.card-quotes-single {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $color_red;
        opacity: .8;
        z-index: 2;
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
        filter: grayscale(100%);
        z-index: 1;
    }

    &-icon {
        position: absolute;
        top: 39px;
        @include right(29px);
        z-index: 3;
        color: $color_white;

        @include ltr {
            transform: scaleX(-1);
            filter: FlipH;
        }

        @include respond-to(phone-max) {
            width: 14px;
            height: 11px;
            top: 20px;
            @include right(12px);
        }
    }

    &-text {
        position: relative;
        z-index: 3;
        margin: 0;
        @include margin-left(45px);
        max-width: 389px;

        @include respond-to(tablet-max) {
            margin-top: 25px;
            margin-bottom: 25px;
        }

        @include respond-to(phone-max) {
            max-width: none;
            @include margin-left(25px);
            @include margin-right(25px);
        }

        p {
            color: $color_white;
            font-weight: 500;
            font-size: pxToRem(30px);
            letter-spacing: 0;
            line-height: pxToRem(42px);
            margin: 0;

            @include respond-to(phone-max) {
                font-size: 23px;
                line-height: 28px;
            }
        }
    }
}
