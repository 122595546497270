.hero-slider {
    @include margin-left(14vw);
    max-width: 625px;
    position: relative;

    @include respond-to(tablet-max) {
        @include margin-left(0);
        max-width: none;
    }

    &-slick {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        max-height: 295px;

        @include respond-to(laptop-max) {
            max-height: 290px;
        }

        @include respond-to(tablet-max) {
            max-height: 177px;
        }

        &.slick-slider {
            overflow: visible;
            max-height: none;
        }

        &-elem {
            &-wrapper {
                display: flex;
            }
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &-content {
        @include margin-left(27px);
        max-width: 361px;

        @include respond-to(tablet-max) {
            @include margin-left(29px);
            max-width: none;
        }

        @media (max-width: 320px) {
            @include margin-left(10px);
        }
    }

    &-promotion {
        display: flex;

        &-label {
            display: inline-block;
            text-transform: uppercase;
            font-size: pxToRem(18px);
            font-weight: 300;
            background-color: $color_gray;
            height: 31px;
            line-height: 31px;
            width: 167px;
            @include padding-left(10px);
            margin-top: 51px;

            @include respond-to(tablet-max) {
                font-size: 11px;
                height: 20px;
                line-height: 20px;
                width: 74px;
                @include padding-left(5px);
                margin-top: 23px;
            }
        }
    }

    &-index {
        line-height: 1;
        vertical-align: bottom;
        position: relative;
        top: -13px;
        font-weight: 500;

        @include respond-to(tablet-max) {
            top: -6px;
        }

        &-wrapper {
            display: inline-block;
            color: $color_gray;
            background-color: $color_white;
            font-size: pxToRem(50px);
            width: 66px;
            height: 102px;
            box-shadow: 0 0 105px 0 rgba(0,0,0,0.50);
            border-radius: 8px;
            text-align: center;
            line-height: 102px;

            @include respond-to(tablet-max) {
                font-size: 23px;
                width: 30px;
                height: 46px;
                box-shadow: 0 0 48px 0 rgba(0,0,0,0.50);
                border-radius: 4px;
                line-height: 46px;
            }
        }
    }

    &-image {
        display: inline-block;
        width: 262px;
        height: 174px;
        border: solid 5px $color_white;
        background-color: $color_white;
        background-size: cover;
        background-position: top center;
        margin-top: 51px;

        @include respond-to(tablet-max) {
            width: 123px;
            min-width: 123px;
            height: 81px;
            border: solid 2px $color_white;
            margin-top: 60px;
        }

        @media (max-width: 320px) {
            width: 103px;
            min-width: 103px;
            height: 61px;
        }
    }

    &-title {
        line-height: pxToRem(49px);
        margin-top: 15px;
        margin-bottom: 26px;
        position: relative;

        @include respond-to(tablet-max) {
            line-height: 25px;
            margin-top: 13px;
            margin-bottom: 13px;
        }

        &::after {
            content: '\A';
            position: absolute;
            @include left(0);
            bottom: -6px;
            display: inline-block;
            background-color: $color_red;
            width: 70px;
            height: 5px;

            @include respond-to(tablet-max) {
                bottom: -10px;
                width: 35px;
                height: 3px;
            }
        }
    }

    &-description {
        color: $color_body_copy;
        line-height: pxToRem(26px);
        margin-bottom: 17px;
        max-height: 49px;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        @include respond-to(tablet-max) {
            line-height: 20px;
            margin-bottom: 5px;
            max-height: 45px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            @include padding-right(10px);
        }
    }

    &-link {
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }

    &-arrow {
        @include reset-button;
        width: 34px;
        height: 34px;
        position: absolute;
        top: 58px;
        overflow: hidden;

        @include respond-to(tablet-max) {
            width: 30px;
            height: 30px;
            top: 10px;
        }

        &-icon {
            @include respond-to(tablet-max) {
                width: 14px;
                height: 31px;
            }
        }

        &.arrow-prev {
            @include left(520px);
            @include ltr {
                transform: rotate(180deg);
            }

            @include respond-to(tablet-max) {
                @include left(260px);
            }

            @media (max-width: 320px) {
                @include left(230px);
            }
        }
        &.arrow-next {
            @include left(560px);
            @include rtl {
                transform: rotate(180deg);
            }

            @include respond-to(tablet-max) {
                @include left(295px);
            }

            @media (max-width: 320px) {
                @include left(260px);
            }
        }
    }
}
