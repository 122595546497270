.cards-container {
    margin-top: 37px;
    position: relative;

    &-title {
        font-size: pxToRem(32px);
        margin-bottom: 40px;
        margin-top: 20px;
    }

    &-cardwrapper {
        height: 354px;
        margin-bottom: pxToRem(40px);
        border-radius: 30px;
        overflow: hidden;

        @include respond-to(tablet-max) {
            height: 47vw;
            margin-bottom: 15px;
            border-radius: 15px;
        }

        &.small {
            height: 228px;

            @include respond-to(tablet-max) {
                height: 30.5vw;
            }
        }
    }

    &-row-vertical {
        flex-direction: column;
        width: 100%;

        @include respond-to(tablet-max) {
            width: auto;
        }

        .cards-container-col {
            width: 100%;
        }

        @include respond-to(laptop-min) {
            flex-direction: row;

            .cards-container-col {
                margin-bottom: 20px;
                width: 50%;
            }
        }
    }

    &-backgroundimage {
        position: absolute;
        top: -35%;
        @include right(0);
        height: 100%;
        z-index: -1;

        @include respond-to(tablet-max) {
            height: auto;
            width: 60%;
            top: 0;
            transform: translateY(-30%);
        }

        img {
            width: 100%;

            @include rtl {
                transform: scaleX(-1);
            }
        }
    }

    .card-fluid-mobile .cards-container-cardwrapper {
        @include respond-to(tablet-max) {
            height: auto;
        }
    }

    .cards-container-cardwrapper {
        @include respond-to(tablet-max) {
            height: auto;
        }
    }

    &.container-fluid .cards-container-col {
        @include respond-to(laptop-min) {
            @include padding-left(10px);
            @include padding-right(10px);

            &:first-child {
                @include padding-left(0);
            }

            &:last-child {
                @include padding-right(0);
            }
        }
    }
}
