.promotion-details-section {
    h1 {
        font-size: pxToRem(42px);
    }

    &-description {
        margin-bottom: pxToRem(120px);

        @include respond-to(tablet-max) {
            margin-bottom: pxToRem(35px);
        }
    }

    h2 {
        margin-top: pxToRem(100px);
        margin-bottom: pxToRem(35px);

    	color: $color_red;
    	font-size: pxToRem(30px);
    	font-weight: 500;

        @include respond-to(tablet-max) {
            margin-top: pxToRem(35px);
        }
    }
}
