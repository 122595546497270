$header-height: 116px;
$header-height-mobile: 76px;

.header,
.search,
.header-dummy-background {
    height: pxToRem($header-height);
    font-size: 1rem;

    @include respond-to(phone-max) {
        height: pxToRem($header-height-mobile);
    }
}

.header-dummy-background {
    flex-shrink: 0;
}

.header-wrapper {
    position: fixed;
    top: 0px;
    transform: translateY(-50%);

    background: $color_black;
    z-index: 10;
    width: 100%;
    min-width: $app-min-width;

    transition: transform $transition_duration;

    &.bordered {
        border-bottom: solid 1px rgba(151,151,151, 0.34);
    }
}

.header {
    position: relative;
    z-index: 2;
    background: $color_black;

    width: 100%;

    & > .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
}

.header-homeLink {
    display: flex;
    justify-content: flex-start;
    flex-shrink: 10;
    text-decoration: none;
}
.header-logo-mobile {
    display:none;
}
.header-logo {
    height: pxToRem(75px) !important;
    width: pxToRem(241px);

    @include respond-to(phone-max) {
        display: none;
        &.header-logo-mobile {
            height: 30px;
            width: 95px;
            display: block;
        }
    }
}

.header-nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    height: 100%;

    @include respond-to(tablet-max) {
        display: none;
    };
}

.header-navList {
    @include reset-list;
    display: inline-flex;
    justify-content: flex-end;
    height: 50%;
    text-transform: uppercase;
    font-size: pxToRem(13px);

    @include rtl {
        font-size: pxToRem(18px);
    }

    &-item {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        height: 100%;
        z-index: 1;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        transition: border-bottom-color $transition_duration;

        & > span {
            transition: color $transition_duration;
        }

        &:not(:last-child) {
            @include margin-right(pxToRem(13px));

            @include rtl {
                @include margin-right(pxToRem(25px));
            }
        }

        a {
            color: $color_white;
            text-decoration: none;
        }

        &:hover a,
        & a.active {
            color: $color_red;
        }

        &-hasSubNavigation {
            align-items: flex-start;
            cursor: default;

            & > svg, & > span {
                pointer-events: none;
            }

            &-arrow {
                display: flex;
                width: 7px;
                height: 10px;
                @include margin-left(6px);
                margin-top: 4px;
                transform: rotateZ(90deg);

                @include rtl {
                    width: 20px;
                    @include margin-right(4px);
                }
            }
        }

        &-subNavigation {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 50px 0;
            border-top: 1px solid transparent;
            cursor: auto;
            background: $color_black;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;
            transition: visibility $transition_duration, opacity $transition_duration, border $transition_duration;

            &-item {
                text-decoration: none;
            }

            &-background {
                position: absolute;
                top: 0;
                bottom: 0;
                @include right(0);
                width: 40%;
                background-color: rgba(9,21,32,0.78);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                opacity: 0.24;
                filter: grayscale(100%);
            }

            &-left {
                user-select: text;
            }

            &-title {
                display: flex;
                font-size: pxToRem(30px);
                line-height: pxToRem(38px);
                font-weight: 500;
                margin-bottom: 15px;
            }

            &-arrow {
                display: flex;
                transform: rotate(-45deg) translate(75%, -50%);
                transform-origin: 50% 50%;
                height: pxToRem(35px);
                width: pxToRem(16px);

                @include rtl {
                    transform: rotate(225deg) translate(75%, 30%);
                }

                ;

                svg {
                    height: pxToRem(35px);
                    width: pxToRem(16px);
                }
            }

            &-description {
                font-size: pxToRem(18px);
                line-height: pxToRem(30px);
                font-weight: 300;
                text-transform: none;
            }

            &-linkGroup {
                margin: pxToRem(20px) 0;
            }

            a.header-navList-item-subNavigation-item {
                display: block;
                color: $color_body_copy;
                font-size: pxToRem(16px);
                font-weight: 500;

                &:hover {
                    color: $color_white;
                }

                &:active,
                &.active {
                    color: $color_red;
                }

                &:not(:last-child) {
                    margin-bottom: pxToRem(15px);
                }
            }
        }

        &-subNavigation-open {
            transition-delay: 0ms;

            & > span {
                color: $color_red;
                transition-delay: 0ms;
            }

            .header-navList-item-subNavigation {
                visibility: visible;
                opacity: 1;
                border-top-color: rgba(151,151,151, 0.34);
                transition-delay: 0ms;
            }

            .header-navList-item-bottom-border {
                background: $color_red;
                transition-delay: 0ms;
            }
        }

        &-bottom-border {
            flex-basis: 100%;
            align-self: flex-end;
            transform: translateY(2px);
            width: 100%;
            height: 1px;
            background: transparent;
            transition: background $transition_duration;
        }
    }

    &-main {
        font-size: pxToRem(14px);
        margin-bottom: 1rem;

        @include rtl {
            font-size: pxToRem(17px);
            margin-bottom: 1.1rem;
        }

        .header-navList-item {
            align-items: flex-end;
        }

        a {
            font-weight: 500;
            color: $color_body_copy;
            text-decoration: none;

            &:hover,
            &:active,
            &.active {
                color: $color_red;
            }
        }

        .header-navList-item-storeLocator a {
            // Highlight the store locator link
            color: $color_white;
        }
    }
}

.header-separator {
    height: pxToRem(89px);
    width: 2px;
    margin: 0 pxToRem(14px);

    background: $color_black3;

    @include respond-to(tablet-max) {
        display: none;
    }
}

.header-rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-languageSelector {
        // text-transform: uppercase;
        font-size: pxToRem(12px);
        text-decoration: none;
    }

    &-search {
        height: pxToRem(25px);
        width: pxToRem(25px);
        margin-bottom: 1rem;
        cursor: pointer;

        @include respond-to(tablet-max) {
            min-height: 19px;
            min-width: 19px;
            margin-bottom: 0;
        }
    }

    a {
        color: $color_white;
        text-decoration: none;
    }

    @include respond-to(tablet-max) {
        flex-direction: row-reverse;
        flex-grow: 1;
        justify-content: flex-start;

        & > *:not(:first-child) {
            @include margin-right(20px);
        }
    }
}


/* ==========================================================================
	Search
/* ========================================================================== */

.search-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;

    pointer-events: none;

    .search {
        opacity: 0;

        transition: opacity $transition_duration;
    }

    transition: transform $transition_duration, background $transition_duration;

    @include respond-to(tablet-max) {
        transform: translateY(100%);
    }
}

.search {
    font-size: pxToRem(40px);
    display: flex;
    align-items: center;

    @include respond-to(tablet-max) {
        font-size: 25px;
    }

    &-form {
        flex-grow: 1;

        input {
            @include reset-input;

            width: 100%;
            color: $color_white;

            &::placeholder {
                color: $color_copy_placeholder;
            }
        }
    }

    &-close-button {
        height: pxToRem(20px);
        width: pxToRem(20px);
        cursor: pointer;

        fill: $color_white;
    }
}

// On search open

.header-wrapper.search-open {
    transform: translateY(0);

    .search-wrapper {
        background: $color_black2;
        pointer-events: auto;

        .search {
            opacity: 1;
        }
    }

    @include respond-to(tablet-max) {
        transform: translateY(-50%);

        .search-wrapper {
            transform: translateY(200%);
        }
    }
}
