.hero-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 458px;

    @include respond-to(tablet-max) {
        height: 53vw;
        justify-content: flex-start;
        padding-top: 10vw;
    }

    &-title {
        align-self: flex-start;
        @include text-align(left);
        font-size: pxToRem(40px);
        font-weight: 500;
        line-height: pxToRem(60px);
        color: $color_white;
        z-index: 3;
        margin: 0;
        display: inline;

        @include respond-to(phone-max) {
            font-size: 30px;
            line-height: 35px;
            width: 80vw;
        }

        @media (max-width: 320px) {
            font-size: 20px;
            line-height: 25px;
        }

        &::after {
            content: '\A';
            display: inline-block;
            width: 22px;
            height: 22px;
            background-image: url('../images/icons/corner-red.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
            @include left(5px);
            top: -20px;

            @include rtl {
                transform: rotate(-90deg);
            }

            @include respond-to(phone-max) {
                width: 9px;
                height: 9px;
                @include left(0);
                top: -15px;
            }

            @media (max-width: 320px) {
                top: -8px;
            }
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        background-position: center;
        background-size: cover;
        opacity: 0.32;
        filter: grayscale(76%);

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg, #000000 11%, rgba(0,0,0,0.00) 27%);

            @include respond-to(tablet-max) {
                background-image: linear-gradient(0deg, #000000 14%, rgba(0,0,0,0.00) 36%);
            }
        }
    }
}
