.features-section-simple {
    padding-top: 20px;
    padding-bottom: 98px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(118,134,147, 0.13) 80%, rgba(0, 0, 0, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(118,134,147, 0.13) 80%, rgba(0, 0, 0, 0));

    @include respond-to(phone-max) {
        padding-top: 0;
        padding-bottom: 29px;
    }

    &-title {
        font-size: pxToRem(30px);
        margin-bottom: 45px;

        @include respond-to(phone-max) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    &-feature {
        border: solid 1px $color_red;
        border-radius: 30px;
        text-align: center;
        padding: 10px;
        padding-top: 66px;
        height: 100%;

        @include respond-to(phone-max) {
            border-radius: 15px;
            padding-top: 32px;
        }

        &-title {
            @include text-align(left);
            text-transform: uppercase;
            font-weight: 300;
            font-size: pxToRem(24px);
            line-height: pxToRem(36px);
            @include padding-left(24px);
            margin-top: 46px;
            margin-bottom: 25px;

            @include respond-to(phone-max) {
                font-size: 16px;
                @include padding-left(10px);
                margin-top: 20px;
                margin-bottom: 20px;
            }

            @media only screen and (max-width: 400px) {
                @include padding-left(0px);
            }

            @media (max-width: 320px) {
                font-size: 13px;
                line-height: 1.5;
            }
        }

        &-icon {
            @include respond-to(phone-max) {
                width: 50px;
                height: 50px;
            }
        }

        &-col {
            @include respond-to(tablet-max) {
                max-width: 25%;
                margin-bottom: 30px;
            }

            @include respond-to(phone-max) {
                max-width: 33.33333%;
                margin-bottom: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
