.media-center {
    margin-top: 63px;
    transition: min-height $transition_duration;

    @include respond-to(tablet-max) {
        margin-top: 12px;
    }

    &-filters {
        border: solid 1px $color_gray3;
        border-radius: 13px;
        padding: 0 40px;
        margin-bottom: 37px;

        @include respond-to(tablet-max) {
            margin-top: 12px;
            background-color: $color_black2;
            border: solid 1px $color_black5;
            border-radius: 5px;
            padding: 19px 18px 2px 18px;
            position: relative;
        }

        &-title {
            margin: 0;
            font-size: 16px;
            letter-spacing: 0;
            position: absolute;
            top: 14px;
            @include left(18px);
        }

        &-icon {
            color: $color_red;

            &-wrapper {
                @include text-align(right);
            }
        }

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            height: 75px;
            align-items: center;

            @include respond-to(tablet-max) {
                padding-top: 29px;
                height: auto;
                flex-wrap: wrap;
            }

            &-item {
                flex-basis: 20%;

                @include respond-to(phone-max) {
                    flex-basis: 33.333%;
                }
            }
        }

        &-input {
            &:checked + .media-center-filters-label {
                @include respond-to(tablet-max) {
                    border: 1px solid $color_red;
                    background-color: $color_red;
                }

                .media-center-filters-switch::after {
                    background-color: $color_red;
                    @include ltr {
                        transform: translateX(20px);
                    }
                    @include rtl {
                        transform: translateX(-20px);
                    }
                }
            }

            &:focus + .media-center-filters-label {
                outline: 1px dotted;
                outline: 5px auto -webkit-focus-ring-color;
            }
        }

        &-button-all {
            @include reset-button;
            color: $color_white;

            @include respond-to(tablet-max) {
                font-size: 16px;
                width: 82px;
                line-height: 45px;
                border: 1px solid $color_gray3;
                border-radius: 5px;
                text-align: center;
                margin-bottom: 19px;
                transition: background-color $transition_duration, border $transition_duration;
            }

            &.active {
                @include respond-to(tablet-max) {
                    border: 1px solid $color_red;
                    background-color: $color_red;
                }

                .media-center-filters-switch::after {
                    background-color: $color_red;
                    @include ltr {
                        transform: translateX(20px);
                    }
                    @include rtl {
                        transform: translateX(-20px);
                    }
                }
            }
        }

        &-label {
            cursor: pointer;
            margin: 0;

            @include respond-to(tablet-max) {
                white-space: nowrap;
                font-size: 16px;
                width: 82px;
                line-height: 45px;
                border: 1px solid $color_gray3;
                border-radius: 5px;
                text-align: center;
                margin-bottom: 19px;
                transition: background-color $transition_duration, border $transition_duration;
            }
        }

        &-switch {
            display: inline-block;
            width:  40px;
            height: 12px;
            border-radius: 6px;
            background-color: $color_gray3;
            box-shadow: inset 0 -1px 1px 0 rgba(0,0,0,0.15);
            position: relative;
            @include margin-right(12px);

            &::after {
                content: '\A';
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: absolute;
                top: -3px;
                @include left(0);
                background-color: $color_white;
                transform: translateX(0);
                transition: transform $transition_duration, background-color $transition_duration;
            }
        }
    }

    &-cardwrapper {
        height: 287px;
        margin-bottom: 55px;
        border-radius: 30px;
        overflow: hidden;
        opacity: 1;
        transform: translateY(0);
        transition: opacity $transition_duration, transform $transition_duration;

        @include respond-to(tablet-max) {
            height: 47vw;
            margin-bottom: 15px;
            border-radius: 15px;
        }
    }

    &-loadmore {
        @include reset-button;
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        margin-top: 41px;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }

        &-row {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    &.loading {
        .media-center-cardwrapper {
            opacity: 0;
            transform: translateY(-20px);
        }
    }
}