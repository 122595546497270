.single-link-section {
    height: 234px;
    position: relative;
    text-align: center;
    margin-top: 43px;

    @include respond-to(tablet-max) {
        height: 42vw;
        margin-top: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-image {
        background-size: cover;
        background-position: center center;
        filter: grayscale(100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: $color_black;
        opacity: .6;
    }

    &-title {
        font-size: pxToRem(30px);
        color: $color_white;
        padding-top: 30px;
        margin-bottom: 40px;

        @include respond-to(tablet-max) {
            padding-top: 0;
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

    &-link {
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }
}