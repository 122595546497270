@font-face {
    font-family: 'ApexSans';
    src:
        url('../fonts/ApexSans Book.otf') format('truetype'),
        url('../fonts/ApexSans Book.woff') format('woff'),
        url('../fonts/ApexSans Book.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ApexSans';
    src:
        url('../fonts/ApexSans Medium.otf') format('truetype'),
        url('../fonts/ApexSans Medium.ttf') format('truetype'),
        url('../fonts/ApexSans Medium.woff') format('woff'),
        url('../fonts/ApexSans Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ApexSans';
    src:
        url('../fonts/ApexSans Bold.otf') format('truetype'),
        url('../fonts/ApexSans Bold.woff') format('woff'),
        url('../fonts/ApexSans Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GE Dinar One';
    src:
        url('../fonts/GE Dinar One Light.otf') format('truetype'),
        url('../fonts/GE Dinar One Light.woff') format('woff'),
        url('../fonts/GE Dinar One Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GE Dinar One';
    src:
        url('../fonts/GE Dinar One Regular.otf') format('truetype'),
        url('../fonts/GE Dinar One Regular.woff') format('woff'),
        url('../fonts/GE Dinar One Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GE Dinar One';
    src:
        url('../fonts/GE Dinar One Medium.otf') format('truetype'),
        url('../fonts/GE Dinar One Medium.ttf') format('truetype'),
        url('../fonts/GE Dinar One Medium.woff') format('woff'),
        url('../fonts/GE Dinar One Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GE Dinar One';
    src:
        url('../fonts/GE Dinar One Bold.otf') format('truetype'),
        url('../fonts/GE Dinar One Bold.ttf') format('truetype'),
        url('../fonts/GE Dinar One Bold.woff') format('woff'),
        url('../fonts/GE Dinar One Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

html {
    font-size: 18px;

    @include respond-to(laptop-max) {
        font-size: 16px;
    }

    @include respond-to(tablet-max) {
        font-size: 14px;
    }

    @include respond-to(phone-max) {
        font-size: 13px;
    }
}

html, body {
    background-color: $color_black;
    color: $color_white;
}

body {
    font-family: 'ApexSans', 'GE Dinar One', 'Helvetica';
    font-weight: 300;
}

a {
    transition: color $transition_duration;
    color: $color_white;
    text-decoration: underline;
}

a:active,
a:focus,
a:hover {
    color: $color_white;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6,
p, blockquote {
    user-select: text;
}

h1 {
    font-size: pxToRem(64px);
    font-weight: 300;
    line-height: pxToRem(60px);
    margin-bottom: pxToRem(45px);
}

h2 {
    font-weight: 500;
    font-size: pxToRem(42px);
    color: $color_red;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 25px;

    @include respond-to(tablet-max) {
        font-size: pxToRem(30px);
        margin-bottom: 12px;
    }
}

p {
    font-weight: 300;
    font-size: pxToRem(18px);
    line-height: pxToRem(30px);
    margin-bottom: 20px;

    @include respond-to(phone-max) {
      font-size: pxToRem(20px);
      line-height: pxToRem(40px);
    }

    @include rtl {
      font-size: pxToRem(21px);
      line-height: pxToRem(45px);
    }




}

ol {
    @include padding-left(1em);
    margin-bottom: 0;
}

textarea, input, button, select {
    font-family: inherit;
    font-weight: inherit;
}

b, strong {
    font-weight: 500;
}

.small {
    font-weight: 300;
}
