.search-results {
    padding-top: pxToRem(20px);

    &-term {
        display: flex;
        flex-wrap: wrap;
        font-size: pxToRem(42px);

        &-title {
            @include margin-right(pxToRem(20px));
        }

        &-text {
            padding: pxToRem(4px) 0;
            color: $color_red;
            border-bottom: 2px solid $color_red;
        }
    }

    &-list {
        counter-reset: section;

        &-item {
            margin-top: pxToRem(30px);

            &:last-child {
                .search-results-list-item-separator {
                    display: none;
                }

                margin-bottom: 0;
            }

            &-title {
                margin-bottom: pxToRem(20px);
                font-size: pxToRem(30px);
                text-decoration: none;
            }

            &-description {
                font-size: pxToRem(18px);
                line-height: pxToRem(30px);

                @include respond-to(tablet-max) {
                    color: $color_gray2;
                }
            }

            &-separator {
                display: flex;
                height: 1px;
                margin-top: pxToRem(25px);

                &::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: $color_black4;
                }
            }
        }
    }
}
