.title-section {
    &-title {
        font-size: pxToRem(42px);
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0;
        color: $color_red;
        margin-bottom: 17px;

        @include respond-to(phone-max) {
            font-size: 23px;
            margin-bottom: 7px;
        }
    }

    &-description {
        font-size: pxToRem(30px);
        line-height: pxToRem(45px);
        max-width: 946px;
        margin-bottom: 48px;

        @include respond-to(tablet-max) {
            max-width: 83vw;
            margin-bottom: 32px;
        }

        @include respond-to(phone-max) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
