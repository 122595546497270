.facts-section-5 {
    position: relative;
    padding-top: pxToRem(108px);
    padding-bottom: pxToRem(124px);

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_black;
        opacity: 1;
        filter: grayscale(100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &-title {
        margin-bottom: pxToRem(31px);
    }

    &-badges {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: pxToRem(-10px);
    }

    &-badge {
        position: relative;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: pxToRem(200px);
        height: pxToRem(300px);
        margin: pxToRem(10px);
        padding: pxToRem(10px);
        border: solid 1px $color_red;
        border-radius: pxToRem(30px);

        @include respond-to(tablet-max) {
            width: pxToRem(140px);
            height: pxToRem(220px);
            border-radius: pxToRem(25px);
        }

        &-center {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: relative;
        }

        &-image {
            height: 70px;
            width: auto;

            @include respond-to(tablet-max) {
                height: pxToRem(45px);
            }

            img {
                height: 100%;
            }

            &-container {
                width: 90px;
                height: 70px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;

                @include respond-to(tablet-max) {
                    height: pxToRem(45px);
                    width: pxToRem(58px);
                }
            }
        }

        &-value {
            font-size: pxToRem(50px);
            color: $color_white;
            text-transform: uppercase;

            @include respond-to(tablet-max) {
                font-size: pxToRem(35px);
            }

            @include rtl {
                direction: ltr;
            }
        }

        &-title {
            position: absolute;
            bottom: 22%;
            right: -10.7rem;
            height: 45px;
            width: 100%;
            margin: 0;
            text-transform: uppercase;
            color: $color_body_copy;
            font-size: pxToRem(18px);
            line-height: pxToRem(23px);
            transform: rotate(-90deg);
            transform-origin: bottom left;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include respond-to(tablet-max) {
                font-size: pxToRem(13px);
                line-height: pxToRem(16px);
                width: 100%;
                right: -7.4rem;
            }

            @include rtl {
                bottom: 15%;
                right: -2.7rem;

                @include respond-to(tablet-max) {
                    right: -1.9rem;
                }
            }
        }
    }
}
