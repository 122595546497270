.hero-media-center-details {
    overflow-x: hidden;

    @include respond-to(tablet-max) {
        overflow-x: visible;
    }

    &-title {
        font-size: pxToRem(30px);
        margin-top: 90px;
        margin-bottom: 40px;

        @include respond-to(tablet-max) {
            font-size: 16px;
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    &-images {
        position: relative;

        &-current,
        &-next {
            width: 932px;
            height: 615px;
            border-radius: 30px;
            background-size: cover;
            background-position: center center;
            text-decoration: none;

            @include respond-to(tablet-max) {
                width: 100%;
                height: 61vw;
                border-radius: 15px;
            }
        }

        &-next {
            position: absolute;
            top: 0;
            @include left(932px + 76px);
            color: transparent;

            &:hover, &:active, &:focus, &:visited {
                color: transparent;
            }

            &::after {
                content: '\A';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color_black;
                opacity: 0.8;
            }
        }
    }

    &-navigation-controls {
        display: flex;
        justify-content: space-between;
    }


    &-nextarticle {
        display: block;
        @include text-align(right);
        font-size: pxToRem(30px);
        color: $color_red;
        letter-spacing: 0;
        margin-top: 61px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            font-size: 1.1rem;
            margin-top: 15px;

            &-icon {
                width: 20px;
                height: 20px;
                position: relative;
                top: -4px;
            }
        }

        &:hover, &:active, &:visited, &:focus {
            color: $color_red;
        }

        &-icon {
            transform: translateY(8px);

            @include rtl {
                transform: translateY(8px) rotate(180deg);
            }
        }
    }

    &-goback {
        padding-top: 5px;
    }
}
