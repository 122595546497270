.card-video {
    height: 100%;
    position: relative;

    @include respond-to(tablet-max) {
        padding-top: 30vw;
    }

    @include respond-to(phone-max) {
        padding-top: 47vw;
    }

    &-background {
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        filter: blur(4px);

        &::after {
            content: '\A';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color_black;
            opacity: .6;
        }
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-title {
        color: $color_white;
        margin: 0;
        font-size: pxToRem(30px);
        width: 304px;
        margin-top: 18px;

        @include respond-to(tablet-max) {
            font-size: 16px;
            width: 162px;
            margin-top: 8px;
        }
    }

    &-button {
        @include reset-button;
        color: transparent;
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    &-icon {
        display: flex;
        background-color: $color_white;
        width: 98px;
        height: 98px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 15px 0 rgba($color_black, .5);

        @include respond-to(tablet-max) {
            width: 49px;
            height: 49px;

            svg {
                width: 17px;
                height: 22px;
            }
        }
    }

    &-popup {
        position: relative;
        background: transparent;
        width: auto;
        max-width: 800px;
        margin: 20px auto;
        border-radius: 30px;

        @include respond-to(tablet-max) {
            border-radius: 15px;
        }

        .mfp-close {
            display: none;
        }

        &-video {
            width: 100%;
            display: block;
            border-radius: 30px;

            @include respond-to(tablet-max) {
                border-radius: 15px;
            }
        }

        &-close {
            @include reset-button;
            position: absolute;
            top: -10vh;
            @include right(-87px);

            @include respond-to(tablet-max) {
                position: fixed;
                top: auto;
                @include right(auto);
                left: 50%;
                transform: translateX(-50%);
                bottom: 40px;
                width: 64px;
                height: 64px;
                border-radius: 50%;
                border: solid 1px $color_gray3;

                &-icon {
                    width: 16px;
                    height: 16px;
                }
            }

            @media (max-height: 450px) {
                position: absolute;
                bottom: -80px;
            }
        }
    }
}
