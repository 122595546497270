.link-section {
    background-color: $color_white;
    color: $color_gray;
    padding-top: 83px;
    padding-bottom: 69px;

    @include respond-to(tablet-max) {
        padding-top: 37px;
        padding-bottom: 27px;
    }

    &-image {
        width: 100%;
        border-radius: 30px;
        @include margin-left(10px);

        @include respond-to(tablet-max) {
            border-radius: 10px;
            @include margin-left(0);
        }
    }

    &-title {
        line-height: 1;

        @include respond-to(tablet-max) {
            margin-top: 32px;
        }
    }

    &-subtitle {
        position: relative;
        font-weight: 500;
        font-size: pxToRem(30px);
        color: $color_body_copy;
        line-height: pxToRem(44px);
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 20px;

        @include respond-to(tablet-max) {
            text-align: center;
            line-height: 26px;
            padding: 0 10px;
            margin-bottom: 30px;
        }

        &-corner {
            color: $color_red;
            
            @include respond-to(tablet-max) {
                width: 25px;
                height: 25px;
            }

            &-wrapper {
                display: inline-block;
                position: absolute;
                top: -25px;
                @include right(-10px);
    
                @include rtl {
                    transform: rotate(-90deg);
                }
    
                @include respond-to(tablet-max) {
                    top: -10px;
                    @include right(0);
                }
            }
        }
    }

    &-description {
        color: $color_gray;
        margin-bottom: 50px;
    }

    &-links {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            display: inline-block;
            color: $color_white;
            background-color: $color_red;
            min-width: 183px;
            text-align: center;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            @include margin-right(26px);
            margin-bottom: 10px;

            @include respond-to(tablet-max) {
                min-width: 127px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                @include margin-right(10px);
            }

            &:last-child {
                @include margin-right(0);
            }

            &-link {
                display: block;
                font-weight: 500;
                font-size: pxToRem(16px);
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
}