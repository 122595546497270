/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* ==========================================================================
	Directions
/* ========================================================================== */
/* ==========================================================================
	Variables
/* ========================================================================== */
/* Colors */
/* Font Sizes */
/* Transitions and animations */
/* ==========================================================================
	Responsive
/* ========================================================================== */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

html[dir="rtl"] .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0; }

html[dir="rtl"] .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0; }

html[dir="rtl"] .offset-3 {
  margin-right: 25%;
  margin-left: 0; }

html[dir="rtl"] .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0; }

html[dir="rtl"] .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0; }

html[dir="rtl"] .offset-6 {
  margin-right: 50%;
  margin-left: 0; }

html[dir="rtl"] .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0; }

html[dir="rtl"] .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0; }

html[dir="rtl"] .offset-9 {
  margin-right: 75%;
  margin-left: 0; }

html[dir="rtl"] .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0; }

html[dir="rtl"] .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0; }

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000 !important; }

body {
  min-width: 320px;
  text-align: left;
  display: flex;
  flex-direction: column; }
  body > * {
    flex-shrink: 0; }
  body > main {
    background-color: #000 !important; }

.block-scroll {
  overflow: hidden; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.dnone {
  display: none; }

.inner-page-content {
  position: relative;
  z-index: 1; }

header.bordered ~ #main .inner-page-content {
  margin-top: 0px; }

.vertical-ellipsis {
  position: relative; }
  .vertical-ellipsis > * {
    overflow: hidden;
    transition: max-height 350ms; }
  .vertical-ellipsis:after {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    text-shadow: 0px 0px 6px black;
    color: #fff;
    cursor: pointer;
    content: ' Read more';
    transition: visibility 350ms, opacity 350ms; }
  .vertical-ellipsis-overflow:after {
    visibility: visible;
    opacity: 1; }

*:focus {
  outline: none !important; }

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* IE 6-9 */
  filter: gray;
  /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
  -webkit-filter: grayscale(100%); }

form.generic-form .sf_cols .container .row {
  margin-bottom: 43px; }
  @media only screen and (max-width: 991px) {
    form.generic-form .sf_cols .container .row {
      margin-bottom: 35px; } }

form.generic-form .sf_cols:last-child .container .row {
  margin-bottom: 0; }

form.generic-form .generic-form-select {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  height: 75px;
  background-color: #ce003c;
  color: #fff;
  border-radius: 6.3px;
  font-size: 1.66667rem;
  letter-spacing: 0;
  padding: 0 35px;
  cursor: pointer; }
  form.generic-form .generic-form-select::-ms-expand, form.generic-form .generic-form-select::-ms-clear {
    display: none; }
  @media only screen and (max-width: 991px) {
    form.generic-form .generic-form-select {
      height: 53px;
      border-radius: 4.5px;
      font-size: 16px;
      padding: 0 18px; } }
  form.generic-form .generic-form-select option {
    color: #000;
    background-color: #fff; }
    form.generic-form .generic-form-select option:first-child {
      display: none; }
  form.generic-form .generic-form-select-wrapper {
    width: 100%;
    margin-bottom: 50px;
    position: relative; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-select-wrapper {
        margin-bottom: 22px; } }
    form.generic-form .generic-form-select-wrapper.error .generic-form-select-label {
      display: none; }
  form.generic-form .generic-form-select-label {
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    top: -32px;
    left: 0;
    pointer-events: none;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1;
    transition: opacity 350ms, transform 350ms; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-select-label {
        transform: translateY(20px);
        top: -20px;
        font-size: 13px; } }
  form.generic-form .generic-form-select-caret {
    position: absolute;
    top: calc(50% - 5px);
    right: 22px;
    pointer-events: none; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-select-caret {
        width: 15px;
        height: 8px;
        top: calc(50% - 4px); } }
  form.generic-form .generic-form-select:focus + .generic-form-select-label {
    opacity: 1;
    transform: translateY(0); }

form.generic-form .generic-form-text {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  height: 75px;
  background-color: #121212;
  color: #fff;
  border: 1px solid #232323;
  border-radius: 6.3px;
  font-size: 1.66667rem;
  letter-spacing: 0;
  padding: 0 35px; }
  form.generic-form .generic-form-text::-ms-expand, form.generic-form .generic-form-text::-ms-clear {
    display: none; }
  @media only screen and (max-width: 991px) {
    form.generic-form .generic-form-text {
      height: 53px;
      border-radius: 4.5px;
      font-size: 16px;
      padding: 0 18px; } }
  form.generic-form .generic-form-text::placeholder {
    color: #768693; }
  form.generic-form .generic-form-text option {
    color: initial;
    background-color: #fff; }
    form.generic-form .generic-form-text option:first-child {
      display: none; }
  form.generic-form .generic-form-text-wrapper {
    width: 100%;
    position: relative; }
    form.generic-form .generic-form-text-wrapper:not(:last-child) {
      margin-bottom: 50px; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-text-wrapper {
        margin-bottom: 37px; } }
    form.generic-form .generic-form-text-wrapper.error .generic-form-text-label {
      display: none; }
  form.generic-form .generic-form-text-label {
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    top: -32px;
    pointer-events: none;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1;
    transition: opacity 350ms, transform 350ms; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-text-label {
        transform: translateY(20px);
        top: -20px;
        font-size: 13px; } }
  form.generic-form .generic-form-text-corner {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none;
    color: #768693;
    transition: color 350ms; }
    @media only screen and (max-width: 991px) {
      form.generic-form .generic-form-text-corner {
        width: 11px;
        height: 10px;
        top: 8px;
        right: 8px; }
        form.generic-form .generic-form-text-corner svg {
          width: 11px;
          height: 10px; } }
  form.generic-form .generic-form-text:focus + .generic-form-text-deps .generic-form-text-label {
    opacity: 1;
    transform: translateY(0); }
  form.generic-form .generic-form-text:focus + .generic-form-text-deps .generic-form-text-corner {
    color: #ce003c; }

@media only screen and (max-width: 991px) {
  form.generic-form .generic-form-submit-wrapper {
    text-align: center; } }

form.generic-form .generic-form-submit-wrapper button[type="submit"] {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  background-color: #ce003c;
  text-align: center;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.88889rem;
  text-transform: uppercase;
  padding: 0 30px; }
  form.generic-form .generic-form-submit-wrapper button[type="submit"] > span {
    position: relative; }
  @media only screen and (max-width: 991px) {
    form.generic-form .generic-form-submit-wrapper button[type="submit"] {
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      margin-bottom: 0; } }

form.generic-form textarea.generic-form-text {
  resize: none;
  height: 200px;
  padding-top: 13px; }
  @media only screen and (max-width: 991px) {
    form.generic-form textarea.generic-form-text {
      height: 141px; } }

form.generic-form .error-inline {
  position: absolute;
  top: -32px;
  pointer-events: none;
  font-size: 1rem;
  color: #ce003c;
  letter-spacing: 0;
  line-height: 1; }
  @media only screen and (max-width: 991px) {
    form.generic-form .error-inline {
      top: -20px;
      font-size: 13px; } }

@font-face {
  font-family: 'ApexSans';
  src: url("../fonts/ApexSans Book.otf") format("truetype"), url("../fonts/ApexSans Book.woff") format("woff"), url("../fonts/ApexSans Book.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'ApexSans';
  src: url("../fonts/ApexSans Medium.otf") format("truetype"), url("../fonts/ApexSans Medium.ttf") format("truetype"), url("../fonts/ApexSans Medium.woff") format("woff"), url("../fonts/ApexSans Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'ApexSans';
  src: url("../fonts/ApexSans Bold.otf") format("truetype"), url("../fonts/ApexSans Bold.woff") format("woff"), url("../fonts/ApexSans Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'GE Dinar One';
  src: url("../fonts/GE Dinar One Light.otf") format("truetype"), url("../fonts/GE Dinar One Light.woff") format("woff"), url("../fonts/GE Dinar One Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'GE Dinar One';
  src: url("../fonts/GE Dinar One Regular.otf") format("truetype"), url("../fonts/GE Dinar One Regular.woff") format("woff"), url("../fonts/GE Dinar One Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GE Dinar One';
  src: url("../fonts/GE Dinar One Medium.otf") format("truetype"), url("../fonts/GE Dinar One Medium.ttf") format("truetype"), url("../fonts/GE Dinar One Medium.woff") format("woff"), url("../fonts/GE Dinar One Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'GE Dinar One';
  src: url("../fonts/GE Dinar One Bold.otf") format("truetype"), url("../fonts/GE Dinar One Bold.ttf") format("truetype"), url("../fonts/GE Dinar One Bold.woff") format("woff"), url("../fonts/GE Dinar One Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

html {
  font-size: 18px; }
  @media only screen and (max-width: 1199px) {
    html {
      font-size: 16px; } }
  @media only screen and (max-width: 991px) {
    html {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    html {
      font-size: 13px; } }

html, body {
  background-color: #000;
  color: #fff; }

body {
  font-family: 'ApexSans', 'GE Dinar One', 'Helvetica';
  font-weight: 300; }

a {
  transition: color 350ms;
  color: #fff;
  text-decoration: underline; }

a:active,
a:focus,
a:hover {
  color: #fff;
  text-decoration: none; }

h1, h2, h3, h4, h5, h6,
p, blockquote {
  user-select: text; }

h1 {
  font-size: 3.55556rem;
  font-weight: 300;
  line-height: 3.33333rem;
  margin-bottom: 2.5rem; }

h2 {
  font-weight: 500;
  font-size: 2.33333rem;
  color: #ce003c;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 25px; }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 1.66667rem;
      margin-bottom: 12px; } }

p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.66667rem;
  margin-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    p {
      font-size: 1.11111rem;
      line-height: 2.22222rem; } }

ol {
  padding-left: 1em;
  margin-bottom: 0; }

textarea, input, button, select {
  font-family: inherit;
  font-weight: inherit; }

b,
strong {
  font-weight: 500; }

.small {
  font-weight: 300; }

.header,
.search,
.header-dummy-background {
  height: 6.44444rem;
  font-size: 1rem; }
  @media only screen and (max-width: 767px) {
    .header,
    .search,
    .header-dummy-background {
      height: 4.22222rem; } }

.header-dummy-background {
  flex-shrink: 0; }

.header-wrapper {
  position: fixed;
  top: 0px;
  transform: translateY(-50%);
  background: #000;
  z-index: 10;
  width: 100%;
  min-width: 320px;
  transition: transform 350ms; }
  .header-wrapper.bordered {
    border-bottom: solid 1px rgba(151, 151, 151, 0.34); }

.header {
  position: relative;
  z-index: 2;
  background: #000;
  width: 100%; }
  .header > .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%; }

.header-homeLink {
  display: flex;
  justify-content: flex-start;
  flex-shrink: 10;
  text-decoration: none; }

.header-logo-mobile {
  display: none; }

.header-logo {
  height: 4.16667rem !important;
  width: 13.38889rem; }
  @media only screen and (max-width: 767px) {
    .header-logo {
      display: none; }
      .header-logo.header-logo-mobile {
        height: 30px;
        width: 95px;
        display: block; } }

.header-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  height: 100%; }
  @media only screen and (max-width: 991px) {
    .header-nav {
      display: none; } }

.header-navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: flex-end;
  height: 50%;
  text-transform: uppercase;
  font-size: 0.72222rem; }
  .header-navList-item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    z-index: 1;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    transition: border-bottom-color 350ms; }
    .header-navList-item > span {
      transition: color 350ms; }
    .header-navList-item:not(:last-child) {
      margin-right: 0.72222rem; }
    .header-navList-item a {
      color: #fff;
      text-decoration: none; }
    .header-navList-item:hover a,
    .header-navList-item a.active {
      color: #ce003c; }
    .header-navList-item-hasSubNavigation {
      align-items: flex-start;
      cursor: default; }
      .header-navList-item-hasSubNavigation > svg, .header-navList-item-hasSubNavigation > span {
        pointer-events: none; }
      .header-navList-item-hasSubNavigation-arrow {
        display: flex;
        width: 7px;
        height: 10px;
        margin-left: 6px;
        margin-top: 4px;
        transform: rotateZ(90deg); }
    .header-navList-item-subNavigation {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 50px 0;
      border-top: 1px solid transparent;
      cursor: auto;
      background: #000;
      visibility: hidden;
      overflow: hidden;
      opacity: 0;
      transition: visibility 350ms, opacity 350ms, border 350ms; }
      .header-navList-item-subNavigation-item {
        text-decoration: none; }
      .header-navList-item-subNavigation-background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 40%;
        background-color: rgba(9, 21, 32, 0.78);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.24;
        filter: grayscale(100%); }
      .header-navList-item-subNavigation-left {
        user-select: text; }
      .header-navList-item-subNavigation-title {
        display: flex;
        font-size: 1.66667rem;
        line-height: 2.11111rem;
        font-weight: 500;
        margin-bottom: 15px; }
      .header-navList-item-subNavigation-arrow {
        display: flex;
        transform: rotate(-45deg) translate(75%, -50%);
        transform-origin: 50% 50%;
        height: 1.94444rem;
        width: 0.88889rem; }
        .header-navList-item-subNavigation-arrow svg {
          height: 1.94444rem;
          width: 0.88889rem; }
      .header-navList-item-subNavigation-description {
        font-size: 1rem;
        line-height: 1.66667rem;
        font-weight: 300;
        text-transform: none; }
      .header-navList-item-subNavigation-linkGroup {
        margin: 1.11111rem 0; }
      .header-navList-item-subNavigation a.header-navList-item-subNavigation-item {
        display: block;
        color: #a0a5a9;
        font-size: 0.88889rem;
        font-weight: 500; }
        .header-navList-item-subNavigation a.header-navList-item-subNavigation-item:hover {
          color: #fff; }
        .header-navList-item-subNavigation a.header-navList-item-subNavigation-item:active, .header-navList-item-subNavigation a.header-navList-item-subNavigation-item.active {
          color: #ce003c; }
        .header-navList-item-subNavigation a.header-navList-item-subNavigation-item:not(:last-child) {
          margin-bottom: 0.83333rem; }
    .header-navList-item-subNavigation-open {
      transition-delay: 0ms; }
      .header-navList-item-subNavigation-open > span {
        color: #ce003c;
        transition-delay: 0ms; }
      .header-navList-item-subNavigation-open .header-navList-item-subNavigation {
        visibility: visible;
        opacity: 1;
        border-top-color: rgba(151, 151, 151, 0.34);
        transition-delay: 0ms; }
      .header-navList-item-subNavigation-open .header-navList-item-bottom-border {
        background: #ce003c;
        transition-delay: 0ms; }
    .header-navList-item-bottom-border {
      flex-basis: 100%;
      align-self: flex-end;
      transform: translateY(2px);
      width: 100%;
      height: 1px;
      background: transparent;
      transition: background 350ms; }
  .header-navList-main {
    font-size: 0.77778rem;
    margin-bottom: 1rem; }
    .header-navList-main .header-navList-item {
      align-items: flex-end; }
    .header-navList-main a {
      font-weight: 500;
      color: #a0a5a9;
      text-decoration: none; }
      .header-navList-main a:hover, .header-navList-main a:active, .header-navList-main a.active {
        color: #ce003c; }
    .header-navList-main .header-navList-item-storeLocator a {
      color: #fff; }

.header-separator {
  height: 4.94444rem;
  width: 2px;
  margin: 0 0.77778rem;
  background: #191919; }
  @media only screen and (max-width: 991px) {
    .header-separator {
      display: none; } }

.header-rightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header-rightSide-languageSelector {
    font-size: 0.66667rem;
    text-decoration: none; }
  .header-rightSide-search {
    height: 1.38889rem;
    width: 1.38889rem;
    margin-bottom: 1rem;
    cursor: pointer; }
    @media only screen and (max-width: 991px) {
      .header-rightSide-search {
        min-height: 19px;
        min-width: 19px;
        margin-bottom: 0; } }
  .header-rightSide a {
    color: #fff;
    text-decoration: none; }
  @media only screen and (max-width: 991px) {
    .header-rightSide {
      flex-direction: row-reverse;
      flex-grow: 1;
      justify-content: flex-start; }
      .header-rightSide > *:not(:first-child) {
        margin-right: 20px; } }

/* ==========================================================================
	Search
/* ========================================================================== */
.search-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  transition: transform 350ms, background 350ms; }
  .search-wrapper .search {
    opacity: 0;
    transition: opacity 350ms; }
  @media only screen and (max-width: 991px) {
    .search-wrapper {
      transform: translateY(100%); } }

.search {
  font-size: 2.22222rem;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .search {
      font-size: 25px; } }
  .search-form {
    flex-grow: 1; }
    .search-form input {
      appearance: none;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      outline: none;
      width: 100%;
      color: #fff; }
      .search-form input::-ms-expand, .search-form input::-ms-clear {
        display: none; }
      .search-form input::placeholder {
        color: #262626; }
  .search-close-button {
    height: 1.11111rem;
    width: 1.11111rem;
    cursor: pointer;
    fill: #fff; }

.header-wrapper.search-open {
  transform: translateY(0); }
  .header-wrapper.search-open .search-wrapper {
    background: #121212;
    pointer-events: auto; }
    .header-wrapper.search-open .search-wrapper .search {
      opacity: 1; }
  @media only screen and (max-width: 991px) {
    .header-wrapper.search-open {
      transform: translateY(-50%); }
      .header-wrapper.search-open .search-wrapper {
        transform: translateY(200%); } }

.mobile-navigation {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6.44444rem);
  background-color: #000;
  border-top: 1px solid rgba(151, 151, 151, 0.54);
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100px);
  transition: visibility 350ms, opacity 350ms, transform 350ms; }
  .mobile-navigation-open {
    visibility: visible;
    opacity: 1;
    transform: translate(0); }
  @media only screen and (min-width: 992px) {
    .mobile-navigation {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .mobile-navigation {
      height: calc(100% - 4.22222rem); } }
  .mobile-navigation-list {
    list-style: none;
    margin: 0;
    padding: 0; }
  .mobile-navigation-secondary {
    flex-grow: 1;
    font-size: 1.33333rem;
    line-height: 4.44444rem; }
    .mobile-navigation-secondary-item > a {
      display: block;
      height: 100%;
      width: 100%;
      text-decoration: none; }
    .mobile-navigation-secondary-item-hasSubNavigation {
      display: flex;
      justify-content: space-between; }
      .mobile-navigation-secondary-item-hasSubNavigation-arrow {
        width: 1.66667rem;
        display: flex;
        align-items: center; }
        .mobile-navigation-secondary-item-hasSubNavigation-arrow svg {
          width: 1.38889rem;
          height: auto; }
  .mobile-navigation-footer {
    margin-top: 1.66667rem; }
    .mobile-navigation-footer li a {
      display: block;
      height: 100%;
      width: 100%;
      font-size: 1.44444rem;
      line-height: 4.88889rem;
      color: #a0a5a9; }
      .mobile-navigation-footer li a:hover, .mobile-navigation-footer li a:active, .mobile-navigation-footer li a.active {
        color: #ce003c; }
  .mobile-navigation-background {
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 20%;
    z-index: -1;
    background-color: rgba(9, 21, 32, 0.78);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
    filter: grayscale(100%); }

.mobile-navigation-main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: transform 350ms; }
  .mobile-navigation-main-item a {
    text-decoration: none; }

.mobile-navigation-subNavigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  transition: opacity 350ms, transform 350ms, visibility 350ms; }

.mobile-navigation-subNavigation-item {
  position: absolute;
  height: 100%;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #000;
  transition: visibility 350ms, opacity 350ms; }
  .mobile-navigation-subNavigation-item .container {
    position: relative;
    display: flex;
    flex-direction: column; }
  .mobile-navigation-subNavigation-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    height: 5.55556rem;
    margin-bottom: 1.11111rem;
    color: #fff;
    font-size: 1.77778rem;
    border-bottom: 1px solid rgba(151, 151, 151, 0.25); }
  .mobile-navigation-subNavigation-item-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    margin-right: 15px;
    transform: rotateZ(180deg); }
    .mobile-navigation-subNavigation-item-arrow svg {
      width: 30px;
      height: 30px; }
  .mobile-navigation-subNavigation-item a {
    color: #a0a5a9;
    font-size: 1.44444rem;
    line-height: 3.88889rem;
    margin-left: 3.88889rem;
    text-decoration: none; }
    .mobile-navigation-subNavigation-item a:hover, .mobile-navigation-subNavigation-item a:active, .mobile-navigation-subNavigation-item a.active {
      color: #ce003c; }
  .mobile-navigation-subNavigation-item-background {
    position: absolute;
    top: 30%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(9, 21, 32, 0.78);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
    filter: grayscale(100%); }

.mobile-navigation-subNavigation-open .mobile-navigation-main {
  transform: translateX(-100%); }

.mobile-navigation-subNavigation-open .mobile-navigation-subNavigation {
  transform: translateX(0); }

.mobile-navigation-subNavigation-open .mobile-navigation-subNavigation-item-open {
  visibility: visible;
  opacity: 1; }

.mobile-navigation-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .mobile-navigation-toggle svg {
    fill: #fff; }
  .mobile-navigation-toggle-icon {
    transition: opacity 350ms, transform 350ms; }
  .mobile-navigation-toggle .mobile-navigation-cross {
    position: absolute;
    opacity: 0;
    transform: translateX(100%); }
  .mobile-navigation-toggle-open .mobile-navigation-hamburger {
    opacity: 0; }
  .mobile-navigation-toggle-open .mobile-navigation-cross {
    transform: translateX(0);
    opacity: 1; }
  @media only screen and (min-width: 992px) {
    .mobile-navigation-toggle {
      display: none; } }

.search-results {
  padding-top: 1.11111rem; }
  .search-results-term {
    display: flex;
    flex-wrap: wrap;
    font-size: 2.33333rem; }
    .search-results-term-title {
      margin-right: 1.11111rem; }
    .search-results-term-text {
      padding: 0.22222rem 0;
      color: #ce003c;
      border-bottom: 2px solid #ce003c; }
  .search-results-list {
    counter-reset: section; }
    .search-results-list-item {
      margin-top: 1.66667rem; }
      .search-results-list-item:last-child {
        margin-bottom: 0; }
        .search-results-list-item:last-child .search-results-list-item-separator {
          display: none; }
      .search-results-list-item-title {
        margin-bottom: 1.11111rem;
        font-size: 1.66667rem;
        text-decoration: none; }
      .search-results-list-item-description {
        font-size: 1rem;
        line-height: 1.66667rem; }
        @media only screen and (max-width: 991px) {
          .search-results-list-item-description {
            color: #768693; } }
      .search-results-list-item-separator {
        display: flex;
        height: 1px;
        margin-top: 1.38889rem; }
        .search-results-list-item-separator::before {
          content: "";
          width: 100%;
          height: 1px;
          background: #292929; }

.footer {
  padding-top: 2.22222rem;
  padding-bottom: 2.22222rem;
  background-color: #000;
  background-image: linear-gradient(0deg, rgba(82, 82, 82, 0.4) 0%, #000000 100%); }
  .footer-top {
    display: flex;
    align-items: center; }
  .footer-topLogo {
    color: #3d4543;
    width: 12.22222rem;
    height: 4.16667rem !important;
    min-width: 100px;
    min-height: 30px; }
  .footer-topSeparator {
    flex-grow: 1;
    height: 3px;
    margin-left: 2.33333rem;
    background: #ce003c; }
    @media only screen and (max-width: 991px) {
      .footer-topSeparator {
        margin-left: 1.11111rem; } }
  .footer-content {
    padding: 1.11111rem 0;
    color: #768693; }
    .footer-content-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap; }
      @media only screen and (max-width: 991px) {
        .footer-content-left {
          flex-direction: row;
          justify-content: flex-start; } }
    .footer-content-slogan {
      font-size: 0.88889rem;
      font-weight: 300;
      line-height: 1.16667rem;
      min-height: 70px; }
    @media only screen and (max-width: 991px) {
      .footer-content-follow {
        text-align: right; } }
    @media only screen and (max-width: 360px) {
      .footer-content-follow {
        width: 100%; } }
    .footer-content-follow-text {
      color: #fff;
      font-size: 1.11111rem;
      font-weight: 300;
      line-height: 1.55556rem;
      margin-bottom: 0.55556rem; }
    .footer-content-follow-social-networks a:not(:last-child) {
      margin-right: 1.11111rem; }
    .footer-content-follow-social-networks a > img {
      height: 1.11111rem;
      width: 1.11111rem; }
    .footer-content-copyright {
      text-transform: uppercase;
      font-size: 0.77778rem;
      font-weight: 300;
      line-height: 1rem;
      margin-top: 1rem; }
    .footer-content-navigation {
      display: flex;
      justify-content: space-around;
      font-size: 0.77778rem;
      font-weight: 500; }
      .footer-content-navigation-list {
        display: flex;
        flex-direction: column; }
        .footer-content-navigation-list a {
          color: #768693;
          text-transform: uppercase;
          padding: 0.55556rem 0;
          line-height: 1.22222rem;
          text-decoration: none; }
          .footer-content-navigation-list a:hover {
            color: #fff; }

.hero {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px; }
  @media only screen and (max-width: 991px) {
    .hero {
      height: 102vw; } }
  .hero-title {
    width: 60%;
    align-self: flex-start;
    text-align: left;
    font-size: 2.77778rem;
    font-weight: 500;
    line-height: 3.33333rem;
    color: #fff; }
    @media only screen and (max-width: 991px) {
      .hero-title {
        margin-bottom: 50px; } }
    @media only screen and (max-width: 767px) {
      .hero-title {
        font-size: 30px;
        line-height: 35px;
        width: 80vw; } }
    @media (max-width: 320px) {
      .hero-title {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px; } }
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    opacity: 0.32;
    filter: grayscale(76%); }
    .hero-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0) 60%, #000); }
  .hero-title, .hero-sliders {
    z-index: 3; }

.hero-slider {
  margin-left: 14vw;
  max-width: 625px;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .hero-slider {
      margin-left: 0;
      max-width: none; } }
  .hero-slider-slick {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 295px; }
    @media only screen and (max-width: 1199px) {
      .hero-slider-slick {
        max-height: 290px; } }
    @media only screen and (max-width: 991px) {
      .hero-slider-slick {
        max-height: 177px; } }
    .hero-slider-slick.slick-slider {
      overflow: visible;
      max-height: none; }
    .hero-slider-slick-elem-wrapper {
      display: flex; }
    .hero-slider-slick .slick-arrow {
      display: none !important; }
  .hero-slider-content {
    margin-left: 27px;
    max-width: 361px; }
    @media only screen and (max-width: 991px) {
      .hero-slider-content {
        margin-left: 29px;
        max-width: none; } }
    @media (max-width: 320px) {
      .hero-slider-content {
        margin-left: 10px; } }
  .hero-slider-promotion {
    display: flex; }
    .hero-slider-promotion-label {
      display: inline-block;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 300;
      background-color: #19262f;
      height: 31px;
      line-height: 31px;
      width: 167px;
      padding-left: 10px;
      margin-top: 51px; }
      @media only screen and (max-width: 991px) {
        .hero-slider-promotion-label {
          font-size: 11px;
          height: 20px;
          line-height: 20px;
          width: 74px;
          padding-left: 5px;
          margin-top: 23px; } }
  .hero-slider-index {
    line-height: 1;
    vertical-align: bottom;
    position: relative;
    top: -13px;
    font-weight: 500; }
    @media only screen and (max-width: 991px) {
      .hero-slider-index {
        top: -6px; } }
    .hero-slider-index-wrapper {
      display: inline-block;
      color: #19262f;
      background-color: #fff;
      font-size: 2.77778rem;
      width: 66px;
      height: 102px;
      box-shadow: 0 0 105px 0 rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      text-align: center;
      line-height: 102px; }
      @media only screen and (max-width: 991px) {
        .hero-slider-index-wrapper {
          font-size: 23px;
          width: 30px;
          height: 46px;
          box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          line-height: 46px; } }
  .hero-slider-image {
    display: inline-block;
    width: 262px;
    height: 174px;
    border: solid 5px #fff;
    background-color: #fff;
    background-size: cover;
    background-position: top center;
    margin-top: 51px; }
    @media only screen and (max-width: 991px) {
      .hero-slider-image {
        width: 123px;
        min-width: 123px;
        height: 81px;
        border: solid 2px #fff;
        margin-top: 60px; } }
    @media (max-width: 320px) {
      .hero-slider-image {
        width: 103px;
        min-width: 103px;
        height: 61px; } }
  .hero-slider-title {
    line-height: 2.72222rem;
    margin-top: 15px;
    margin-bottom: 26px;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .hero-slider-title {
        line-height: 25px;
        margin-top: 13px;
        margin-bottom: 13px; } }
    .hero-slider-title::after {
      content: '\A';
      position: absolute;
      left: 0;
      bottom: -6px;
      display: inline-block;
      background-color: #ce003c;
      width: 70px;
      height: 5px; }
      @media only screen and (max-width: 991px) {
        .hero-slider-title::after {
          bottom: -10px;
          width: 35px;
          height: 3px; } }
  .hero-slider-description {
    color: #a0a5a9;
    line-height: 1.44444rem;
    margin-bottom: 17px;
    max-height: 49px;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 991px) {
      .hero-slider-description {
        line-height: 20px;
        margin-bottom: 5px;
        max-height: 45px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding-right: 10px; } }
  .hero-slider-link {
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .hero-slider-link {
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }
  .hero-slider-arrow {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 58px;
    overflow: hidden; }
    .hero-slider-arrow > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .hero-slider-arrow {
        width: 30px;
        height: 30px;
        top: 10px; } }
    @media only screen and (max-width: 991px) {
      .hero-slider-arrow-icon {
        width: 14px;
        height: 31px; } }
    .hero-slider-arrow.arrow-prev {
      left: 520px;
      transform: rotate(180deg); }
      @media only screen and (max-width: 991px) {
        .hero-slider-arrow.arrow-prev {
          left: 260px; } }
      @media (max-width: 320px) {
        .hero-slider-arrow.arrow-prev {
          left: 230px; } }
    .hero-slider-arrow.arrow-next {
      left: 560px; }
      @media only screen and (max-width: 991px) {
        .hero-slider-arrow.arrow-next {
          left: 295px; } }
      @media (max-width: 320px) {
        .hero-slider-arrow.arrow-next {
          left: 260px; } }

.hero-inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 458px; }
  @media only screen and (max-width: 991px) {
    .hero-inner {
      height: 53vw;
      justify-content: flex-start;
      padding-top: 10vw; } }
  .hero-inner-title {
    align-self: flex-start;
    text-align: left;
    font-size: 2.22222rem;
    font-weight: 500;
    line-height: 3.33333rem;
    color: #fff;
    z-index: 3;
    margin: 0;
    display: inline; }
    @media only screen and (max-width: 767px) {
      .hero-inner-title {
        font-size: 30px;
        line-height: 35px;
        width: 80vw; } }
    @media (max-width: 320px) {
      .hero-inner-title {
        font-size: 20px;
        line-height: 25px; } }
    .hero-inner-title::after {
      content: '\A';
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image: url("../images/icons/corner-red.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      left: 5px;
      top: -20px; }
      @media only screen and (max-width: 767px) {
        .hero-inner-title::after {
          width: 9px;
          height: 9px;
          left: 0;
          top: -15px; } }
      @media (max-width: 320px) {
        .hero-inner-title::after {
          top: -8px; } }
  .hero-inner-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    opacity: 0.32;
    filter: grayscale(76%); }
    .hero-inner-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(0deg, #000000 11%, rgba(0, 0, 0, 0) 27%); }
      @media only screen and (max-width: 991px) {
        .hero-inner-image-overlay {
          background-image: linear-gradient(0deg, #000000 14%, rgba(0, 0, 0, 0) 36%); } }

.link-section {
  background-color: #fff;
  color: #19262f;
  padding-top: 83px;
  padding-bottom: 69px; }
  @media only screen and (max-width: 991px) {
    .link-section {
      padding-top: 37px;
      padding-bottom: 27px; } }
  .link-section-image {
    width: 100%;
    border-radius: 30px;
    margin-left: 10px; }
    @media only screen and (max-width: 991px) {
      .link-section-image {
        border-radius: 10px;
        margin-left: 0; } }
  .link-section-title {
    line-height: 1; }
    @media only screen and (max-width: 991px) {
      .link-section-title {
        margin-top: 32px; } }
  .link-section-subtitle {
    position: relative;
    font-weight: 500;
    font-size: 1.66667rem;
    color: #a0a5a9;
    line-height: 2.44444rem;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px; }
    @media only screen and (max-width: 991px) {
      .link-section-subtitle {
        text-align: center;
        line-height: 26px;
        padding: 0 10px;
        margin-bottom: 30px; } }
    .link-section-subtitle-corner {
      color: #ce003c; }
      @media only screen and (max-width: 991px) {
        .link-section-subtitle-corner {
          width: 25px;
          height: 25px; } }
      .link-section-subtitle-corner-wrapper {
        display: inline-block;
        position: absolute;
        top: -25px;
        right: -10px; }
        @media only screen and (max-width: 991px) {
          .link-section-subtitle-corner-wrapper {
            top: -10px;
            right: 0; } }
  .link-section-description {
    color: #19262f;
    margin-bottom: 50px; }
  .link-section-links {
    list-style: none;
    margin: 0;
    padding: 0; }
    .link-section-links-item {
      display: inline-block;
      color: #fff;
      background-color: #ce003c;
      min-width: 183px;
      text-align: center;
      height: 38px;
      line-height: 38px;
      border-radius: 19px;
      margin-right: 26px;
      margin-bottom: 10px; }
      @media only screen and (max-width: 991px) {
        .link-section-links-item {
          min-width: 127px;
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
          margin-right: 10px; } }
      .link-section-links-item:last-child {
        margin-right: 0; }
      .link-section-links-item-link {
        display: block;
        font-weight: 500;
        font-size: 0.88889rem;
        text-transform: uppercase;
        text-decoration: none; }

.facts-section {
  position: relative;
  padding-top: 108px;
  padding-bottom: 124px; }
  @media only screen and (max-width: 991px) {
    .facts-section {
      padding-top: 24px;
      padding-bottom: 42px; } }
  @media only screen and (max-width: 767px) {
    .facts-section-badges-wrapper {
      overflow: scroll; } }
  .facts-section-background {
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: grayscale(100%); }
  .facts-section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 1;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 75%, #000000 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85) 75%, #000000 100%); }
  .facts-section-title {
    margin-top: 24px;
    margin-bottom: 31px; }
    @media only screen and (max-width: 991px) {
      .facts-section-title {
        margin-top: 0;
        margin-bottom: 12px; } }
  .facts-section-description {
    color: #fff;
    margin: 0; }
    @media only screen and (max-width: 991px) {
      .facts-section-description {
        margin-bottom: 35px; } }
  .facts-section-badges {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -0.55556rem; }
  .facts-section-badge {
    position: relative;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 11.11111rem;
    height: 16.66667rem;
    margin: 0.55556rem;
    padding: 0.55556rem;
    border: solid 1px #ce003c;
    border-radius: 1.66667rem; }
    @media only screen and (max-width: 991px) {
      .facts-section-badge {
        width: 7.77778rem;
        height: 12.22222rem;
        border-radius: 1.38889rem; } }
    .facts-section-badge-center {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative; }
    .facts-section-badge-image {
      height: 70px;
      width: auto; }
      @media only screen and (max-width: 991px) {
        .facts-section-badge-image {
          height: 2.5rem; } }
      .facts-section-badge-image img {
        height: 100%; }
      .facts-section-badge-image-container {
        width: 90px;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }
        @media only screen and (max-width: 991px) {
          .facts-section-badge-image-container {
            height: 2.5rem;
            width: 3.22222rem; } }
    .facts-section-badge-value {
      font-size: 2.77778rem;
      color: #fff;
      text-transform: uppercase; }
      @media only screen and (max-width: 991px) {
        .facts-section-badge-value {
          font-size: 1.94444rem; } }
    .facts-section-badge-title {
      position: absolute;
      bottom: 22%;
      right: -10.7rem;
      height: 45px;
      width: 100%;
      margin: 0;
      text-transform: uppercase;
      color: #a0a5a9;
      font-size: 1rem;
      line-height: 1.27778rem;
      transform: rotate(-90deg);
      transform-origin: bottom left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      @media only screen and (max-width: 991px) {
        .facts-section-badge-title {
          font-size: 0.72222rem;
          line-height: 0.88889rem;
          width: 100%;
          right: -7.4rem; } }

.facts-section-5 {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6.88889rem; }
  .facts-section-5-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 1;
    filter: grayscale(100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .facts-section-5-title {
    margin-bottom: 1.72222rem; }
  .facts-section-5-badges {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -0.55556rem; }
  .facts-section-5-badge {
    position: relative;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 11.11111rem;
    height: 16.66667rem;
    margin: 0.55556rem;
    padding: 0.55556rem;
    border: solid 1px #ce003c;
    border-radius: 1.66667rem; }
    @media only screen and (max-width: 991px) {
      .facts-section-5-badge {
        width: 7.77778rem;
        height: 12.22222rem;
        border-radius: 1.38889rem; } }
    .facts-section-5-badge-center {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative; }
    .facts-section-5-badge-image {
      height: 70px;
      width: auto; }
      @media only screen and (max-width: 991px) {
        .facts-section-5-badge-image {
          height: 2.5rem; } }
      .facts-section-5-badge-image img {
        height: 100%; }
      .facts-section-5-badge-image-container {
        width: 90px;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }
        @media only screen and (max-width: 991px) {
          .facts-section-5-badge-image-container {
            height: 2.5rem;
            width: 3.22222rem; } }
    .facts-section-5-badge-value {
      font-size: 2.77778rem;
      color: #fff;
      text-transform: uppercase; }
      @media only screen and (max-width: 991px) {
        .facts-section-5-badge-value {
          font-size: 1.94444rem; } }
    .facts-section-5-badge-title {
      position: absolute;
      bottom: 22%;
      right: -10.7rem;
      height: 45px;
      width: 100%;
      margin: 0;
      text-transform: uppercase;
      color: #a0a5a9;
      font-size: 1rem;
      line-height: 1.27778rem;
      transform: rotate(-90deg);
      transform-origin: bottom left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      @media only screen and (max-width: 991px) {
        .facts-section-5-badge-title {
          font-size: 0.72222rem;
          line-height: 0.88889rem;
          width: 100%;
          right: -7.4rem; } }

.services-section {
  background-color: #fff;
  margin-right: 71px;
  color: #19262f;
  position: relative;
  padding: 46px 142px 61px 212px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; }
  @media only screen and (max-width: 991px) {
    .services-section {
      margin-right: 9px;
      padding: 34px 0 45px 87px; } }
  .services-section-title {
    margin-bottom: 42px; }
    @media only screen and (max-width: 991px) {
      .services-section-title {
        margin-bottom: 6px; } }
  .services-section-nav {
    position: absolute;
    top: -25px;
    left: 42px;
    background-color: #e6e6e6;
    box-shadow: 7px 0 14px 0 rgba(0, 0, 0, 0.32);
    border-radius: 30px;
    width: 127px;
    height: calc(100% + 50px);
    padding-top: 20px;
    z-index: 1;
    overflow: auto; }
    @media only screen and (max-width: 1199px) {
      .services-section-nav {
        padding-top: 20px; } }
    @media only screen and (max-width: 991px) {
      .services-section-nav {
        left: 0;
        width: 64px;
        transform: translateY(-50%);
        top: 50%;
        height: auto;
        padding-top: 14px;
        padding-bottom: 14px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }
    .services-section-nav-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 20px; }
      @media only screen and (max-width: 991px) {
        .services-section-nav-list {
          padding-bottom: 0; } }
      .services-section-nav-list-elem {
        text-align: center;
        margin-bottom: 7px; }
        @media only screen and (max-width: 1199px) {
          .services-section-nav-list-elem {
            margin-bottom: 1px; } }
        @media only screen and (max-width: 991px) {
          .services-section-nav-list-elem {
            height: 32px;
            margin-bottom: 13px; } }
        .services-section-nav-list-elem:last-child {
          margin-bottom: 0; }
        .services-section-nav-list-elem.active {
          height: 59px;
          pointer-events: none; }
          @media only screen and (max-width: 991px) {
            .services-section-nav-list-elem.active {
              height: 47px; } }
          .services-section-nav-list-elem.active .services-section-nav-button-icon {
            width: 47px;
            height: 47px;
            color: #ce003c; }
            @media only screen and (max-width: 991px) {
              .services-section-nav-list-elem.active .services-section-nav-button-icon {
                width: 47px;
                height: 47px; } }
    .services-section-nav-button {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer; }
      .services-section-nav-button > span {
        position: relative; }
      .services-section-nav-button:focus {
        outline: 0; }
      .services-section-nav-button-icon {
        width: 40px;
        height: 40px;
        color: #a0a5a9;
        transition: width 350ms, height 350ms, color 350ms; }
        .services-section-nav-button-icon:hover {
          width: 47px;
          height: 47px;
          color: #ce003c; }
        @media only screen and (max-width: 991px) {
          .services-section-nav-button-icon {
            width: 32px;
            height: 32px; } }
  .services-section-slick {
    overflow: hidden;
    max-height: 367px; }
    @media only screen and (max-width: 1199px) {
      .services-section-slick {
        max-height: 293px; } }
    @media only screen and (max-width: 991px) {
      .services-section-slick {
        max-height: 70vw; } }
    .services-section-slick.slick-slider {
      overflow: visible;
      max-height: none; }
    .services-section-slick-wrapper {
      position: relative; }
  .services-section-corner {
    color: #ce003c; }
    @media only screen and (max-width: 991px) {
      .services-section-corner {
        width: 29px;
        height: 29px; } }
    .services-section-corner-wrapper {
      display: inline-block;
      position: absolute;
      top: -45px;
      right: -45px; }
      @media only screen and (max-width: 991px) {
        .services-section-corner-wrapper {
          top: -17px;
          right: -17px; } }
  @media only screen and (max-width: 991px) {
    .services-section-elem {
      padding-right: 28px; } }
  .services-section-elem-title {
    font-size: 1.66667rem;
    color: #19262f;
    letter-spacing: 0;
    margin-top: 30px; }
    @media only screen and (max-width: 991px) {
      .services-section-elem-title {
        margin-top: 0;
        margin-bottom: 11px;
        font-size: 16px; } }
  .services-section-elem-description {
    color: #19262f; }
  .services-section-elem-link {
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    margin-right: 26px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .services-section-elem-link {
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        margin-right: 10px;
        margin-bottom: 0; } }
  .services-section-elem-image {
    width: 100%;
    border-radius: 15px; }
    @media only screen and (max-width: 991px) {
      .services-section-elem-image {
        margin-bottom: 12px; } }
    @media only screen and (max-width: 991px) {
      .services-section-elem-image-wrapper {
        position: relative; } }
  .services-section .slick-dots {
    display: none !important; }

.cards-container {
  margin-top: 37px;
  position: relative; }
  .cards-container-title {
    font-size: 1.77778rem;
    margin-bottom: 40px;
    margin-top: 20px; }
  .cards-container-cardwrapper {
    height: 354px;
    margin-bottom: 2.22222rem;
    border-radius: 30px;
    overflow: hidden; }
    @media only screen and (max-width: 991px) {
      .cards-container-cardwrapper {
        height: 47vw;
        margin-bottom: 15px;
        border-radius: 15px; } }
    .cards-container-cardwrapper.small {
      height: 228px; }
      @media only screen and (max-width: 991px) {
        .cards-container-cardwrapper.small {
          height: 30.5vw; } }
  .cards-container-row-vertical {
    flex-direction: column;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .cards-container-row-vertical {
        width: auto; } }
    .cards-container-row-vertical .cards-container-col {
      width: 100%; }
    @media only screen and (min-width: 992px) {
      .cards-container-row-vertical {
        flex-direction: row; }
        .cards-container-row-vertical .cards-container-col {
          margin-bottom: 20px;
          width: 50%; } }
  .cards-container-backgroundimage {
    position: absolute;
    top: -35%;
    right: 0;
    height: 100%;
    z-index: -1; }
    @media only screen and (max-width: 991px) {
      .cards-container-backgroundimage {
        height: auto;
        width: 60%;
        top: 0;
        transform: translateY(-30%); } }
    .cards-container-backgroundimage img {
      width: 100%; }
  @media only screen and (max-width: 991px) {
    .cards-container .card-fluid-mobile .cards-container-cardwrapper {
      height: auto; } }
  @media only screen and (max-width: 991px) {
    .cards-container .cards-container-cardwrapper {
      height: auto; } }
  @media only screen and (min-width: 992px) {
    .cards-container.container-fluid .cards-container-col {
      padding-left: 10px;
      padding-right: 10px; }
      .cards-container.container-fluid .cards-container-col:first-child {
        padding-left: 0; }
      .cards-container.container-fluid .cards-container-col:last-child {
        padding-right: 0; } }

.card-link {
  background-position-x: 20vw;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  color: #fff;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .card-link {
      background-position-x: 35vw; } }
  .card-link::after {
    content: '\A';
    background-color: #000;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .card-link-content {
    background-color: rgba(206, 0, 60, 0.7);
    height: 100%;
    width: 50%;
    padding-left: 78px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 1199px) {
      .card-link-content {
        padding-left: 50px; } }
    @media only screen and (max-width: 991px) {
      .card-link-content {
        padding-left: 27px;
        padding-bottom: 20px;
        border-top-right-radius: 15px; } }
  .card-link-title {
    color: #fff;
    font-size: 1.66667rem;
    margin: 0;
    padding: 61px 32px 32px 0; }
    @media only screen and (max-width: 991px) {
      .card-link-title {
        font-size: 16px;
        padding: 30px 27px 10px 0; } }
  .card-link-more {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.88889rem;
    border: solid 1px #fff;
    width: 167px;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    display: inline-block;
    text-align: center;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .card-link-more {
        font-size: 11px;
        width: 116px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }

.card-quotes {
  background-color: #fff;
  height: 100%;
  color: #19262f;
  padding-top: 57px;
  padding-left: 100px;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .card-quotes {
      padding-top: 27px;
      padding-left: 52px;
      padding-right: 87px; } }
  .card-quotes-title {
    color: #19262f;
    font-size: 1.66667rem;
    margin-bottom: 15px; }
    @media only screen and (max-width: 991px) {
      .card-quotes-title {
        font-size: 16px;
        margin-bottom: 5px; } }
  .card-quotes-slick-elem p {
    font-size: 1.11111rem;
    font-weight: 300;
    margin: 0;
    max-width: 390px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 210px; }
    @media only screen and (max-width: 991px) {
      .card-quotes-slick-elem p {
        font-size: 13px;
        max-width: none;
        max-height: 26.5vw; } }
  .card-quotes-slick .slick-arrow {
    display: none !important; }
  .card-quotes-quoteicon {
    position: absolute;
    top: 59px;
    left: 29px;
    color: #a0a5a9; }
    @media only screen and (max-width: 991px) {
      .card-quotes-quoteicon {
        width: 24px;
        height: 19px;
        top: 30px;
        left: 15px; } }
  .card-quotes-arrow {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 58px;
    overflow: hidden; }
    .card-quotes-arrow > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .card-quotes-arrow {
        width: 30px;
        height: 30px;
        top: 25px; } }
    @media only screen and (max-width: 991px) {
      .card-quotes-arrow-icon {
        width: 14px;
        height: 31px; } }
    .card-quotes-arrow.arrow-prev {
      right: 114px;
      transform: rotate(180deg); }
      @media only screen and (max-width: 1199px) {
        .card-quotes-arrow.arrow-prev {
          right: 71px; } }
      @media only screen and (max-width: 991px) {
        .card-quotes-arrow.arrow-prev {
          right: 50px; } }
    .card-quotes-arrow.arrow-next {
      right: 72px; }
      @media only screen and (max-width: 1199px) {
        .card-quotes-arrow.arrow-next {
          right: 29px; } }
      @media only screen and (max-width: 991px) {
        .card-quotes-arrow.arrow-next {
          right: 16px; } }

.card-video {
  height: 100%;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .card-video {
      padding-top: 30vw; } }
  @media only screen and (max-width: 767px) {
    .card-video {
      padding-top: 47vw; } }
  .card-video-background {
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(4px); }
    .card-video-background::after {
      content: '\A';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: .6; }
  .card-video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .card-video-title {
    color: #fff;
    margin: 0;
    font-size: 1.66667rem;
    width: 304px;
    margin-top: 18px; }
    @media only screen and (max-width: 991px) {
      .card-video-title {
        font-size: 16px;
        width: 162px;
        margin-top: 8px; } }
  .card-video-button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: transparent;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%; }
    .card-video-button > span {
      position: relative; }
  .card-video-icon {
    display: flex;
    background-color: #fff;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.5); }
    @media only screen and (max-width: 991px) {
      .card-video-icon {
        width: 49px;
        height: 49px; }
        .card-video-icon svg {
          width: 17px;
          height: 22px; } }
  .card-video-popup {
    position: relative;
    background: transparent;
    width: auto;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 30px; }
    @media only screen and (max-width: 991px) {
      .card-video-popup {
        border-radius: 15px; } }
    .card-video-popup .mfp-close {
      display: none; }
    .card-video-popup-video {
      width: 100%;
      display: block;
      border-radius: 30px; }
      @media only screen and (max-width: 991px) {
        .card-video-popup-video {
          border-radius: 15px; } }
    .card-video-popup-close {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: -10vh;
      right: -87px; }
      .card-video-popup-close > span {
        position: relative; }
      @media only screen and (max-width: 991px) {
        .card-video-popup-close {
          position: fixed;
          top: auto;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          bottom: 40px;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: solid 1px #3b3b3b; }
          .card-video-popup-close-icon {
            width: 16px;
            height: 16px; } }
      @media (max-height: 450px) {
        .card-video-popup-close {
          position: absolute;
          bottom: -80px; } }

.card-newsletter {
  font-weight: 500;
  background-color: #ce003c;
  background-image: url("/ResourcePackages/Theme/assets/dist/images/card-newsletter-image.png");
  background-size: cover;
  background-position: center center;
  height: 100%;
  text-align: center;
  color: #fff;
  padding-top: 74px; }
  @media only screen and (max-width: 991px) {
    .card-newsletter {
      padding-top: 26px; } }
  .card-newsletter-title {
    color: #fff;
    font-size: 1.66667rem;
    margin-bottom: 11px; }
    @media only screen and (max-width: 991px) {
      .card-newsletter-title {
        margin-bottom: 5px; } }
  .card-newsletter-description {
    font-size: 1rem;
    line-height: 1.66667rem;
    max-width: 473px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px; }
    @media only screen and (max-width: 991px) {
      .card-newsletter-description {
        font-size: 16px;
        margin-bottom: 30px; } }
  .card-newsletter-email {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    background-color: #fff;
    border-radius: 9px;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    color: #19262f;
    padding: 0 30px 0 14px; }
    .card-newsletter-email::-ms-expand, .card-newsletter-email::-ms-clear {
      display: none; }
    .card-newsletter-email::placeholder {
      color: #768693; }
    .card-newsletter-email:focus + .card-newsletter-email-corner {
      color: #ce003c; }
    @media only screen and (max-width: 991px) {
      .card-newsletter-email {
        border-radius: 5px;
        height: 54px;
        font-size: 16px; } }
    .card-newsletter-email-wrapper {
      display: inline-block;
      position: relative;
      width: 100%;
      max-width: 308px; }
      @media only screen and (max-width: 991px) {
        .card-newsletter-email-wrapper {
          max-width: none;
          padding: 0 20px; } }
      .card-newsletter-email-wrapper.error .error-inline {
        position: absolute;
        left: 0;
        top: -25px;
        font-weight: 500;
        font-size: 0.83333rem; }
        @media only screen and (max-width: 991px) {
          .card-newsletter-email-wrapper.error .error-inline {
            position: static;
            font-size: 12px;
            margin-top: 10px; } }
    .card-newsletter-email-corner {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #768693;
      transition: color 350ms;
      width: 21px;
      height: 20px; }
      @media only screen and (max-width: 991px) {
        .card-newsletter-email-corner {
          width: 14px;
          height: 13px;
          top: 8px;
          right: 28px; }
          .card-newsletter-email-corner svg {
            width: 14px;
            height: 13px; } }
  .card-newsletter-subscribe {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.88889rem;
    text-transform: uppercase;
    color: #fff;
    border: solid 1px #fff;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    padding: 0 20px;
    margin-left: 17px; }
    .card-newsletter-subscribe > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .card-newsletter-subscribe {
        margin-top: 14px;
        margin-bottom: 30px;
        margin-left: 0;
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }

.card-article {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center center; }
  @media only screen and (max-width: 991px) {
    .card-article {
      padding-top: 30vw; } }
  @media only screen and (max-width: 767px) {
    .card-article {
      padding-top: 47vw; } }
  .card-article-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .6;
    z-index: 0; }
  .card-article-content {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 33px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 991px) {
      .card-article-content {
        margin-left: 28px;
        margin-bottom: 20px; } }
  .card-article-date {
    font-weight: 300;
    margin-bottom: 3px; }
    @media only screen and (max-width: 767px) {
      .card-article-date {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 5px; } }
  .card-article-title {
    margin: 0;
    color: #fff;
    font-size: 1.66667rem;
    margin-bottom: 13px; }
    @media only screen and (max-width: 767px) {
      .card-article-title {
        font-size: 16px;
        margin-bottom: 9px; } }
  .card-article-link {
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .card-article-link {
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }
  .card-article-typeicon {
    display: flex;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6.4px;
    position: absolute;
    top: 22px;
    right: 23px;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .card-article-typeicon {
        width: 28px;
        height: 28px;
        border-radius: 3px;
        top: 14px;
        right: 14px; } }
    @media only screen and (max-width: 767px) {
      .card-article-typeicon svg {
        width: 15px;
        height: 14px; } }

.card-images {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center center; }
  .card-images-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .6;
    z-index: 0; }
  .card-images-content {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 33px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 991px) {
      .card-images-content {
        margin-left: 28px;
        margin-bottom: 20px; } }
  .card-images-date {
    font-weight: 300;
    margin-bottom: 3px; }
    @media only screen and (max-width: 767px) {
      .card-images-date {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 5px; } }
  .card-images-title {
    margin: 0;
    color: #fff;
    font-size: 1.66667rem;
    margin-bottom: 13px; }
    @media only screen and (max-width: 767px) {
      .card-images-title {
        font-size: 16px;
        margin-bottom: 9px; } }
  .card-images-button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px; }
    .card-images-button > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .card-images-button {
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }
  .card-images-typeicon {
    display: flex;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6.4px;
    position: absolute;
    top: 22px;
    right: 23px;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .card-images-typeicon {
        width: 28px;
        height: 28px;
        border-radius: 3px;
        top: 14px;
        right: 14px; } }
    @media only screen and (max-width: 767px) {
      .card-images-typeicon svg {
        width: 15px;
        height: 14px; } }

.card-quotes-single {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center; }
  .card-quotes-single::after {
    content: '\A';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ce003c;
    opacity: .8;
    z-index: 2; }
  .card-quotes-single-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    z-index: 1; }
  .card-quotes-single-icon {
    position: absolute;
    top: 39px;
    right: 29px;
    z-index: 3;
    color: #fff;
    transform: scaleX(-1);
    filter: FlipH; }
    @media only screen and (max-width: 767px) {
      .card-quotes-single-icon {
        width: 14px;
        height: 11px;
        top: 20px;
        right: 12px; } }
  .card-quotes-single-text {
    position: relative;
    z-index: 3;
    margin: 0;
    margin-left: 45px;
    max-width: 389px; }
    @media only screen and (max-width: 991px) {
      .card-quotes-single-text {
        margin-top: 25px;
        margin-bottom: 25px; } }
    @media only screen and (max-width: 767px) {
      .card-quotes-single-text {
        max-width: none;
        margin-left: 25px;
        margin-right: 25px; } }
    .card-quotes-single-text p {
      color: #fff;
      font-weight: 500;
      font-size: 1.66667rem;
      letter-spacing: 0;
      line-height: 2.33333rem;
      margin: 0; }
      @media only screen and (max-width: 767px) {
        .card-quotes-single-text p {
          font-size: 23px;
          line-height: 28px; } }

.card-faq {
  height: 100%;
  position: relative;
  background-color: #191919;
  padding: 32px 57px 0 47px; }
  @media only screen and (max-width: 767px) {
    .card-faq {
      padding: 29px 23px 0 23px; } }
  .card-faq-corner {
    color: #ce003c;
    position: absolute;
    top: 26px;
    right: 21px; }
    @media only screen and (max-width: 767px) {
      .card-faq-corner {
        width: 18px;
        height: 17px;
        top: 15px;
        right: 14px; } }
  .card-faq-question {
    font-weight: 500;
    font-size: 1.66667rem;
    letter-spacing: 0;
    line-height: 2.33333rem;
    margin-bottom: 15px; }
    @media only screen and (max-width: 767px) {
      .card-faq-question {
        margin-bottom: 10px; } }
  .card-faq-answer {
    color: #a0a5a9;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 200px; }
    @media only screen and (max-width: 991px) {
      .card-faq-answer {
        font-size: 1.22222rem;
        letter-spacing: 0;
        line-height: 1.77778rem;
        margin-bottom: 20px; } }

.promotion-section-slider {
  position: relative;
  padding-top: 109px;
  padding-bottom: 32px; }
  @media only screen and (max-width: 991px) {
    .promotion-section-slider {
      padding-top: 55px; } }
  .promotion-section-slider-maintitle {
    margin: 0;
    margin-bottom: 36px; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-maintitle {
        position: absolute;
        top: 20px; } }
    .promotion-section-slider-maintitle-col {
      width: 100%; }
  .promotion-section-slider-slick {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 445px; }
    @media only screen and (max-width: 1199px) {
      .promotion-section-slider-slick {
        max-height: 403px; } }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-slick {
        max-height: 87vw; } }
    .promotion-section-slider-slick.slick-slider {
      overflow: visible;
      max-height: none; }
    .promotion-section-slider-slick-elem-wrapper {
      display: flex; }
      @media only screen and (max-width: 991px) {
        .promotion-section-slider-slick-elem-wrapper {
          display: block;
          padding-top: 62px; } }
    .promotion-section-slider-slick .slick-arrow {
      display: none !important; }
  .promotion-section-slider-content {
    margin-left: 60px;
    margin-top: 25px; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-content {
        margin-left: 29px; } }
    @media (max-width: 320px) {
      .promotion-section-slider-content {
        margin-left: 10px; } }
  .promotion-section-slider-promotion {
    display: flex; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-promotion {
        position: absolute;
        top: 0;
        right: 85px; } }
    .promotion-section-slider-promotion-label {
      display: inline-block;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 300;
      background-color: #19262f;
      height: 31px;
      line-height: 31px;
      width: 167px;
      padding-left: 10px;
      margin-top: 51px; }
      @media only screen and (max-width: 991px) {
        .promotion-section-slider-promotion-label {
          font-size: 11px;
          height: 20px;
          line-height: 20px;
          width: 74px;
          padding-left: 5px;
          margin-top: 23px; } }
  .promotion-section-slider-index {
    line-height: 1;
    vertical-align: bottom;
    position: relative;
    top: -13px;
    font-weight: 500; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-index {
        top: -6px; } }
    .promotion-section-slider-index-wrapper {
      display: inline-block;
      color: #19262f;
      background-color: #fff;
      font-size: 2.77778rem;
      width: 66px;
      height: 102px;
      box-shadow: 0 0 105px 0 rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      text-align: center;
      line-height: 102px; }
      @media only screen and (max-width: 991px) {
        .promotion-section-slider-index-wrapper {
          font-size: 23px;
          width: 30px;
          height: 46px;
          box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          line-height: 46px; } }
  .promotion-section-slider-image {
    display: inline-block;
    width: 666px;
    min-width: 666px;
    height: 439px;
    background-size: cover;
    background-position: center center;
    border: solid 1px #19262f;
    border-radius: 30px; }
    @media only screen and (max-width: 1199px) {
      .promotion-section-slider-image {
        width: 536px;
        min-width: 536px;
        height: 398px; } }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-image {
        border-radius: 15px;
        width: 100%;
        min-width: 0;
        height: 59vw; } }
  .promotion-section-slider-title {
    line-height: 2.72222rem;
    margin-top: 15px;
    margin-bottom: 5px;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-title {
        line-height: 25px;
        margin-top: 13px;
        margin-bottom: 3px;
        font-size: 16px; } }
  .promotion-section-slider-description {
    line-height: 1.66667rem;
    margin-bottom: 23px;
    max-height: 66px;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 1199px) {
      .promotion-section-slider-description {
        max-height: 56px; } }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-description {
        line-height: 20px;
        margin-bottom: 5px;
        margin-right: 10px;
        max-height: 45px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        float: left ;
        width: 60%; } }
  .promotion-section-slider-link {
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-link {
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        padding: 0 17px;
        float: left ; } }
  .promotion-section-slider-terms {
    display: block;
    color: #768693;
    font-size: 0.88889rem;
    line-height: 1.66667rem;
    margin-top: 54px; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-terms {
        margin-top: 0;
        display: inline-block;
        width: 100%; } }
  .promotion-section-slider-arrow {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 80px;
    overflow: hidden;
    z-index: 1; }
    .promotion-section-slider-arrow > span {
      position: relative; }
    .promotion-section-slider-arrow-wrapper {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-arrow {
        width: 30px;
        height: 30px;
        top: 10px; } }
    @media only screen and (max-width: 991px) {
      .promotion-section-slider-arrow-icon {
        width: 14px;
        height: 31px; } }
    .promotion-section-slider-arrow.arrow-prev {
      right: 110px;
      transform: rotate(180deg); }
      @media only screen and (max-width: 1199px) {
        .promotion-section-slider-arrow.arrow-prev {
          right: 60px; } }
      @media only screen and (max-width: 991px) {
        .promotion-section-slider-arrow.arrow-prev {
          right: 35px; } }
    .promotion-section-slider-arrow.arrow-next {
      right: 70px; }
      @media only screen and (max-width: 1199px) {
        .promotion-section-slider-arrow.arrow-next {
          right: 20px; } }
      @media only screen and (max-width: 991px) {
        .promotion-section-slider-arrow.arrow-next {
          right: 0; } }

.promotion-section {
  margin-top: 80px; }
  @media only screen and (max-width: 991px) {
    .promotion-section {
      margin-top: 30px; } }
  .promotion-section-title {
    font-size: 1.77778rem;
    margin-bottom: 36px; }
    @media only screen and (max-width: 991px) {
      .promotion-section-title {
        margin-bottom: 26px; } }

.title-section-title {
  font-size: 2.33333rem;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0;
  color: #ce003c;
  margin-bottom: 17px; }
  @media only screen and (max-width: 767px) {
    .title-section-title {
      font-size: 23px;
      margin-bottom: 7px; } }

.title-section-description {
  font-size: 1.66667rem;
  line-height: 2.5rem;
  max-width: 946px;
  margin-bottom: 48px; }
  @media only screen and (max-width: 991px) {
    .title-section-description {
      max-width: 83vw;
      margin-bottom: 32px; } }
  @media only screen and (max-width: 767px) {
    .title-section-description {
      font-size: 13px;
      line-height: 20px; } }

.title-list-section {
  padding-bottom: 2.22222rem; }
  .title-list-section-title {
    font-size: 2.33333rem;
    font-weight: 500;
    color: #ce003c;
    margin-bottom: 2.5rem;
    line-height: normal; }
  .title-list-section-description {
    font-size: 1rem;
    line-height: 1.66667rem; }
  .title-list-section-list {
    margin-top: 1.38889rem; }
    .title-list-section-list-item-title {
      font-weight: 500;
      font-size: 1.66667rem; }
      .title-list-section-list-item-title::after {
        content: '\A';
        display: inline-block;
        width: 0.88889rem;
        height: 0.88889rem;
        background-image: url("../images/icons/corner-red.svg");
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        left: -2px;
        top: -12px; }
    .title-list-section-list-item-description {
      color: #768693; }
    .title-list-section-list-item:not(:last-child) {
      margin-bottom: 1.66667rem; }

.split-section {
  padding-bottom: 2.22222rem; }
  @media only screen and (min-width: 992px) {
    .split-section-content {
      padding-right: 3.33333rem; } }
  .split-section-title {
    font-size: 2.33333rem;
    font-weight: 500;
    color: #ce003c;
    margin-bottom: 2.5rem;
    line-height: normal; }
  .split-section-subtitle {
    margin-bottom: 0.83333rem;
    font-size: 1.66667rem;
    font-weight: 300;
    line-height: 2.5rem; }
  @media only screen and (max-width: 991px) {
    .split-section-subtitle + .split-section-description {
      color: #a0a5a9; } }
  .split-section-description {
    font-size: 1rem;
    line-height: 1.66667rem;
    font-weight: 300;
    line-height: 1.66667rem; }
  .split-section .split-section-image {
    height: 23.38889rem;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    box-shadow: 0 20px 140px 10px rgba(255, 255, 255, 0.18); }
    @media only screen and (max-width: 991px) {
      .split-section .split-section-image {
        height: 18.72222rem;
        width: 100%;
        margin-bottom: 2.77778rem;
        border-radius: 24px;
        box-shadow: 0 20px 140px 10px rgba(255, 255, 255, 0.05); } }
  .split-section-links {
    list-style: none;
    margin: 0;
    padding: 0; }
    .split-section-links-item {
      display: inline-block;
      color: #fff;
      background-color: #ce003c;
      min-width: 183px;
      text-align: center;
      height: 38px;
      line-height: 38px;
      border-radius: 19px;
      margin-right: 26px;
      margin-bottom: 10px; }
      @media only screen and (max-width: 991px) {
        .split-section-links-item {
          min-width: 127px;
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
          margin-right: 10px; } }
      .split-section-links-item:last-child {
        margin-right: 0; }
      .split-section-links-item-link {
        font-weight: 500;
        font-size: 0.88889rem;
        text-transform: uppercase;
        text-decoration: none; }

.single-link-section {
  height: 234px;
  position: relative;
  text-align: center;
  margin-top: 43px; }
  @media only screen and (max-width: 991px) {
    .single-link-section {
      height: 42vw;
      margin-top: 21px;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .single-link-section-image {
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .single-link-section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #000;
    opacity: .6; }
  .single-link-section-title {
    font-size: 1.66667rem;
    color: #fff;
    padding-top: 30px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 991px) {
      .single-link-section-title {
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 10px; } }
  .single-link-section-link {
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .single-link-section-link {
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }

.products-section {
  height: min-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 5.55556rem; }
  @media only screen and (max-width: 991px) {
    .products-section {
      background: #000 !important; } }
  .products-section .container {
    overflow: visible; }
    @media only screen and (min-width: 992px) {
      .products-section .container {
        position: relative; } }
  .products-section-list {
    display: flex;
    flex-wrap: nowrap; }
    @media only screen and (min-width: 992px) {
      .products-section-list {
        padding: 2.22222rem 2.77778rem 1.11111rem 2.77778rem;
        margin: 0 -2.77778rem 1.11111rem -2.77778rem;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; } }
    @media only screen and (max-width: 991px) {
      .products-section-list {
        display: block; } }
  .products-section-list-item {
    position: static;
    margin: 1.11111rem 0; }
    @media only screen and (min-width: 992px) {
      .products-section-list-item {
        padding-right: 60px; } }
    @media only screen and (max-width: 991px) {
      .products-section-list-item {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center; } }
    .products-section-list-item-header {
      font-weight: 500;
      font-size: 25px;
      margin-bottom: 0.55556rem; }
    .products-section-list-item-image {
      width: 7.83333rem;
      height: 11.66667rem;
      border-radius: 1.11111rem;
      border: 0.38889rem solid transparent;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      filter: brightness(70%);
      box-shadow: 0px 0px 80px -5px rgba(150, 150, 150, 0.4);
      transition: border 350ms, box-shadow 350ms, filter 350ms;
      background-color: #fff; }
      @media only screen and (min-width: 992px) {
        .products-section-list-item-image {
          margin-bottom: 1.38889rem; } }
      @media only screen and (max-width: 991px) {
        .products-section-list-item-image {
          filter: brightness(100%); } }
    .products-section-list-item-name {
      font-size: 0.88889rem;
      font-weight: 500;
      line-height: 1.11111rem;
      text-transform: uppercase;
      color: #bbbbbb;
      transition: color 350ms; }
      @media only screen and (max-width: 991px) {
        .products-section-list-item-name {
          color: #fff;
          margin-left: 25px; } }
    .products-section-list-item-view-details {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.5rem;
      text-transform: none;
      color: #ce003c;
      font-size: 0.88889rem;
      font-weight: 500;
      transition: opacity 700ms; }
      .products-section-list-item-view-details-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.27778rem; }
        .products-section-list-item-view-details-arrow, .products-section-list-item-view-details-arrow svg {
          width: 0.66667rem;
          height: 0.66667rem; }
      @media only screen and (min-width: 992px) {
        .products-section-list-item-view-details {
          display: none; } }
    .products-section-list-item.active .products-section-list-item-image {
      border-color: #ce003c;
      box-shadow: 0px 0px 80px -5px #ce003c;
      filter: brightness(100%); }
    .products-section-list-item.active .products-section-list-item-name {
      color: #fff; }
    .products-section-list-item.active .products-section-list-item-details {
      padding: 2.5rem;
      opacity: 1;
      transform: translateY(0);
      visibility: visible; }
      @media only screen and (min-width: 992px) {
        .products-section-list-item.active .products-section-list-item-details {
          transition-delay: 525ms; } }
      @media only screen and (max-width: 991px) {
        .products-section-list-item.active .products-section-list-item-details {
          margin-top: 2.22222rem; }
          .products-section-list-item.active .products-section-list-item-details > .row {
            max-height: 700px;
            overflow: scroll;
            -webkit-overflow-scrolling: touch; } }
    .products-section-list-item.active .products-section-list-item-view-details {
      opacity: 0; }
  @media only screen and (max-width: 991px) {
    .products-section-item-details-desktop-wrapper {
      display: none; } }
  .products-section-item-details-desktop {
    position: relative; }
  .products-section-list-item-details {
    padding: 2.5rem;
    border-radius: 24px;
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
    background: #fff;
    color: #19262f;
    user-select: text;
    transition: opacity 350ms, transform 350ms, visibility 350ms; }
    @media only screen and (min-width: 992px) {
      .products-section-list-item-details {
        position: absolute;
        top: 0;
        width: 100%; }
        .products-section-list-item-details-mobile {
          display: none; } }
    @media only screen and (max-width: 991px) {
      .products-section-list-item-details {
        position: relative;
        width: 100%;
        padding: 0 2.5rem;
        top: auto;
        bottom: auto;
        right: auto;
        left: auto;
        transform: translateY(0);
        transition: opacity 350ms 350ms, transform 350ms 350ms, padding 350ms 350ms, margin 350ms 350ms, visibility 350ms 350ms; }
        .products-section-list-item-details > .row {
          max-height: 0;
          overflow: auto;
          transition: max-height 350ms 350ms; } }
    .products-section-list-item-details-tip {
      position: absolute;
      top: 2px;
      left: 2.77778rem;
      height: 30px;
      width: 30px;
      border-radius: 5px;
      background: #fff;
      transform: translateY(-50%) rotateZ(45deg); }
    .products-section-list-item-details-close {
      position: absolute;
      top: 1rem;
      padding: 0.83333rem;
      right: 1rem; }
      .products-section-list-item-details-close svg {
        height: 1.11111rem;
        width: 1.11111rem;
        fill: #000;
        pointer-events: none; }
      @media only screen and (min-width: 992px) {
        .products-section-list-item-details-close {
          display: none; } }
    .products-section-list-item-details-property {
      position: relative;
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.27778rem; }
      @media only screen and (max-width: 991px) {
        .products-section-list-item-details-property-separator {
          height: 1px;
          background-color: rgba(151, 151, 151, 0.61);
          margin-top: 2.5rem; }
        .products-section-list-item-details-property:not(:last-child) {
          margin-bottom: 2.22222rem; } }
      .products-section-list-item-details-property h1, .products-section-list-item-details-property h2, .products-section-list-item-details-property h3, .products-section-list-item-details-property h4, .products-section-list-item-details-property h5, .products-section-list-item-details-property h6 {
        color: #19262f;
        font-weight: 500;
        margin-bottom: 1.66667rem;
        font-size: 1rem;
        line-height: 1.27778rem; }
      .products-section-list-item-details-property ul {
        margin: 0;
        padding: 0;
        padding-left: 1em; }
      .products-section-list-item-details-property-item-simple:not(:last-child) {
        margin-bottom: 1.22222rem; }
      .products-section-list-item-details-property-item-withImage {
        display: flex;
        align-items: center;
        margin-left: -1em; }
        .products-section-list-item-details-property-item-withImage:not(:last-child) {
          margin-bottom: 2.22222rem; }
        .products-section-list-item-details-property-item-withImage > img {
          height: 3rem;
          width: auto;
          margin-right: 1.11111rem; }
      .products-section-list-item-details-property:last-child .products-section-list-item-details-property-separator {
        display: none; }
    .products-section-list-item-details.active {
      padding: 2.5rem;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      transition-delay: 350ms; }
      .products-section-list-item-details.active .products-section-list-item-view-details {
        opacity: 0; }

.media-center {
  margin-top: 63px;
  transition: min-height 350ms; }
  @media only screen and (max-width: 991px) {
    .media-center {
      margin-top: 12px; } }
  .media-center-filters {
    border: solid 1px #3b3b3b;
    border-radius: 13px;
    padding: 0 40px;
    margin-bottom: 37px; }
    @media only screen and (max-width: 991px) {
      .media-center-filters {
        margin-top: 12px;
        background-color: #121212;
        border: solid 1px #232323;
        border-radius: 5px;
        padding: 19px 18px 2px 18px;
        position: relative; } }
    .media-center-filters-title {
      margin: 0;
      font-size: 16px;
      letter-spacing: 0;
      position: absolute;
      top: 14px;
      left: 18px; }
    .media-center-filters-icon {
      color: #ce003c; }
      .media-center-filters-icon-wrapper {
        text-align: right; }
    .media-center-filters-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      height: 75px;
      align-items: center; }
      @media only screen and (max-width: 991px) {
        .media-center-filters-list {
          padding-top: 29px;
          height: auto;
          flex-wrap: wrap; } }
      .media-center-filters-list-item {
        flex-basis: 20%; }
        @media only screen and (max-width: 767px) {
          .media-center-filters-list-item {
            flex-basis: 33.333%; } }
    @media only screen and (max-width: 991px) {
      .media-center-filters-input:checked + .media-center-filters-label {
        border: 1px solid #ce003c;
        background-color: #ce003c; } }
    .media-center-filters-input:checked + .media-center-filters-label .media-center-filters-switch::after {
      background-color: #ce003c;
      transform: translateX(20px); }
    .media-center-filters-input:focus + .media-center-filters-label {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color; }
    .media-center-filters-button-all {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      color: #fff; }
      .media-center-filters-button-all > span {
        position: relative; }
      @media only screen and (max-width: 991px) {
        .media-center-filters-button-all {
          font-size: 16px;
          width: 82px;
          line-height: 45px;
          border: 1px solid #3b3b3b;
          border-radius: 5px;
          text-align: center;
          margin-bottom: 19px;
          transition: background-color 350ms, border 350ms; } }
      @media only screen and (max-width: 991px) {
        .media-center-filters-button-all.active {
          border: 1px solid #ce003c;
          background-color: #ce003c; } }
      .media-center-filters-button-all.active .media-center-filters-switch::after {
        background-color: #ce003c;
        transform: translateX(20px); }
    .media-center-filters-label {
      cursor: pointer;
      margin: 0; }
      @media only screen and (max-width: 991px) {
        .media-center-filters-label {
          white-space: nowrap;
          font-size: 16px;
          width: 82px;
          line-height: 45px;
          border: 1px solid #3b3b3b;
          border-radius: 5px;
          text-align: center;
          margin-bottom: 19px;
          transition: background-color 350ms, border 350ms; } }
    .media-center-filters-switch {
      display: inline-block;
      width: 40px;
      height: 12px;
      border-radius: 6px;
      background-color: #3b3b3b;
      box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.15);
      position: relative;
      margin-right: 12px; }
      .media-center-filters-switch::after {
        content: '\A';
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: 0;
        background-color: #fff;
        transform: translateX(0);
        transition: transform 350ms, background-color 350ms; }
  .media-center-cardwrapper {
    height: 287px;
    margin-bottom: 55px;
    border-radius: 30px;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 350ms, transform 350ms; }
    @media only screen and (max-width: 991px) {
      .media-center-cardwrapper {
        height: 47vw;
        margin-bottom: 15px;
        border-radius: 15px; } }
  .media-center-loadmore {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    background-color: #ce003c;
    text-align: center;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    font-weight: 500;
    font-size: 0.88889rem;
    text-transform: uppercase;
    padding: 0 30px;
    margin-top: 41px; }
    .media-center-loadmore > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .media-center-loadmore {
        height: 30px;
        line-height: 30px;
        border-radius: 15px; } }
    .media-center-loadmore-row {
      display: none; }
      .media-center-loadmore-row.active {
        display: block; }
  .media-center.loading .media-center-cardwrapper {
    opacity: 0;
    transform: translateY(-20px); }

.quote-section {
  position: relative;
  margin-top: 3.05556rem; }
  @media only screen and (max-width: 991px) {
    .quote-section {
      margin-top: 11.11111rem; } }
  .quote-section .row {
    flex-direction: row; }
    @media only screen and (max-width: 991px) {
      .quote-section .row {
        flex-direction: column; } }
  .quote-section-dummy-background {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #fff; }
  .quote-section-background {
    padding-top: 2.22222rem;
    padding-bottom: 2.77778rem;
    border-top-right-radius: 1.66667rem;
    border-bottom-right-radius: 1.66667rem;
    background: #fff; }
  .quote-section-text {
    padding-left: 2.77778rem;
    padding-right: 2.77778rem; }
  .quote-section-text-quote {
    position: relative;
    color: #000;
    font-size: 2.33333rem;
    font-weight: 500;
    line-height: 2.94444rem;
    padding: 1.38889rem 0;
    margin-bottom: 0.55556rem; }
    .quote-section-text-quote::before {
      content: '\A';
      position: absolute;
      width: 2.05556rem;
      height: 2.05556rem;
      left: -1.66667rem;
      top: 0px;
      transform: rotate(-90deg);
      background-image: url("../images/icons/corner-red.svg");
      background-size: contain;
      background-repeat: no-repeat; }
    .quote-section-text-quote::after {
      content: '\A';
      position: absolute;
      width: 2.05556rem;
      height: 2.05556rem;
      right: -1.66667rem;
      bottom: 0px;
      transform: rotate(90deg);
      background-image: url("../images/icons/corner-red.svg");
      background-size: contain;
      background-repeat: no-repeat; }
  .quote-section-text-author {
    color: #ce003c;
    font-size: 1.66667rem;
    line-height: 2rem; }
  .quote-section-image {
    height: 15.11111rem;
    width: 15.11111rem;
    margin-top: -4.44444rem;
    border-radius: 1.38889rem;
    box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.18);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 991px) {
      .quote-section-image {
        margin-top: -13rem;
        margin-bottom: 2.22222rem; } }

.hero-media-center-details {
  overflow-x: hidden; }
  @media only screen and (max-width: 991px) {
    .hero-media-center-details {
      overflow-x: visible; } }
  .hero-media-center-details-title {
    font-size: 1.66667rem;
    margin-top: 90px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 991px) {
      .hero-media-center-details-title {
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 24px; } }
  .hero-media-center-details-images {
    position: relative; }
    .hero-media-center-details-images-current, .hero-media-center-details-images-next {
      width: 932px;
      height: 615px;
      border-radius: 30px;
      background-size: cover;
      background-position: center center;
      text-decoration: none; }
      @media only screen and (max-width: 991px) {
        .hero-media-center-details-images-current, .hero-media-center-details-images-next {
          width: 100%;
          height: 61vw;
          border-radius: 15px; } }
    .hero-media-center-details-images-next {
      position: absolute;
      top: 0;
      left: 1008px;
      color: transparent; }
      .hero-media-center-details-images-next:hover, .hero-media-center-details-images-next:active, .hero-media-center-details-images-next:focus, .hero-media-center-details-images-next:visited {
        color: transparent; }
      .hero-media-center-details-images-next::after {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.8; }
  .hero-media-center-details-navigation-controls {
    display: flex;
    justify-content: space-between; }
  .hero-media-center-details-nextarticle {
    display: block;
    text-align: right;
    font-size: 1.66667rem;
    color: #ce003c;
    letter-spacing: 0;
    margin-top: 61px;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      .hero-media-center-details-nextarticle {
        font-size: 1.1rem;
        margin-top: 15px; }
        .hero-media-center-details-nextarticle-icon {
          width: 20px;
          height: 20px;
          position: relative;
          top: -4px; } }
    .hero-media-center-details-nextarticle:hover, .hero-media-center-details-nextarticle:active, .hero-media-center-details-nextarticle:visited, .hero-media-center-details-nextarticle:focus {
      color: #ce003c; }
    .hero-media-center-details-nextarticle-icon {
      transform: translateY(8px); }
  .hero-media-center-details-goback {
    padding-top: 5px; }

.media-center-details {
  margin-top: 30px;
  margin-bottom: 101px; }
  @media only screen and (max-width: 991px) {
    .media-center-details {
      margin-top: 30px;
      margin-bottom: 34px; } }
  .media-center-details-date {
    display: block;
    font-size: 1rem;
    line-height: 1.66667rem;
    color: #768693;
    margin-bottom: 19px;
    font-weight: 500; }
    @media only screen and (max-width: 991px) {
      .media-center-details-date {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 10px; } }
  .media-center-details-title {
    font-size: 2.33333rem;
    font-weight: 300;
    margin-bottom: 28px; }
    @media only screen and (max-width: 991px) {
      .media-center-details-title {
        font-size: 23px;
        margin-bottom: 10px; } }
  @media only screen and (max-width: 991px) {
    .media-center-details-description {
      font-size: 13px;
      line-height: 20px; } }

.media-center-images {
  margin-bottom: 138px; }
  @media only screen and (max-width: 991px) {
    .media-center-images {
      margin-bottom: 39px; } }
  @media (min-width: 992px) {
    .media-center-images .container {
      max-width: 1010px; } }
  @media (min-width: 1200px) {
    .media-center-images .container {
      max-width: 1190px; } }
  @media only screen and (max-width: 991px) {
    .media-center-images .container {
      padding: 0;
      padding-left: 15px; } }
  .media-center-images-image {
    height: 167px;
    background-size: cover;
    background-position: center center;
    border-radius: 15px; }
    @media only screen and (max-width: 991px) {
      .media-center-images-image {
        border-radius: 8px;
        height: 27vw;
        width: calc(100% - 24px) !important; } }
  .media-center-images-button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: block;
    padding: 0 25px; }
    .media-center-images-button > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .media-center-images-button {
        padding: 0;
        position: relative; }
        .media-center-images-button::after {
          content: '\A';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: 0.8;
          transition: opacity 350ms; } }

@media only screen and (max-width: 991px) and (max-width: 991px) {
  .media-center-images-slick .slick-slide.slick-active .media-center-images-button::after {
    opacity: 0; } }
  @media only screen and (max-width: 991px) {
    .media-center-images-slick .slick-slide.slick-active + .slick-slide .media-center-images-button {
      transform: translateX(-18px); }
    .media-center-images-slick .slick-slide.slick-active + .slick-slide + .slick-slide .media-center-images-button {
      transform: translateX(-36px); } }
  .media-center-images-slick .slick-arrow {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: transparent;
    width: 34px;
    height: 34px;
    background-image: url("../images/icons/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    bottom: -63px;
    overflow: hidden; }
    .media-center-images-slick .slick-arrow > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .media-center-images-slick .slick-arrow {
        display: none !important; } }
    .media-center-images-slick .slick-arrow.slick-prev {
      left: calc(50% - 100px);
      transform: rotate(180deg); }
    .media-center-images-slick .slick-arrow.slick-next {
      right: calc(50% - 100px); }
  .media-center-images-slick .slick-dots {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    bottom: -53px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px; }
    @media only screen and (max-width: 991px) {
      .media-center-images-slick .slick-dots {
        display: none !important; } }
    .media-center-images-slick .slick-dots li {
      display: inline-block;
      height: 15px;
      margin-right: 6px; }
      .media-center-images-slick .slick-dots li:last-child {
        margin-right: 0; }
      .media-center-images-slick .slick-dots li.slick-active button {
        pointer-events: none;
        opacity: 1; }
      .media-center-images-slick .slick-dots li button {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        color: transparent;
        width: 15px;
        height: 15px;
        background-color: #ce003c;
        border-radius: 50%;
        opacity: 0.44;
        transition: opacity 350ms;
        overflow: hidden; }
        .media-center-images-slick .slick-dots li button > span {
          position: relative; }

.images-carousel {
  position: relative;
  width: auto;
  max-width: 994px;
  margin: 20px auto; }
  .images-carousel-slick-elem {
    height: 60vh;
    background-size: cover;
    background-position: center center; }
    @media only screen and (max-width: 991px) {
      .images-carousel-slick-elem {
        height: 57vw;
        border-radius: 15px;
        width: calc(100% - 8vw) !important;
        transform: translateX(0); }
        .images-carousel-slick-elem::after {
          content: '\A';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: 0.8;
          transition: opacity 350ms; } }
  @media only screen and (max-width: 991px) {
    .images-carousel-slick .slick-slide.slick-current.slick-active .images-carousel-slick-elem::after {
      opacity: 0; } }
  @media only screen and (max-width: 991px) {
    .images-carousel-slick .slick-slide.slick-current.slick-active + .slick-slide .images-carousel-slick-elem {
      transform: translateX(-4vw); } }
  .images-carousel-slick .slick-list {
    border-radius: 30px; }
    @media only screen and (max-width: 991px) {
      .images-carousel-slick .slick-list {
        border-radius: 0; } }
  .images-carousel-slick .slick-arrow {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: transparent;
    width: 68px;
    height: 68px;
    background-image: url("../images/icons/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden; }
    .images-carousel-slick .slick-arrow > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .images-carousel-slick .slick-arrow {
        display: none !important; } }
    .images-carousel-slick .slick-arrow.slick-prev {
      left: -80px;
      transform: translateY(-50%) rotate(180deg); }
    .images-carousel-slick .slick-arrow.slick-next {
      right: -80px; }
  .images-carousel-slick .slick-dots {
    position: absolute;
    left: 0;
    bottom: calc(-15vh - 20px);
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .images-carousel-slick .slick-dots {
        display: block;
        text-align: center;
        bottom: -9.5vw; } }
    .images-carousel-slick .slick-dots li {
      flex-grow: 1;
      margin-right: 30px; }
      @media only screen and (max-width: 991px) {
        .images-carousel-slick .slick-dots li {
          display: inline-block;
          margin-right: 1vw; } }
      .images-carousel-slick .slick-dots li:last-child {
        margin-right: 0; }
      .images-carousel-slick .slick-dots li.slick-active button {
        pointer-events: none; }
        @media only screen and (max-width: 991px) {
          .images-carousel-slick .slick-dots li.slick-active button {
            opacity: 1; } }
        .images-carousel-slick .slick-dots li.slick-active button::after {
          opacity: 0; }
    .images-carousel-slick .slick-dots button {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      color: transparent;
      width: 100%;
      height: 15vh;
      border-radius: 15px;
      background-size: cover;
      background-position: center center;
      position: relative; }
      .images-carousel-slick .slick-dots button > span {
        position: relative; }
      @media only screen and (max-width: 991px) {
        .images-carousel-slick .slick-dots button {
          width: 2.4vw;
          height: 2.4vw;
          background-color: #ce003c;
          background-image: none !important;
          border-radius: 50%;
          opacity: 0.44;
          transition: opacity 350ms;
          overflow: hidden; } }
      .images-carousel-slick .slick-dots button::after {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.8;
        transition: opacity 350ms; }
        @media only screen and (max-width: 991px) {
          .images-carousel-slick .slick-dots button::after {
            content: none; } }
  .images-carousel .mfp-close {
    display: none; }
  .images-carousel-close {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: -10vh;
    right: -87px; }
    .images-carousel-close > span {
      position: relative; }
    @media only screen and (max-width: 991px) {
      .images-carousel-close {
        position: fixed;
        top: auto;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: solid 1px #3b3b3b; }
        .images-carousel-close-icon {
          width: 16px;
          height: 16px; } }
    @media (max-height: 450px) {
      .images-carousel-close {
        position: absolute;
        bottom: -80px; } }

@media (max-width: 991px) {
  html.images-carousel-open .mfp-container {
    padding-left: 15px;
    padding-right: 0; } }

.store-locator {
  height: calc(100vh - 116px);
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .store-locator {
      height: 75vh; } }
  .store-locator-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px; }
  .store-locator-zoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .store-locator-zoom-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center; }
      @media only screen and (max-width: 991px) {
        .store-locator-zoom-container {
          flex-direction: row;
          align-items: flex-end; } }
    .store-locator-zoom-in, .store-locator-zoom-out {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      width: 46px;
      height: 46px;
      background-color: #000;
      border-radius: 8px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.5);
      pointer-events: auto; }
      .store-locator-zoom-in > span, .store-locator-zoom-out > span {
        position: relative; }
      @media only screen and (max-width: 991px) {
        .store-locator-zoom-in, .store-locator-zoom-out {
          width: 38px;
          height: 38px;
          box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.5);
          border-radius: 7px;
          margin-bottom: 29px; } }
      .store-locator-zoom-in-icon, .store-locator-zoom-out-icon {
        color: #fff;
        position: relative;
        top: 4px; }
        @media only screen and (max-width: 991px) {
          .store-locator-zoom-in-icon, .store-locator-zoom-out-icon {
            width: 19px;
            height: 19px; } }
    .store-locator-zoom-in-icon {
      left: 1px; }
      @media only screen and (max-width: 991px) {
        .store-locator-zoom-in-icon {
          left: 0; } }
    .store-locator-zoom-out {
      margin-bottom: 17px; }
      @media only screen and (max-width: 991px) {
        .store-locator-zoom-out {
          margin-bottom: 29px;
          margin-right: 19px; } }
  .store-locator-filters {
    background-color: #fff;
    box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.22);
    position: absolute;
    top: 0;
    right: 0;
    width: 408px;
    height: 100%;
    transform: translateX(100%);
    transition: transform 350ms; }
    @media only screen and (max-width: 767px) {
      .store-locator-filters {
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.22);
        width: 66vw; } }
    .store-locator-filters.active {
      transform: translateX(0); }
      .store-locator-filters.active .store-locator-filters-togglebutton-icon {
        color: #ce003c; }
    .store-locator-filters-scrollable {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: 100%;
      padding: 0; }
    .store-locator-filters-form {
      padding-top: 94px; }
      @media only screen and (max-width: 767px) {
        .store-locator-filters-form {
          padding-top: 36px; } }
    .store-locator-filters-togglebutton {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      width: 73px;
      height: 71px;
      background-color: #fff;
      box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.22);
      position: absolute;
      top: 83px;
      left: -73px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
      .store-locator-filters-togglebutton > span {
        position: relative; }
      @media only screen and (max-width: 767px) {
        .store-locator-filters-togglebutton {
          width: 50px;
          height: 46px;
          box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.26);
          top: 32px;
          left: -50px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px; } }
      .store-locator-filters-togglebutton::after {
        content: '\A';
        display: inline-block;
        background-color: #fff;
        position: absolute;
        top: -20px;
        right: -20px;
        width: 20px;
        height: calc(100% + 40px);
        pointer-events: none; }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-togglebutton::after {
            top: -15px;
            right: -15px;
            width: 15px;
            height: calc(100% + 30px); } }
      .store-locator-filters-togglebutton-icon {
        position: relative;
        top: 3px;
        color: #000;
        transition: color 350ms; }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-togglebutton-icon {
            width: 19px;
            height: 18px; } }
    .store-locator-filters-filter {
      color: #000;
      font-weight: 300;
      font-size: 1rem;
      transition: box-shadow 350ms;
      padding: 0 28px;
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .store-locator-filters-filter {
          padding: 0 16px;
          margin-bottom: 16px; } }
      .store-locator-filters-filter:last-child {
        margin-bottom: 0; }
      .store-locator-filters-filter.active {
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1); }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-filter.active {
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1); } }
        .store-locator-filters-filter.active .store-locator-filters-filter-header {
          background-color: #fff;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; }
        .store-locator-filters-filter.active .store-locator-filters-filter-container {
          max-height: 500px; }
          @media only screen and (max-width: 767px) {
            .store-locator-filters-filter.active .store-locator-filters-filter-container {
              max-height: 250px; } }
        .store-locator-filters-filter.active .store-locator-filters-filter-checkbox {
          opacity: 1; }
      .store-locator-filters-filter-header {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
        background-color: #E8EBED;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        height: 61px;
        padding: 0 33px;
        border: 1px solid #E8EBED;
        text-align: left;
        transition: background-color 350ms, border-bottom-left-radius 350ms, border-bottom-right-radius 350ms; }
        .store-locator-filters-filter-header > span {
          position: relative; }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-filter-header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            height: 46px;
            padding: 0 14px; } }
        .store-locator-filters-filter-header > span {
          display: flex;
          align-items: center; }
      .store-locator-filters-filter-title {
        font-weight: 500;
        font-size: 1rem;
        margin: 0;
        flex-basis: 35%; }
      .store-locator-filters-filter-value {
        font-weight: 300;
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 10px; }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-filter-value {
            margin-right: 5px; } }
      .store-locator-filters-filter-container {
        border-left: 1px solid #E8EBED;
        border-right: 1px solid #E8EBED;
        border-bottom: 1px solid #E8EBED;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        overflow: hidden;
        max-height: 0;
        transition: max-height 350ms; }
        @media only screen and (max-width: 767px) {
          .store-locator-filters-filter-container {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px; } }
        .store-locator-filters-filter-container-gutter {
          padding: 39px 34px; }
          @media only screen and (max-width: 767px) {
            .store-locator-filters-filter-container-gutter {
              padding: 20px 17px; } }
      .store-locator-filters-filter-checkbox {
        opacity: 0;
        transition: opacity 350ms; }
        .store-locator-filters-filter-checkbox-input {
          display: none; }
          .store-locator-filters-filter-checkbox-input:checked + .store-locator-filters-filter-checkbox-label {
            font-weight: 500; }
            .store-locator-filters-filter-checkbox-input:checked + .store-locator-filters-filter-checkbox-label .store-locator-filters-filter-checkbox-label-icon {
              color: #ce003c; }
        .store-locator-filters-filter-checkbox-label {
          display: flex;
          line-height: 1;
          margin-bottom: 21px;
          cursor: pointer;
          font-weight: 300; }
          @media only screen and (max-width: 767px) {
            .store-locator-filters-filter-checkbox-label {
              margin-bottom: 11px; } }
          .store-locator-filters-filter-checkbox-label-text {
            width: 80%; }
          .store-locator-filters-filter-checkbox-label-icon {
            color: #768693;
            transition: color 350ms;
            margin-right: 14px;
            position: relative;
            top: 4px;
            width: 25px;
            height: 22px; }
            @media only screen and (max-width: 767px) {
              .store-locator-filters-filter-checkbox-label-icon {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                top: 2px; } }
        .store-locator-filters-filter-checkbox:last-child .store-locator-filters-filter-checkbox-label {
          margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .store-locator-filters-filter-caret {
          width: 15px;
          height: 8px; } }
    .store-locator-filters-buttons {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 25px 28px; }
      @media only screen and (max-width: 767px) {
        .store-locator-filters-buttons {
          padding: 16px; } }
    .store-locator-filters-filterbutton {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      display: inline-block;
      color: #fff;
      background-color: #ce003c;
      text-align: center;
      width: 162px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-weight: 500;
      font-size: 0.72222rem;
      text-transform: uppercase; }
      .store-locator-filters-filterbutton > span {
        position: relative; }
    .store-locator-filters-resetbutton {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      display: inline-block;
      color: #ce003c;
      text-align: center;
      width: 162px;
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      font-size: 0.72222rem;
      text-transform: uppercase; }
      .store-locator-filters-resetbutton > span {
        position: relative; }
  .store-locator-infowindow {
    position: relative; }
    .store-locator-infowindow-container {
      background-color: #fff;
      color: #000;
      border-radius: 30px;
      box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.17);
      width: 100%;
      height: auto;
      padding-bottom: 27px; }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-container {
          border-radius: 15px;
          box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.17);
          width: 221px;
          padding-bottom: 20px; } }
    .store-locator-infowindow-image {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      width: 100%;
      height: 187px;
      background-size: cover;
      background-position: center center;
      color: transparent;
      position: relative; }
      .store-locator-infowindow-image > span {
        position: relative; }
      .store-locator-infowindow-image:hover::before {
        opacity: 1; }
      .store-locator-infowindow-image::before {
        content: '\A';
        background-color: rgba(0, 0, 0, 0.7);
        background-image: url("../images/icons/google-street-view.svg");
        background-repeat: no-repeat;
        background-size: 100px 100px;
        background-position-x: 20%;
        background-position-y: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 350ms; }
      @media (max-height: 768px) {
        .store-locator-infowindow-image {
          height: 120px; } }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-image {
          height: 101px; } }
      .store-locator-infowindow-image.disabled {
        pointer-events: none; }
        .store-locator-infowindow-image.disabled > span {
          display: none; }
    .store-locator-infowindow-close {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 37px;
      height: 37px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 5px; }
      .store-locator-infowindow-close > span {
        position: relative; }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-close {
          top: 10px;
          right: 10px;
          width: 23px;
          height: 23px;
          border-radius: 3px; } }
      .store-locator-infowindow-close-icon {
        position: relative;
        top: 2px; }
        @media only screen and (max-width: 767px) {
          .store-locator-infowindow-close-icon {
            width: 8px;
            height: 8px;
            top: 0;
            left: 1px; } }
    .store-locator-infowindow-info {
      font-weight: 500;
      font-size: 1rem;
      padding-left: 30px;
      position: relative;
      margin-bottom: 24px; }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-info {
          padding-left: 18px;
          margin-bottom: 12px; } }
      .store-locator-infowindow-info-container {
        list-style: none;
        padding: 27px 27px 0 27px;
        margin: 0; }
        @media only screen and (max-width: 767px) {
          .store-locator-infowindow-info-container {
            padding: 20px 20px 0 20px; } }
      .store-locator-infowindow-info-locationicon, .store-locator-infowindow-info-phoneicon, .store-locator-infowindow-info-hoursicon {
        position: absolute;
        top: 0;
        left: 0; }
      .store-locator-infowindow-info-locationicon {
        top: -2px; }
        @media only screen and (max-width: 767px) {
          .store-locator-infowindow-info-locationicon {
            top: 0;
            width: 12px;
            height: 15px; } }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-info-phoneicon {
          width: 10px;
          height: 10px;
          top: 1px; } }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-info-hoursicon {
          width: 12px;
          height: 12px;
          top: 1px; } }
    .store-locator-infowindow-getdirection {
      display: inline-block;
      color: #fff;
      background-color: #ce003c;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-weight: 500;
      font-size: 0.72222rem;
      text-transform: uppercase;
      padding: 0 15px;
      margin-left: 27px;
      margin-top: 6px;
      text-decoration: none; }
      @media only screen and (max-width: 767px) {
        .store-locator-infowindow-getdirection {
          margin-top: 5px;
          margin-left: 20px; } }
  .store-locator .gm-style-iw {
    padding: 0 !important;
    max-width: 369px;
    max-height: 420px;
    overflow: visible !important; }
    .store-locator .gm-style-iw-c {
      border-radius: 30px; }
    .store-locator .gm-style-iw div {
      overflow: visible !important; }
      .store-locator .gm-style-iw div .store-locator-infowindow-container {
        overflow: hidden !important; }
    .store-locator .gm-style-iw + div {
      display: none; }
    .store-locator .gm-style-iw button.gm-ui-hover-effect {
      display: none !important; }
  .store-locator-results {
    opacity: 0;
    transition: opacity 350ms;
    padding: 0 28px; }
    @media only screen and (max-width: 767px) {
      .store-locator-results {
        padding: 0 16px; } }
    .store-locator-results-list {
      list-style: none;
      padding: 0;
      margin: 0; }
    .store-locator-results-title {
      color: #000;
      font-size: 1.66667rem;
      margin-top: 28px;
      margin-bottom: 28px; }
      @media only screen and (max-width: 767px) {
        .store-locator-results-title {
          margin-top: 14px;
          margin-bottom: 20px; } }
    .store-locator-results.active {
      opacity: 1; }
  .store-locator-result-button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 100%;
    display: block;
    line-height: 1.55556rem;
    padding: 28px 32px;
    border: 1px solid #E8EBED;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    margin-bottom: 28px;
    text-align: left; }
    .store-locator-result-button > span {
      position: relative; }
    @media only screen and (max-width: 767px) {
      .store-locator-result-button {
        padding: 14px 16px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 20px; } }
  .store-locator-streetview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    z-index: 11; }
    @media only screen and (max-width: 767px) {
      .store-locator-streetview {
        align-items: flex-start; } }
    .store-locator-streetview.active {
      pointer-events: auto;
      opacity: 1; }
    .store-locator-streetview-overlay {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      background-color: #000;
      opacity: .7;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent; }
      .store-locator-streetview-overlay > span {
        position: relative; }
    .store-locator-streetview-frame {
      width: 100%;
      height: 100%;
      border-radius: 30px; }
      @media only screen and (max-width: 991px) {
        .store-locator-streetview-frame {
          border-radius: 15px; } }
      .store-locator-streetview-frame-wrapper {
        width: calc(100% - 30px);
        max-width: 800px;
        height: 70vh;
        max-height: 600px;
        position: relative; }
        @media only screen and (max-width: 991px) {
          .store-locator-streetview-frame-wrapper {
            margin-top: 15px;
            height: 80vh;
            max-height: 700px; } }
        @media only screen and (max-width: 767px) {
          .store-locator-streetview-frame-wrapper {
            height: 70vh; } }
    .store-locator-streetview-close {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: -10vh;
      right: -87px; }
      .store-locator-streetview-close > span {
        position: relative; }
      @media only screen and (max-width: 991px) {
        .store-locator-streetview-close {
          position: fixed;
          top: auto;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          bottom: 40px;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: solid 1px #3b3b3b; }
          .store-locator-streetview-close-icon {
            width: 16px;
            height: 16px; } }
      @media (max-height: 450px) {
        .store-locator-streetview-close {
          position: absolute;
          bottom: -80px; } }

html.is-touch.is-device .store-locator-infowindow-image:hover::before {
  opacity: 0; }

.features-section {
  position: relative; }
  .features-section-background {
    position: absolute;
    top: 30%;
    right: 0px;
    bottom: 0;
    left: 30%;
    z-index: -1;
    background-position: left top, center;
    background-size: 200%, cover;
    background-repeat: no-repeat;
    opacity: 0.32; }
    @media only screen and (max-width: 991px) {
      .features-section-background {
        left: 0px; } }
  .features-section-item {
    padding-top: 2.22222rem;
    padding-bottom: 2.22222rem; }
    .features-section-item-icon {
      width: 2.77778rem;
      height: 2.77778rem;
      background-repeat: no-repeat;
      background-position: left center; }
    .features-section-item-name {
      text-transform: uppercase;
      font-size: 1.33333rem;
      text-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.68);
      margin-top: 1.11111rem;
      margin-bottom: 1.38889rem; }
    .features-section-item-description {
      color: #bbbbbb;
      font-size: 1rem;
      line-height: 1.66667rem; }

.features-section-simple {
  padding-top: 20px;
  padding-bottom: 98px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(118, 134, 147, 0.13) 80%, rgba(0, 0, 0, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(118, 134, 147, 0.13) 80%, rgba(0, 0, 0, 0)); }
  @media only screen and (max-width: 767px) {
    .features-section-simple {
      padding-top: 0;
      padding-bottom: 29px; } }
  .features-section-simple-title {
    font-size: 1.66667rem;
    margin-bottom: 45px; }
    @media only screen and (max-width: 767px) {
      .features-section-simple-title {
        font-size: 16px;
        margin-bottom: 20px; } }
  .features-section-simple-feature {
    border: solid 1px #ce003c;
    border-radius: 30px;
    text-align: center;
    padding: 10px;
    padding-top: 66px;
    height: 100%; }
    @media only screen and (max-width: 767px) {
      .features-section-simple-feature {
        border-radius: 15px;
        padding-top: 32px; } }
    .features-section-simple-feature-title {
      text-align: left;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1.33333rem;
      line-height: 2rem;
      padding-left: 24px;
      margin-top: 46px;
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .features-section-simple-feature-title {
          font-size: 16px;
          padding-left: 10px;
          margin-top: 20px;
          margin-bottom: 20px; } }
      @media only screen and (max-width: 400px) {
        .features-section-simple-feature-title {
          padding-left: 0px; } }
      @media (max-width: 320px) {
        .features-section-simple-feature-title {
          font-size: 13px;
          line-height: 1.5; } }
    @media only screen and (max-width: 767px) {
      .features-section-simple-feature-icon {
        width: 50px;
        height: 50px; } }
    @media only screen and (max-width: 991px) {
      .features-section-simple-feature-col {
        max-width: 25%;
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .features-section-simple-feature-col {
        max-width: 33.33333%;
        margin-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px; } }

.brands-section {
  position: relative; }
  .brands-section::before {
    content: '\A';
    display: inline-block;
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50%; }
  .brands-section-content {
    position: relative;
    background-color: #fff;
    color: #19262f;
    padding-top: 89px;
    padding-bottom: 50px;
    padding-right: 55px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; }
    @media only screen and (max-width: 991px) {
      .brands-section-content {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-right: 60px;
        margin-right: 17px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; } }
  @media only screen and (max-width: 991px) {
    .brands-section-title {
      font-size: 16px; } }
  @media only screen and (max-width: 991px) {
    .brands-section-description {
      font-size: 1.22222rem;
      letter-spacing: 0;
      line-height: 1.77778rem;
      margin-bottom: 28px; } }
  .brands-section-image {
    max-width: 100%;
    max-height: 115px; }
    .brands-section-image title {
      display: none !important; }
    @media only screen and (max-width: 991px) {
      .brands-section-image {
        max-height: 47px; } }
    .brands-section-image-wrapper {
      min-height: 115px;
      line-height: 115px; }
      @media only screen and (max-width: 991px) {
        .brands-section-image-wrapper {
          min-height: 47px;
          line-height: 47px; } }
    .brands-section-image-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
  .brands-section-corner {
    color: #ce003c;
    position: absolute;
    top: 35px;
    right: 31px; }
    @media only screen and (max-width: 991px) {
      .brands-section-corner {
        top: 18px;
        right: 15px; }
        .brands-section-corner svg {
          width: 35px;
          height: 34px; } }

.promotion-card-image {
  height: 439px;
  background-color: white;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px;
  border: solid 1px #292929; }
  @media only screen and (max-width: 991px) {
    .promotion-card-image {
      height: 47vw;
      border-radius: 15px; } }

.promotion-card-title {
  font-weight: 300;
  font-size: 1.66667rem;
  line-height: 2.33333rem;
  letter-spacing: 0;
  margin-bottom: 11px; }
  @media only screen and (max-width: 991px) {
    .promotion-card-title {
      font-size: 18px;
      line-height: 30px;
      margin-top: 25px;
      margin-bottom: 4px; } }

.promotion-card-description {
  font-weight: 300;
  margin-bottom: 23px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 60px; }
  @media only screen and (max-width: 1199px) {
    .promotion-card-description {
      height: 52px; } }
  @media only screen and (max-width: 991px) {
    .promotion-card-description {
      color: #768693;
      margin-bottom: 18px;
      height: 40px;
      font-size: 1.22222rem;
      letter-spacing: 0;
      line-height: 1.77778rem; } }

.promotion-card-link {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  background-color: #ce003c;
  text-align: center;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  font-weight: 500;
  font-size: 0.88889rem;
  text-transform: uppercase;
  padding: 0 30px;
  text-decoration: none; }
  .promotion-card-link > span {
    position: relative; }
  @media only screen and (max-width: 991px) {
    .promotion-card-link {
      height: 30px;
      line-height: 30px;
      border-radius: 15px; } }

.promotion-card-terms {
  font-weight: 300;
  font-size: 0.88889rem;
  line-height: 1;
  color: #768693;
  position: absolute;
  bottom: 30px;
  left: 0; }

.promotion-card-content-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center; }

.promotions-list-section .promotion-card {
  margin-bottom: 3.33333rem; }

.promotion-details-section h1 {
  font-size: 2.33333rem; }

.promotion-details-section-description {
  margin-bottom: 6.66667rem; }
  @media only screen and (max-width: 991px) {
    .promotion-details-section-description {
      margin-bottom: 1.94444rem; } }

.promotion-details-section h2 {
  margin-top: 5.55556rem;
  margin-bottom: 1.94444rem;
  color: #ce003c;
  font-size: 1.66667rem;
  font-weight: 500; }
  @media only screen and (max-width: 991px) {
    .promotion-details-section h2 {
      margin-top: 1.94444rem; } }

.terms-and-conditions {
  color: #768693;
  font-weight: 300; }
  .terms-and-conditions h6 {
    margin-top: 1.38889rem; }
  .terms-and-conditions ul {
    padding: 0; }
  .terms-and-conditions li {
    line-height: 1.66667rem;
    margin-bottom: 0.55556rem;
    list-style: none;
    display: flex; }
    .terms-and-conditions li:before {
      content: '- ';
      margin-right: 0.27778rem; }

.skip-to-content {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  text-decoration: none; }
  .skip-to-content:focus {
    top: 0;
    left: 0;
    z-index: 1009;
    width: auto;
    height: auto;
    clip: auto;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    background-color: #000;
    padding: 10px 20px; }

.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-top: 25px; }
  .pagination li {
    padding: 0 10px; }
    .pagination li a {
      text-decoration: none; }
    .pagination li.active {
      border-bottom: 2px solid #ce003c; }
      .pagination li.active a {
        color: #ce003c; }

.instagram-stories-section {
  margin-bottom: -30px;
  margin-top: 15px; }
  .instagram-stories-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .instagram-stories-section-header-title {
      display: flex;
      align-items: center; }
      .instagram-stories-section-header-title-icon {
        width: 3rem;
        height: 2.6rem;
        margin-bottom: 25px;
        margin-left: 5px; }
        @media only screen and (max-width: 767px) {
          .instagram-stories-section-header-title-icon {
            margin-bottom: 12px; } }
        .instagram-stories-section-header-title-icon svg {
          width: 100%;
          height: 100%; }
    @media only screen and (max-width: 767px) {
      .instagram-stories-section-header-buttons {
        display: none; } }
    .instagram-stories-section-header-buttons-arrow {
      width: 20px;
      height: 40px;
      cursor: pointer; }
      .instagram-stories-section-header-buttons-arrow-rotate {
        transform: rotate(180deg);
        margin-right: 35px; }
  .instagram-stories-section .title-with-image {
    margin-bottom: 1.11111rem; }
  .instagram-stories-section .instagram-story-wrapper {
    position: relative;
    overflow: visible; }
    .instagram-stories-section .instagram-story-wrapper:before {
      content: '';
      display: block;
      padding-top: calc(100% + 50px); }
  .instagram-stories-section .instagram-stories:not(.slick-initialized) .instagram-story-wrapper:nth-child(n+5) {
    display: none; }
  .instagram-stories-section .instagram-stories:not(.slick-initialized) .instagram-story-wrapper {
    width: 24%;
    display: inline-block; }
  .instagram-stories-section .instagram-stories {
    margin: 0 -1.11111rem; }
  .instagram-stories-section .instagram-story {
    position: absolute;
    top: 1.11111rem;
    right: 1.11111rem;
    bottom: 5.55556rem;
    left: 1.11111rem;
    border-top-right-radius: 0.44444rem;
    border-top-left-radius: 0.44444rem;
    background-repeat: no-repeat;
    background-size: cover; }
    .instagram-stories-section .instagram-story a {
      display: block;
      width: 100%;
      height: 100%;
      color: white; }
    .instagram-stories-section .instagram-story svg {
      position: absolute;
      right: 0.83333rem;
      bottom: 0.83333rem;
      width: 1.16667rem;
      height: 1.16667rem; }
    .instagram-stories-section .instagram-story svg.instagram-story-play {
      top: 50%;
      left: 50%;
      right: auto;
      width: 2.77778rem;
      height: 2.77778rem;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7)); }
    .instagram-stories-section .instagram-story-footer {
      height: 2.77778rem;
      background-color: #28282a;
      border-top: #ce003c 0.33333rem solid;
      border-bottom-right-radius: 0.44444rem;
      border-bottom-left-radius: 0.44444rem;
      position: relative;
      text-align: right;
      padding: 10px 0; }
      .instagram-stories-section .instagram-story-footer svg {
        position: static;
        margin-right: 5px;
        width: 30%; }

.sfPageEditor .fade {
  opacity: initial; }

.sfPageEditor .cards-container-backgroundimage {
  position: initial; }
