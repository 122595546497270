html, body {
    position: relative;
    width: 100%;
    height: 100%;

    background-color: $color_black !important;
}

body {
    min-width: $app-min-width;
    @include text-align(left);

    display: flex;
    flex-direction: column;

    & > * {
        flex-shrink: 0;
    }

    & > main {
        background-color: $color_black !important;
    }
}

.block-scroll {
    overflow: hidden;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.dnone {
    display: none;
}

.inner-page-content {
    position: relative;
    z-index: 1;
}
header.bordered ~ #main .inner-page-content {
    margin-top: 0px;
}

.vertical-ellipsis {
    position: relative;

    & > * {
        overflow: hidden;
        transition: max-height $transition_duration;
    }

    &:after {
        position: absolute;
        top: 100%;
        @include left(0);
        opacity: 0;
        visibility: hidden;
        text-shadow: 0px 0px 6px rgba(0, 0, 0, 1);
        color: $color_white;
        cursor: pointer;
        content: ' Read more';

        @include rtl {
            content: ' اقرأ أكثر ';
        }

        transition: visibility $transition_duration, opacity $transition_duration;
    }

    &-overflow:after {
        visibility: visible;
        opacity: 1;
    }
}

*:focus {
    outline: none !important;
}

.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    /* IE 6-9 */
    filter: gray;
    /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
    -webkit-filter: grayscale(100%);
}