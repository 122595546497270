.card-article {
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;

    @include respond-to(tablet-max) {
        padding-top: 30vw;
    }

    @include respond-to(phone-max) {
        padding-top: 47vw;
    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_black;
        opacity: .6;
        z-index: 0;
    }

    &-content {
        z-index: 1;
        position: absolute;
        @include left(0);
        bottom: 0;
        @include margin-left(33px);
        margin-bottom: 30px;

        @include respond-to(tablet-max) {
            @include margin-left(28px);
            margin-bottom: 20px;
        }
    }

    &-date {
        font-weight: 300;
        margin-bottom: 3px;

        @include respond-to(phone-max) {
            font-size: 11px;
            line-height: 15px;
            margin-bottom: 5px;
        }
    }

    &-title {
        margin: 0;
        color: $color_white;
        font-size: pxToRem(30px);
        margin-bottom: 13px;

        @include respond-to(phone-max) {
            font-size: 16px;
            margin-bottom: 9px;
        }
    }

    &-link {
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }

    &-typeicon {
        display: flex;
        width: 46px;
        height: 46px;
        background-color: rgba($color_black, .7);
        border-radius: 6.4px;
        position: absolute;
        top: 22px;
        @include right(23px);
        justify-content: center;
        align-items: center;

        @include respond-to(phone-max) {
            width: 28px;
            height: 28px;
            border-radius: 3px;
            top: 14px;
            @include right(14px);
        }

        svg {
            @include respond-to(phone-max) {
                width: 15px;
                height: 14px;
            }
        }
    }
}
