.pagination {    
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    li {
        padding: 0 10px;
        a {
            text-decoration: none;
        }
        &.active {            
            border-bottom: 2px solid $color_red;
            a {                
                color: $color_red;
            }
        }
    }
}