.brands-section {
    position: relative;

    &::before {
        content: '\A';
        display: inline-block;
        background-color: $color_white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        @include left(-50%);
    }

    &-content {
        position: relative;
        background-color: $color_white;
        color: $color_gray;
        padding-top: 89px;
        padding-bottom: 50px;
        @include padding-right(55px);

        @include ltr {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }

        @include rtl {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        @include respond-to(tablet-max) {
            padding-top: 30px;
            padding-bottom: 20px;
            @include padding-right(60px);
            @include margin-right(17px);

            @include ltr {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            @include rtl {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
        }
    }

    &-title {
        @include respond-to(tablet-max) {
            font-size: 16px;
        }
    }

    &-description {
        @include respond-to(tablet-max) {
            font-size: pxToRem(22px);
            letter-spacing: 0;
            line-height: pxToRem(32px);
            margin-bottom: 28px;
        }
    }

    &-image {
        max-width: 100%;
        max-height: 115px;

        title {
            display: none !important;
        }

        @include respond-to(tablet-max) {
            max-height: 47px;
        }

        &-wrapper {
            min-height: 115px;
            line-height: 115px;

            @include respond-to(tablet-max) {
                min-height: 47px;
                line-height: 47px;
            }
        }

        &-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &-corner {
        color: $color_red;
        position: absolute;
        top: 35px;
        @include right(31px);

        @include rtl {
            transform: rotate(-90deg);
        }

        @include respond-to(tablet-max) {
            top: 18px;
            @include right(15px);

            svg {
                width: 35px;
                height: 34px;
            }
        }
    }
}
