.quote-section {
    position: relative;
    margin-top: pxToRem(55px);

    @include respond-to(tablet-max) {
        margin-top: pxToRem(200px);
    }

    .row {
        flex-direction: row;

        @include respond-to(tablet-max) {
            flex-direction: column;
        }
    }

    &-dummy-background {
        position: absolute;
        top: 0;
        @include right(50%);
        bottom: 0;
        @include left(0);
        z-index: 0;

        background: $color_white;
    }

    &-background {
        padding-top: pxToRem(40px);
        padding-bottom: pxToRem(50px);

        @include border-right-radius(pxToRem(30px));
        background: $color_white;

        @include rtl {
            @include border-left-radius(pxToRem(30px));
        }
    }

    &-text {
        @include padding-left(pxToRem(50px));
        @include padding-right(pxToRem(50px));
    }

    &-text-quote {
        position: relative;
        color: $color_black;
        font-size: pxToRem(42px);
        font-weight: 500;
        line-height: pxToRem(53px);

        padding: pxToRem(25px) 0;
        margin-bottom: pxToRem(10px);

        &::before {
            content: '\A';
            position: absolute;
            width: pxToRem(37px);
            height: pxToRem(37px);

            @include left(pxToRem(-30px));
            top: 0px;
            transform: rotate(-90deg);

            background-image: url('../images/icons/corner-red.svg');
            background-size: contain;
            background-repeat: no-repeat;

            @include rtl {
                transform: rotate(0deg);
            }
        }

        &::after {
            content: '\A';
            position: absolute;
            width: pxToRem(37px);
            height: pxToRem(37px);

            @include right(pxToRem(-30px));
            bottom: 0px;
            transform: rotate(90deg);

            background-image: url('../images/icons/corner-red.svg');
            background-size: contain;
            background-repeat: no-repeat;

            @include rtl {
                transform: rotate(180deg);
            }
        }
    }

    &-text-author {
        color: $color_red;
        font-size: pxToRem(30px);
        line-height: pxToRem(36px);
    }

    &-image {
        height: pxToRem(272px);
        width: pxToRem(272px);
        margin-top: pxToRem(-80px);

        border-radius: pxToRem(25px);
        box-shadow: 0 10px 27px 0 rgba(0,0,0,0.18);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;

        @include respond-to(tablet-max) {
            margin-top: pxToRem(-234px);
            margin-bottom: pxToRem(40px);
        }
    }
}
