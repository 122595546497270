.card-faq {
    height: 100%;
    position: relative;
    background-color: $color_black3;

    @include ltr {
        padding: 32px 57px 0 47px;
    }

    @include rtl {
        padding: 32px 47px 0 57px;
    }

    @include respond-to(phone-max) {
        padding: 29px 23px 0 23px;
    }

    &-corner {
        color: $color_red;
        position: absolute;
        top: 26px;
        @include right(21px);

        @include rtl {
            transform: rotate(-90deg);
        }

        @include respond-to(phone-max) {
            width: 18px;
            height: 17px;
            top: 15px;
            @include right(14px);
        }
    }

    &-question {
        font-weight: 500;
        font-size: pxToRem(30px);
        letter-spacing: 0;
        line-height: pxToRem(42px);
        margin-bottom: 15px;

        @include respond-to(phone-max) {
            margin-bottom: 10px;
        }
    }

    &-answer {
        color: $color_body_copy;
        margin: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: 200px;

        @include respond-to(tablet-max) {
            font-size: pxToRem(22px);
            letter-spacing: 0;
            line-height: pxToRem(32px);
            margin-bottom: 20px;
        }
    }
}
