.products-section {
    height: min-content;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: pxToRem(100px);

    @include respond-to(tablet-max) {
        background: $color_black !important;
    }

    .container {
        overflow: visible;

        @include respond-to(laptop-min) {
            position: relative;
        }
    }

    &-list {
        display: flex;
        flex-wrap: nowrap;

        @include respond-to(laptop-min) {
            padding: pxToRem(40px) pxToRem(50px) pxToRem(20px) pxToRem(50px);
            margin: 0 pxToRem(-50px) pxToRem(20px) pxToRem(-50px);
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        @include respond-to(tablet-max) {
            display: block;
        }
    }

    &-list-item {
        position: static;
        margin: pxToRem(20px) 0;

        @include respond-to(laptop-min) {
            @include padding-right(60px);
        }

        @include respond-to(tablet-max) {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }

        &-header {
            font-weight: 500;
            font-size: 25px;
            margin-bottom: pxToRem(10px);
        }

        &-image {
            width: pxToRem(141px);
            height: pxToRem(210px);
            border-radius: pxToRem(20px);
            border: pxToRem(7px) solid transparent;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            filter: brightness(70%);
            box-shadow: 0px 0px 80px -5px rgba(150,150,150,0.4);
            transition: border $transition_duration, box-shadow $transition_duration, filter $transition_duration;
            background-color: #fff;

            @include respond-to(laptop-min) {
                margin-bottom: pxToRem(25px);
            }

            @include respond-to(tablet-max) {
                filter: brightness(100%);
            }
        }

        &-name {
            font-size: pxToRem(16px);
            font-weight: 500;
            line-height: pxToRem(20px);
            text-transform: uppercase;
            color: $color_gray4;
            transition: color $transition_duration;

            @include respond-to(tablet-max) {
                color: $color_white;
                @include margin-left(25px);
            }
        }

        &-view-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: pxToRem(9px);
            text-transform: none;
            color: $color_red;
            font-size: pxToRem(16px);
            font-weight: 500;

            &-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                @include margin-left(pxToRem(5px));

                &, svg {
                    width: pxToRem(12px);
                    height: pxToRem(12px);
                }

                @include rtl {
                    transform: rotateZ(180deg);
                }
            }

            transition: opacity $transition_duration * 2;

            @include respond-to(laptop-min) {
                display: none;
            }
        }

        &.active {
            .products-section-list-item-image {
                border-color: $color_red;
                box-shadow: 0px 0px 80px -5px $color_red;
                filter: brightness(100%);
            }

            .products-section-list-item-name {
                color: $color_white;
            }

            .products-section-list-item-details {
                padding: pxToRem(45px);
                opacity: 1;
                transform: translateY(0);
                visibility: visible;

                @include respond-to(laptop-min) {
                    transition-delay: $transition_duration * 1.5;
                }

                @include respond-to(tablet-max) {
                    margin-top: pxToRem(40px);

                    & > .row {
                        max-height: 700px;
                        overflow: scroll;
                        -webkit-overflow-scrolling: touch;
                    }
                }
            }

            .products-section-list-item-view-details {
                opacity: 0;
            }
        }
    }

    &-item-details-desktop-wrapper {
        @include respond-to(tablet-max) {
            display: none;
        }
    }

    &-item-details-desktop {
        position: relative;
    }

    &-list-item-details {
        padding: pxToRem(45px);
        border-radius: 24px;
        opacity: 0;
        transform: translateY(-20px);
        visibility: hidden;
        background: $color_white;
        color: $color_gray;
        user-select: text;
        transition: opacity $transition_duration, transform $transition_duration, visibility $transition_duration;

        @include respond-to(laptop-min) {
            position: absolute;
            top: 0;
            width: 100%;

            &-mobile {
                display: none;
            }
        }

        @include respond-to(tablet-max) {
            position: relative;
            width: 100%;
            padding: 0 pxToRem(45px);
            top: auto;
            bottom: auto;
            right: auto;
            left: auto;
            transform: translateY(0);

            & > .row {
                max-height: 0;
                overflow: auto;
                transition: max-height $transition_duration $transition_duration;
            }

            transition: opacity $transition_duration $transition_duration, transform $transition_duration $transition_duration, padding $transition_duration $transition_duration, margin $transition_duration $transition_duration, visibility $transition_duration $transition_duration;
        }

        &-tip {
            position: absolute;
            top: 2px;
            @include left(pxToRem(50px));
            height: 30px;
            width: 30px;
            border-radius: 5px;
            background: $color_white;
            transform: translateY(-50%) rotateZ(45deg);
        }

        &-close {
            position: absolute;
            top: 1rem;
            padding:pxToRem(15px);
            @include right(1rem);

            svg {
                height: pxToRem(20px);
                width: pxToRem(20px);
                fill: $color_black;
                pointer-events: none;
            }

            @include respond-to(laptop-min) {
                display: none;
            }
        }

        &-property {
            position: relative;
            font-weight: 300;
            font-size: pxToRem(18px);
            line-height: pxToRem(23px);

            @include respond-to(tablet-max) {
                &-separator {
                    height: 1px;
                    background-color: rgba(151, 151, 151, 0.61);
                    margin-top: pxToRem(45px);
                }

                &:not(:last-child) {
                    margin-bottom: pxToRem(40px);
                }
            }

            h1, h2, h3, h4, h5, h6 {
                color: $color_gray;
                font-weight: 500;
                margin-bottom: pxToRem(30px);
                font-size: pxToRem(18px);
                line-height: pxToRem(23px);
            }

            ul {
                margin: 0;
                padding: 0;

                @include padding-left(1em);
            }

            &-item-simple {
                &:not(:last-child) {
                    margin-bottom: pxToRem(22px);
                }
            }

            &-item-withImage {
                display: flex;
                align-items: center;

                @include margin-left(-1em);

                &:not(:last-child) {
                    margin-bottom: pxToRem(40px);
                }

                & > img {
                    height: pxToRem(54px);
                    width: auto;
                    @include margin-right(pxToRem(20px));
                }
            }

            &:last-child &-separator {
                display: none;
            }
        }

        &.active {
            padding: pxToRem(45px);
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
            transition-delay: $transition_duration;

            .products-section-list-item-view-details {
                opacity: 0;
            }
        }
    }
}
