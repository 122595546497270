.card-link {
    @include ltr {
        background-position-x: 20vw;
    }

    @include rtl {
        background-position-x: -20vw;
    }

    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    color: $color_white;
    position: relative;

    @include respond-to(tablet-max) {
        @include ltr {
            background-position-x: 35vw;
        }

        @include rtl {
            background-position-x: -35vw;
        }
    }

    &::after {
        content: '\A';
        background-color: $color_black;
        opacity: .6;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &-content {
        background-color: rgba($color_red, .7);
        height: 100%;
        width: 50%;
        @include padding-left(78px);

        @include ltr {
            border-top-right-radius: 30px;
        }

        @include rtl {
            border-top-left-radius: 30px;
        }

        position: relative;
        z-index: 1;

        @include respond-to(laptop-max) {
            @include padding-left(50px);
        }

        @include respond-to(tablet-max) {
            @include padding-left(27px);
            padding-bottom: 20px;

            @include ltr {
                border-top-right-radius: 15px;
            }

            @include rtl {
                border-top-left-radius: 15px;
            }
        }
    }

    &-title {
        color: $color_white;
        font-size: pxToRem(30px);
        margin: 0;

        @include ltr {
            padding: 61px 32px 32px 0;
        }

        @include rtl {
            padding: 61px 0 32px 32px;
        }

        @include respond-to(tablet-max) {
            font-size: 16px;

            @include ltr {
                padding: 30px 27px 10px 0;
            }

            @include rtl {
                padding: 30px 0 10px 27px;
            }
        }
    }

    &-more {
        color: $color_white;
        text-transform: uppercase;
        font-size: pxToRem(16px);
        border: solid 1px $color_white;
        width: 167px;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        display: inline-block;
        text-align: center;
        text-decoration: none;

        @include respond-to(tablet-max) {
            font-size: 11px;
            width: 116px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }
}
