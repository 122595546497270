.media-center-details {
    margin-top: 30px;
    margin-bottom: 101px;

    @include respond-to(tablet-max) {
        margin-top: 30px;
        margin-bottom: 34px;
    }

    &-date {
        display: block;
        font-size: pxToRem(18px);
        line-height: pxToRem(30px);
        color: $color_gray2;
        margin-bottom: 19px;
        font-weight: 500;

        @include respond-to(tablet-max) {
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 10px;
        }
    }

    &-title {
        font-size: pxToRem(42px);
        font-weight: 300;
        margin-bottom: 28px;

        @include respond-to(tablet-max) {
            font-size: 23px;
            margin-bottom: 10px;
        }
    }

    &-description {
        @include respond-to(tablet-max) {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
