.services-section {
    background-color: $color_white;
    @include margin-right(71px);
    color: $color_gray;
    position: relative;

    @include ltr {
        padding: 46px 142px 61px 212px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    @include rtl {
        padding: 46px 212px 61px 142px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    @include respond-to(tablet-max) {
        @include margin-right(9px);

        @include ltr {
            padding: 34px 0 45px 87px;
        }

        @include rtl {
            padding: 34px 87px 45px 0;
        }
    }

    &-title {
        margin-bottom: 42px;

        @include respond-to(tablet-max) {
            margin-bottom: 6px;
        }
    }

    &-nav {
        position: absolute;
        top: -25px;
        @include left(42px);
        background-color: $color_lightgray;
        box-shadow: 7px 0 14px 0 rgba(0,0,0,0.32);
        border-radius: 30px;
        width: 127px;
        height: calc(100% + 50px);
        padding-top: 20px;
        z-index: 1;
        overflow: auto;

        @include respond-to(laptop-max) {
            padding-top: 20px;
        }

        @include respond-to(tablet-max) {
            @include left(0);
            width: 64px;
            transform: translateY(-50%);
            top: 50%;
            height: auto;
            padding-top: 14px;
            padding-bottom: 14px;

            @include ltr {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            @include rtl {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding-bottom: 20px;

            @include respond-to(tablet-max) {
                padding-bottom: 0;
            }

            &-elem {
                text-align: center;
                margin-bottom: 7px;

                @include respond-to(laptop-max) {
                    margin-bottom: 1px;
                }

                @include respond-to(tablet-max) {
                    height: 32px;
                    margin-bottom: 13px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    height: 59px;
                    pointer-events: none;

                    @include respond-to(tablet-max) {
                        height: 47px;
                    }

                    .services-section-nav-button-icon {
                        width: 47px;
                        height: 47px;
                        color: $color_red;

                        @include respond-to(tablet-max) {
                            width: 47px;
                            height: 47px;
                        }
                    }
                }
            }
        }

        &-button {
            @include reset-button;

            &:focus {
                outline: 0;
            }

            &-icon {
                width: 40px;
                height: 40px;
                color: $color_body_copy;
                transition: width $transition_duration, height $transition_duration, color $transition_duration;


                &:hover {
                    width: 47px;
                    height: 47px;
                    color: $color_red;
                }

                @include respond-to(tablet-max) {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    &-slick {
        overflow: hidden;
        max-height: 367px;

        @include respond-to(laptop-max) {
            max-height: 293px;
        }

        @include respond-to(tablet-max) {
            max-height: 70vw;
        }

        &.slick-slider {
            overflow: visible;
            max-height: none;
        }

        &-wrapper {
            position: relative;
        }
    }

    &-corner {
        color: $color_red;

        @include respond-to(tablet-max) {
            width: 29px;
            height: 29px;
        }

        &-wrapper {
            display: inline-block;
            position: absolute;
            top: -45px;
            @include right(-45px);

            @include rtl {
                transform: rotate(-90deg);
            }

            @include respond-to(tablet-max) {
                top: -17px;
                @include right(-17px);
            }
        }
    }

    &-elem {
        @include respond-to(tablet-max) {
            @include padding-right(28px);
        }

        &-title {
            font-size: pxToRem(30px);
            color: $color_gray;
            letter-spacing: 0;
            margin-top: 30px;

            @include respond-to(tablet-max) {
                margin-top: 0;
                margin-bottom: 11px;
                font-size: 16px;
            }
        }

        &-description {
            color: $color_gray;
        }

        &-link {
            display: inline-block;
            color: $color_white;
            background-color: $color_red;
            text-align: center;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            @include margin-right(26px);
            margin-bottom: 10px;
            font-weight: 500;
            font-size: pxToRem(16px);
            text-transform: uppercase;
            padding: 0 30px;
            text-decoration: none;

            @include respond-to(tablet-max) {
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                @include margin-right(10px);
                margin-bottom: 0;
            }
        }

        &-image {
            width: 100%;
            border-radius: 15px;

            @include respond-to(tablet-max) {
                margin-bottom: 12px;
            }

            &-wrapper {
                @include respond-to(tablet-max) {
                    position: relative;
                }
            }
        }
    }

    .slick-dots {
        display: none !important;
    }
}
