.promotion-card {
    &-image {
        height: 439px;
        background-color: white;
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 30px;
        border: solid 1px $color_black4;

        @include respond-to(tablet-max) {
            height: 47vw;
            border-radius: 15px;
        }
    }

    &-title {
        font-weight: 300;
        font-size: pxToRem(30px);
        line-height: pxToRem(42px);
        letter-spacing: 0;
        margin-bottom: 11px;

        @include respond-to(tablet-max) {
            font-size: 18px;
            line-height: 30px;
            margin-top: 25px;
            margin-bottom: 4px;
        }
    }

    &-description {
        font-weight: 300;
        margin-bottom: 23px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: 60px;

        @include respond-to(laptop-max) {
            height: 52px;
        }

        @include respond-to(tablet-max) {
            color: $color_gray2;
            margin-bottom: 18px;
            height: 40px;
            font-size: pxToRem(22px);
            letter-spacing: 0;
            line-height: pxToRem(32px);
        }
    }

    &-link {
        @include reset-button;
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }

    &-terms {
        font-weight: 300;
        font-size: pxToRem(16px);
        line-height: 1;
        color: $color_gray2;
        position: absolute;
        bottom: 30px;
        @include left(0);
    }

    &-content {
        &-wrapper {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
