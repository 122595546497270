﻿.instagram-stories-section {

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;

            &-icon {
                width: 3rem;
                height: 2.6rem;
                margin-bottom: 25px;
                margin-left: 5px;

                @include respond-to(phone-max) {
                    margin-bottom: 12px;
                }

                @include rtl {
                    margin-left: 0;
                    margin-right: 5px;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-buttons {

            @include respond-to(phone-max) {
                display: none;
            }

            &-arrow {
                width: 20px;
                height: 40px;
                cursor: pointer;

                @include rtl {
                    transform: rotate(180deg);
                }

                &-rotate {
                    transform: rotate(180deg);
                    margin-right: 35px;

                    @include rtl {
                        margin-left: 35px;
                        margin-right: 0;
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    margin-bottom: -30px;
    margin-top: 15px;

    .title-with-image {
        margin-bottom: pxToRem(20px);
    }

    .instagram-story-wrapper {
        position: relative;
        overflow: visible;

        &:before {
            content: '';
            display: block;
            padding-top: calc(100% + 50px);
        }
    }

    .instagram-stories:not(.slick-initialized) .instagram-story-wrapper:nth-child(n+5) {
        display: none;
    }

    .instagram-stories:not(.slick-initialized) .instagram-story-wrapper {
        width: 24%;
        display: inline-block;
    }

    .instagram-stories {
        margin: 0 -1.11111rem;
    }

    .instagram-story {
        position: absolute;
        top: pxToRem(20px);
        right: pxToRem(20px);
        bottom: pxToRem(100px);
        left: pxToRem(20px);
        border-top-right-radius: pxToRem(8px);
        border-top-left-radius: pxToRem(8px);
        background-repeat: no-repeat;
        background-size: cover;


        a {
            display: block;
            width: 100%;
            height: 100%;
            color: white;
        }

        svg {
            position: absolute;
            right: pxToRem(15px);
            bottom: pxToRem(15px);
            width: pxToRem(21px);
            height: pxToRem(21px);
        }

        svg.instagram-story-play {
            top: 50%;
            left: 50%;
            right: auto;
            width: pxToRem(50px);
            height: pxToRem(50px);
            transform: translate(-50%,-50%);
            filter: drop-shadow( -0px -0px 5px rgba(0,0,0,0.7) );
        }

        &-footer {
            height: pxToRem(50px);
            background-color: #28282a;
            border-top: #ce003c pxToRem(6px) solid;
            border-bottom-right-radius: pxToRem(8px);
            border-bottom-left-radius: pxToRem(8px);
            position: relative;
            text-align: right;
            padding: 10px 0;

            svg {
                position: static;
                margin-right: 5px;
                width: 30%;
            }
        }
    }
}
