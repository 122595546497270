.skip-to-content {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    text-decoration: none;

    &:focus {
        top: 0;
        @include left(0);
        z-index: 1009;
        width: auto;
        height: auto;
        clip: auto;
        font-weight: 500;
        text-transform: uppercase;
        color: $color_white;
        background-color: $color_black;
        padding: 10px 20px;
    }
}