.promotion-section {
    margin-top: 80px;

    @include respond-to(tablet-max) {
        margin-top: 30px;
    }

    &-title {
        font-size: pxToRem(32px);
        margin-bottom: 36px;

        @include respond-to(tablet-max) {
            margin-bottom: 26px;
        }
    }
}