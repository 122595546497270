.features-section {
    position: relative;

    &-background {
        position: absolute;
        top: 30%;
        @include right(0px);
        bottom: 0;
        @include left(30%);
        z-index: -1;

        background-position: left top, center;
        background-size: 200%, cover;
        background-repeat: no-repeat;
        opacity: 0.32;

        @include rtl {
            background-position: right top, center;
        }

        @include respond-to(tablet-max) {
            @include left(0px);
        }
    }

    &-item {
        padding-top: pxToRem(40px);
        padding-bottom: pxToRem(40px);

        &-icon {
            width: pxToRem(50px);
            height: pxToRem(50px);

            background-repeat: no-repeat;
            background-position: left center;
        }

        &-name {
            text-transform: uppercase;
        	font-size: pxToRem(24px);
        	text-shadow: 2px 2px 0 0 rgba(0,0,0,0.68);

            margin-top: pxToRem(20px);
            margin-bottom: pxToRem(25px);
        }

        &-description {
        	color: $color_gray4;
        	font-size: pxToRem(18px);
        	line-height: pxToRem(30px);
        }
    }
}
