@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "../../../../../node_modules/bootstrap/scss/grid";
@import "../../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../../node_modules/bootstrap/scss/media";
@import "../../../../../node_modules/bootstrap/scss/utilities";

// Overrides

html[dir="rtl"] .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0;
}

html[dir="rtl"] .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0;
}

html[dir="rtl"] .offset-3 {
    margin-right: 25%;
    margin-left: 0;
}

html[dir="rtl"] .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0;
}

html[dir="rtl"] .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0;
}

html[dir="rtl"] .offset-6 {
    margin-right: 50%;
    margin-left: 0;
}

html[dir="rtl"] .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0;
}

html[dir="rtl"] .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0;
}

html[dir="rtl"] .offset-9 {
    margin-right: 75%;
    margin-left: 0;
}

html[dir="rtl"] .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0;
}

html[dir="rtl"] .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0;
}
