.images-carousel {
    position: relative;
    width: auto;
    max-width: 994px;
    margin: 20px auto;

    &-slick {
        &-elem {
            height: 60vh;
            background-size: cover;
            background-position: center center;

            @include respond-to(tablet-max) {
                height: 57vw;
                border-radius: 15px;
                width: calc(100% - 8vw) !important;
                transform: translateX(0);

                &::after {
                    content: '\A';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $color_black;
                    opacity: 0.8;
                    transition: opacity $transition_duration;
                }
            }
        }

        .slick-slide {
            @include rtl {
                text-align: right;
            }

            &.slick-current.slick-active {
                .images-carousel-slick-elem::after {
                    @include respond-to(tablet-max) {
                        opacity: 0;
                    }
                }

                & + .slick-slide .images-carousel-slick-elem {
                    @include respond-to(tablet-max) {
                        @include ltr {
                            transform: translateX(-4vw);
                        }
                        @include rtl {
                            transform: translateX(4vw);
                        }
                    }
                }
            }
        }

        .slick-list {
            border-radius: 30px;

            @include respond-to(tablet-max) {
                border-radius: 0;
            }
        }

        .slick-arrow {
            @include reset-button;
            color: transparent;
            width: 68px;
            height: 68px;
            background-image: url('../images/icons/arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;

            @include respond-to(tablet-max) {
                display: none !important;
            }

            &.slick-prev {
                @include left(-80px);
                @include ltr {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
            &.slick-next {
                @include right(-80px);
                @include rtl {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        .slick-dots {
            position: absolute;
            @include left(0);
            bottom: calc(-15vh - 20px);
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            width: 100%;

            @include respond-to(tablet-max) {
                display: block;
                text-align: center;
                bottom: -9.5vw;
            }

            li {
                flex-grow: 1;
                @include margin-right(30px);

                @include respond-to(tablet-max) {
                    display: inline-block;
                    @include margin-right(1vw);
                }

                &:last-child {
                    @include margin-right(0);
                }

                &.slick-active button {
                    pointer-events: none;

                    @include respond-to(tablet-max) {
                        opacity: 1;
                    }

                    &::after {
                        opacity: 0;
                    }
                }
            }

            button {
                @include reset-button;
                color: transparent;
                width: 100%;
                height: 15vh;
                border-radius: 15px;
                background-size: cover;
                background-position: center center;
                position: relative;

                @include respond-to(tablet-max) {
                    width: 2.4vw;
                    height: 2.4vw;
                    background-color: $color_red;
                    background-image: none !important;
                    border-radius: 50%;
                    opacity: 0.44;
                    transition: opacity $transition_duration;
                    overflow: hidden;
                }

                &::after {
                    content: '\A';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $color_black;
                    opacity: 0.8;
                    transition: opacity $transition_duration;

                    @include respond-to(tablet-max) {
                        content: none;
                    }
                }
            }
        }
    }

    .mfp-close {
        display: none;
    }

    &-close {
        @include reset-button;
        position: absolute;
        top: -10vh;
        @include right(-87px);

        @include respond-to(tablet-max) {
            position: fixed;
            top: auto;
            @include right(auto);
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: solid 1px $color_gray3;

            &-icon {
                width: 16px;
                height: 16px;
            }
        }

        @media (max-height: 450px) {
            position: absolute;
            bottom: -80px;
        }
    }
}

html.images-carousel-open .mfp-container {
    @media (max-width: 991px) {
       @include padding-left(15px);
       @include padding-right(0);
   }
}