.card-quotes {
    background-color: $color_white;
    height: 100%;
    color: $color_gray;
    padding-top: 57px;
    @include padding-left(100px);
    position: relative;

    @include respond-to(tablet-max) {
        padding-top: 27px;
        @include padding-left(52px);
        @include padding-right(87px);
    }

    &-title {
        color: $color_gray;
        font-size: pxToRem(30px);
        margin-bottom: 15px;

        @include respond-to(tablet-max) {
            font-size: 16px;
            margin-bottom: 5px;
        }
    }

    &-slick {
        &-elem p {
            font-size: pxToRem(20px);
            font-weight: 300;
            margin: 0;
            max-width: 390px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            max-height: 210px;

            @include respond-to(tablet-max) {
                font-size: 13px;
                max-width: none;
                max-height: 26.5vw;
            }
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &-quoteicon {
        position: absolute;
        top: 59px;
        @include left(29px);
        color: $color_body_copy;

        @include rtl {
            transform: scaleX(-1);
        }

        @include respond-to(tablet-max) {
            width: 24px;
            height: 19px;
            top: 30px;
            @include left(15px);
        }
    }

    &-arrow {
        @include reset-button;
        width: 34px;
        height: 34px;
        position: absolute;
        top: 58px;
        overflow: hidden;

        @include respond-to(tablet-max) {
            width: 30px;
            height: 30px;
            top: 25px;
        }

        &-icon {
            @include respond-to(tablet-max) {
                width: 14px;
                height: 31px;
            }
        }

        &.arrow-prev {
            @include right(114px);
            @include ltr {
                transform: rotate(180deg);
            }

            @include respond-to(laptop-max) {
                @include right(71px);
            }

            @include respond-to(tablet-max) {
                @include right(50px);
            }
        }
        &.arrow-next {
            @include right(72px);
            @include rtl {
                transform: rotate(180deg);
            }

            @include respond-to(laptop-max) {
                @include right(29px);
            }

            @include respond-to(tablet-max) {
                @include right(16px);
            }
        }
    }
}