/* ==========================================================================
	Directions
/* ========================================================================== */

$start: left;
$end: right;
$margin-start: margin-left;
$margin-end: margin-right;

/* ==========================================================================
	Variables
/* ========================================================================== */

$app-min-width: 320px;

/* Colors */

$color_body_copy: #a0a5a9;
$color_copy_placeholder: #262626;
$color_link: #26527c;
$color_black: #000;
$color_black2: #121212;
$color_black3: #191919;
$color_black4: #292929;
$color_black5: #232323;
$color_red: #ce003c;
$color_white: #fff;
$color_gray: #19262f;
$color_gray2: #768693;
$color_gray3: #3b3b3b;
$color_gray4: #bbbbbb;
$color_gray5: #3d4543;
$color_lightgray: #e6e6e6;
$color_lightgray2: #E8EBED;

/* Font Sizes */

$size_base: 18px;

/* Transitions and animations */
$transition_duration: 350ms;

/* ==========================================================================
	Responsive
/* ========================================================================== */

$phone-min-size: 320px;
$phone-max-size: 767px;
$tablet-min-size: 768px;
$tablet-max-size: 991px;
$laptop-min-size: 992px;
$laptop-max-size: 1199px;
$desktop-min-size: 1200px;
$desktop-max-size: 2520px;
