.terms-and-conditions {
    color: $color_gray2;
    font-weight: 300;

    h6 {
        margin-top: pxToRem(25px);
    }

    ul {
        padding: 0;
    }

    li {
        line-height: pxToRem(30px);
        margin-bottom: pxToRem(10px);
        list-style: none;
        display: flex;

        &:before {
            content: '- ';
            @include margin-right(pxToRem(5px));
        }
    }
}
