.footer {
    padding-top: pxToRem(40px);
    padding-bottom: pxToRem(40px);
    background-color: $color_black;
    background-image: linear-gradient(0deg, rgba(82, 82, 82, 0.4) 0%, #000000 100%);

    &-top {
        display: flex;
        align-items: center;
    }

    &-topLogo {
        color: $color_gray5;
        width: pxToRem(220px);
        height: pxToRem(75px) !important;
        min-width: 100px;
        min-height: 30px;
    }

    &-topSeparator {
        flex-grow: 1;
        height: 3px;
        @include margin-left(pxToRem(42px));
        background: $color_red;

        @include respond-to(tablet-max) {
            @include margin-left(pxToRem(20px));
        }
    }

    &-content {
        padding: pxToRem(20px) 0;
        color: $color_gray2;

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex-wrap: wrap;

            @include respond-to(tablet-max) {
                flex-direction: row;
                justify-content: flex-start;
            }
        }

        &-slogan {
            font-size: pxToRem(16px);
            font-weight: 300;
            line-height: pxToRem(21px);
            min-height: 70px;
        }

        &-follow {
            @include respond-to(tablet-max) {
                @include text-align(right);
            }

            @media only screen and (max-width: 360px) {
                width: 100%;
            }

            &-text {
                color: $color_white;
                font-size: pxToRem(20px);
                font-weight: 300;
                line-height: pxToRem(28px);
                margin-bottom: pxToRem(10px);
            }

            &-social-networks {
                a {
                    &:not(:last-child) {
                        @include margin-right(pxToRem(20px));
                    }
                }

                a > img {
                    height: pxToRem(20px);
                    width: pxToRem(20px);
                }
            }
        }

        &-copyright {
            text-transform: uppercase;
            font-size: pxToRem(14px);
            font-weight: 300;
            line-height: pxToRem(18px);
            margin-top: pxToRem(18px);
        }

        &-navigation {
            display: flex;
            justify-content: space-around;
            font-size: pxToRem(14px);
            font-weight: 500;

            &-list {
                display: flex;
                flex-direction: column;

                a {
                    color: $color_gray2;
                    text-transform: uppercase;
                    padding: pxToRem(10px) 0;
                    line-height: pxToRem(22px);
                    text-decoration: none;

                    &:hover {
                        color: $color_white;
                    }
                }
            }
        }
    }
}
