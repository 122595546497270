.mobile-navigation {
    z-index: 2;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(100% - #{pxToRem($header-height)});

    background-color: $color_black;
    border-top: 1px solid rgba(151, 151, 151, 0.54);
    text-transform: uppercase;

    // Animation properties
    visibility: hidden;
    opacity: 0;
    transform: translateX(100px);

    @include rtl {
        transform: translateX(-100px);
    }

    transition:
        visibility $transition_duration,
        opacity $transition_duration,
        transform $transition_duration;

    &-open {
        visibility: visible;
        opacity: 1;
        transform: translate(0);
    }

    // Media queries
    @include respond-to(laptop-min) {
        display: none;
    }

    @include respond-to(phone-max) {
        height: calc(100% - #{pxToRem($header-height-mobile)});
    }

    &-list {
        @include reset-list;
    }

    &-secondary {
        flex-grow: 1;

        font-size: pxToRem(24px);
        line-height: pxToRem(80px);

        &-item {

            & > a {
                display: block;
                height: 100%;
                width: 100%;
                text-decoration: none;
            }

            &-hasSubNavigation {
                display: flex;
                justify-content: space-between;

                &-arrow {
                    width: pxToRem(30px);
                    display: flex;
                    align-items: center;

                    @include rtl {
                        transform: rotateZ(180deg);
                    }

                    svg {
                        width: pxToRem(25px);
                        height: auto;
                    }
                }
            }
        }

    }

    &-footer {
        margin-top: pxToRem(30px);

        li a {
            display: block;
            height: 100%;
            width: 100%;

            font-size: pxToRem(26px);
            line-height: pxToRem(88px);
            color: $color_body_copy;

            &:hover,
            &:active,
            &.active {
                color: $color_red;
            }
        }
    }

    &-background {
        position: absolute;
        top: 50%;
        bottom: 0;
        @include right(0);
        @include left(20%);

        z-index: -1;
        background-color: rgba(9,21,32,0.78);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
        filter: grayscale(100%);
    }
}

.mobile-navigation-main {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    transition: transform $transition_duration;

    &-item a {
        text-decoration: none;
    }
}

.mobile-navigation-subNavigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateX(100%);

    @include rtl {
        transform: translateX(-100%);
    }

    transition:
        opacity $transition_duration,
        transform $transition_duration,
        visibility $transition_duration;
}

.mobile-navigation-subNavigation-item {
    position: absolute;
    height: 100%;
    right: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    background: $color_black;

    transition:
        visibility $transition_duration,
        opacity $transition_duration;

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        height: pxToRem(100px);
        margin-bottom: pxToRem(20px);

        color: $color_white;
        font-size: pxToRem(32px);

        border-bottom: 1px solid rgba(151, 151, 151, 0.25);
    }

    &-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        @include margin-right(15px);

        @include ltr {
            transform: rotateZ(180deg);
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }

    a {
        color: $color_body_copy;
        font-size: pxToRem(26px);
        line-height: pxToRem(70px);
        @include margin-left(pxToRem(70px));
        text-decoration: none;

        &:hover,
        &:active,
        &.active {
            color: $color_red;
        }
    }

    &-background {
        position: absolute;
        top: 30%;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba(9,21,32,0.78);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.3;
        filter: grayscale(100%);
    }
}

.mobile-navigation-subNavigation-open {
    .mobile-navigation-main {
        transform: translateX(-100%);

        @include rtl {
            transform: translateX(100%);
        }
    }

    .mobile-navigation-subNavigation {
        transform: translateX(0);

        @include rtl {
            transform: translateX(0);
        }
    }

    .mobile-navigation-subNavigation-item-open {
        visibility: visible;
        opacity: 1;
    }
}

.mobile-navigation-toggle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        fill: $color_white;
    }

    &-icon {
        transition:
            opacity $transition_duration,
            transform $transition_duration;
    }

    .mobile-navigation-cross {
        position: absolute;
        opacity: 0;
        transform: translateX(100%);

        @include rtl {
            transform: translateX(-100%);
        }
    }

    &-open {
        .mobile-navigation-hamburger {
            opacity: 0;
        }
        .mobile-navigation-cross {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @include respond-to(laptop-min) {
        display: none;
    }
}
