.hero {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 700px;

    @include respond-to(tablet-max) {
        height: 102vw;
    }

    &-title {
        width: 60%;
        align-self: flex-start;
        @include text-align(left);
        font-size: pxToRem(50px);
        font-weight: 500;
        line-height: pxToRem(60px);
        color: $color_white;

        @include respond-to(tablet-max) {
            margin-bottom: 50px;
        }

        @include respond-to(phone-max) {
            font-size: 30px;
            line-height: 35px;
            width: 80vw;
        }

        @media (max-width: 320px) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        background-position: center;
        background-size: cover;
        opacity: 0.32;
        filter: grayscale(76%);

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background: linear-gradient(rgba(0, 0, 0, 0) 60%, $color_black);
        }
    }


    &-title, &-sliders {
        z-index: 3;
    }
}
