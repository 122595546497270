.title-list-section {
    padding-bottom: pxToRem(40px);

    &-title {
        font-size: pxToRem(42px);
        font-weight: 500;
        color: $color_red;
        margin-bottom: pxToRem(45px);
        line-height: normal;
    }

    &-description {
        font-size: pxToRem(18px);
        line-height: pxToRem(30px);
    }

    &-list {
        margin-top: pxToRem(25px);

        &-item {
            &-title {
                font-weight: 500;
                font-size: pxToRem(30px);

                &::after {
                    content: '\A';
                    display: inline-block;
                    width: pxToRem(16px);
                    height: pxToRem(16px);
                    background-image: url('../images/icons/corner-red.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: relative;
                    @include left(-2px);
                    top: -12px;

                    @include rtl {
                        transform: rotate(-90deg);
                    }
                }
            }

            &-description {
                color: $color_gray2;
            }

            &:not(:last-child) {
                margin-bottom: pxToRem(30px);
            }
        }
    }
}
