form.generic-form {
    .sf_cols .container .row {
        margin-bottom: 43px;

        @include respond-to(tablet-max) {
            margin-bottom: 35px;
        }
    }

    .sf_cols:last-child .container .row {
        margin-bottom: 0;
    }

    .generic-form {
        &-select {
            @include reset-input;
            width: 100%;
            height: 75px;
            background-color: $color_red;
            color: $color_white;
            border-radius: 6.3px;
            font-size: pxToRem(30px);
            letter-spacing: 0;
            padding: 0 35px;
            cursor: pointer;

            @include respond-to(tablet-max) {
                height: 53px;
                border-radius: 4.5px;
                font-size: 16px;
                padding: 0 18px;
            }

            option {
                color: $color_black;
                background-color: $color_white;

                &:first-child {
                    display: none;
                }
            }

            &-wrapper {
                width: 100%;
                margin-bottom: 50px;
                position: relative;

                @include respond-to(tablet-max) {
                    margin-bottom: 22px;
                }

                &.error .generic-form-select-label {
                    display: none;
                }
            }

            &-label {
                position: absolute;
                opacity: 0;
                transform: translateY(20px);
                top: -32px;
                @include left(0);
                pointer-events: none;
                font-size: pxToRem(18px);
                letter-spacing: 0;
                line-height: 1;
                transition: opacity $transition_duration, transform $transition_duration;

                @include respond-to(tablet-max) {
                    transform: translateY(20px);
                    top: -20px;
                    font-size: 13px;
                }
            }

            &-caret {
                position: absolute;
                top: calc(50% - 5px);
                @include right(22px);
                pointer-events: none;

                @include respond-to(tablet-max) {
                    width: 15px;
                    height: 8px;
                    top: calc(50% - 4px);
                }
            }

            &:focus + .generic-form-select-label {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &-text {
            @include reset-input;
            width: 100%;
            height: 75px;
            background-color: $color_black2;
            color: $color_white;
            border: 1px solid $color_black5;
            border-radius: 6.3px;
            font-size: pxToRem(30px);
            letter-spacing: 0;
            padding: 0 35px;

            @include respond-to(tablet-max) {
                height: 53px;
                border-radius: 4.5px;
                font-size: 16px;
                padding: 0 18px;
            }

            &::placeholder {
                color: $color_gray2;
            }

            option {
                color: initial;
                background-color: $color_white;

                &:first-child {
                    display: none;
                }
            }

            &-wrapper {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 50px;
                }

                position: relative;

                @include respond-to(tablet-max) {
                    margin-bottom: 37px;
                }

                &.error .generic-form-text-label {
                    display: none;
                }
            }

            &-label {
                position: absolute;
                opacity: 0;
                transform: translateY(20px);
                top: -32px;
                pointer-events: none;
                font-size: pxToRem(18px);
                letter-spacing: 0;
                line-height: 1;
                transition: opacity $transition_duration, transform $transition_duration;

                @include respond-to(tablet-max) {
                    transform: translateY(20px);
                    top: -20px;
                    font-size: 13px;
                }
            }

            &-corner {
                position: absolute;
                top: 10px;
                @include right(10px);
                pointer-events: none;
                color: $color_gray2;
                transition: color $transition_duration;

                @include rtl {
                    transform: rotate(-90deg);
                }

                @include respond-to(tablet-max) {
                    width: 11px;
                    height: 10px;
                    top: 8px;
                    @include right(8px);

                    svg {
                        width: 11px;
                        height: 10px;
                    }
                }
            }

            &:focus + .generic-form-text-deps {
                .generic-form-text-label {
                    opacity: 1;
                    transform: translateY(0);
                }

                .generic-form-text-corner {
                    color: $color_red;
                }
            }
        }

        &-submit-wrapper {
            @include respond-to(tablet-max) {
                text-align: center;
            }

            button[type="submit"] {
                @include reset-button;
                display: inline-block;
                color: $color_white;
                background-color: $color_red;
                text-align: center;
                height: 38px;
                line-height: 38px;
                border-radius: 19px;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: pxToRem(16px);
                text-transform: uppercase;
                padding: 0 30px;

                @include respond-to(tablet-max) {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }

    textarea.generic-form-text {
        resize: none;
        height: 200px;
        padding-top: 13px;

        @include respond-to(tablet-max) {
            height: 141px;
        }
    }

    .error-inline {
        position: absolute;
        top: -32px;
        pointer-events: none;
        font-size: pxToRem(18px);
        color: $color_red;
        letter-spacing: 0;
        line-height: 1;

        @include respond-to(tablet-max) {
            top: -20px;
            font-size: 13px;
        }
    }
}
