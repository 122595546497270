.promotion-section-slider {
    position: relative;
    padding-top: 109px;
    padding-bottom: 32px;

    @include respond-to(tablet-max) {
        padding-top: 55px;
    }

    &-maintitle {
        margin: 0;
        margin-bottom: 36px;

        @include respond-to(tablet-max) {
            position: absolute;
            top: 20px;
        }

        &-col {
            width: 100%;
        }
    }

    &-slick {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        max-height: 445px;

        @include respond-to(laptop-max) {
            max-height: 403px;
        }

        @include respond-to(tablet-max) {
            max-height: 87vw;
        }

        &.slick-slider {
            overflow: visible;
            max-height: none;
        }

        &-elem {
            &-wrapper {
                display: flex;

                @include respond-to(tablet-max) {
                    display: block;
                    padding-top: 62px;
                }
            }
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &-content {
        @include margin-left(60px);
        margin-top: 25px;

        @include respond-to(tablet-max) {
            @include margin-left(29px);
        }

        @media (max-width: 320px) {
            @include margin-left(10px);
        }
    }

    &-promotion {
        display: flex;

        @include respond-to(tablet-max) {
            position: absolute;
            top: 0;
            @include right(85px);
        }

        &-label {
            display: inline-block;
            text-transform: uppercase;
            font-size: pxToRem(18px);
            font-weight: 300;
            background-color: $color_gray;
            height: 31px;
            line-height: 31px;
            width: 167px;
            @include padding-left(10px);
            margin-top: 51px;

            @include respond-to(tablet-max) {
                font-size: 11px;
                height: 20px;
                line-height: 20px;
                width: 74px;
                @include padding-left(5px);
                margin-top: 23px;
            }
        }
    }

    &-index {
        line-height: 1;
        vertical-align: bottom;
        position: relative;
        top: -13px;
        font-weight: 500;

        @include respond-to(tablet-max) {
            top: -6px;
        }

        &-wrapper {
            display: inline-block;
            color: $color_gray;
            background-color: $color_white;
            font-size: pxToRem(50px);
            width: 66px;
            height: 102px;
            box-shadow: 0 0 105px 0 rgba(0,0,0,0.50);
            border-radius: 8px;
            text-align: center;
            line-height: 102px;

            @include respond-to(tablet-max) {
                font-size: 23px;
                width: 30px;
                height: 46px;
                box-shadow: 0 0 48px 0 rgba(0,0,0,0.50);
                border-radius: 4px;
                line-height: 46px;
            }
        }
    }

    &-image {
        display: inline-block;
        width: 666px;
        min-width: 666px;
        height: 439px;
        background-size: cover;
        background-position: center center;
        border: solid 1px $color_gray;
        border-radius: 30px;

        @include respond-to(laptop-max) {
            width: 536px;
            min-width: 536px;
            height: 398px;
        }

        @include respond-to(tablet-max) {
            border-radius: 15px;
            width: 100%;
            min-width: 0;
            height: 59vw;
        }
    }

    &-title {
        line-height: pxToRem(49px);
        margin-top: 15px;
        margin-bottom: 5px;
        position: relative;

        @include respond-to(tablet-max) {
            line-height: 25px;
            margin-top: 13px;
            margin-bottom: 3px;
            font-size: 16px;
        }
    }

    &-description {
        line-height: pxToRem(30px);
        margin-bottom: 23px;
        max-height: 66px;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        @include respond-to(laptop-max) {
            max-height: 56px;
        }

        @include respond-to(tablet-max) {
            line-height: 20px;
            margin-bottom: 5px;
            @include margin-right(10px);
            max-height: 45px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            @include float(left);
            width: 60%;
        }
    }

    &-link {
        display: inline-block;
        color: $color_white;
        background-color: $color_red;
        text-align: center;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        font-weight: 500;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        padding: 0 30px;
        text-decoration: none;

        @include respond-to(tablet-max) {
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            padding: 0 17px;
            @include float(left);
        }
    }

    &-terms {
        display: block;
        color: $color_gray2;
        font-size: pxToRem(16px);
        line-height: pxToRem(30px);
        margin-top: 54px;

        @include respond-to(tablet-max) {
            margin-top: 0;
            display: inline-block;
            width: 100%;
        }
    }

    &-arrow {
        @include reset-button;
        width: 34px;
        height: 34px;
        position: absolute;
        top: 80px;
        overflow: hidden;
        z-index: 1;

        &-wrapper {
            position: relative;
        }

        @include respond-to(tablet-max) {
            width: 30px;
            height: 30px;
            top: 10px;
        }

        &-icon {
            @include respond-to(tablet-max) {
                width: 14px;
                height: 31px;
            }
        }

        &.arrow-prev {
            @include right(110px);
            @include ltr {
                transform: rotate(180deg);
            }

            @include respond-to(laptop-max) {
                @include right(60px);
            }

            @include respond-to(tablet-max) {
                @include right(35px);
            }
        }
        &.arrow-next {
            @include right(70px);
            @include rtl {
                transform: rotate(180deg);
            }

            @include respond-to(laptop-max) {
                @include right(20px);
            }

            @include respond-to(tablet-max) {
                @include right(0);
            }
        }
    }
}