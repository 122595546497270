.media-center-images {
    margin-bottom: 138px;

    @include respond-to(tablet-max) {
        margin-bottom: 39px;
    }

    .container {
        @media (min-width: 992px) {
            max-width: 1010px;
        }

        @media (min-width: 1200px) {
            max-width: 1190px;
        }

        @include respond-to(tablet-max) {
            padding: 0;
            @include padding-left(15px);
        }
    }

    &-image {
        height: 167px;
        background-size: cover;
        background-position: center center;
        border-radius: 15px;

        @include respond-to(tablet-max) {
            border-radius: 8px;
            height: 27vw;
            width: calc(100% - 24px) !important;
        }
    }

    &-button {
        @include reset-button;
        display: block;
        padding: 0 25px;

        @include respond-to(tablet-max) {
            padding: 0;
            position: relative;

            &::after {
                content: '\A';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color_black;
                opacity: 0.8;
                transition: opacity $transition_duration;
            }
        }
    }

    &-slick {
        .slick-slide {
            @include respond-to(tablet-max) {
                &.slick-active {
                    .media-center-images-button::after {
                        @include respond-to(tablet-max) {
                            opacity: 0;
                        }
                    }

                    & + .slick-slide .media-center-images-button {
                        @include ltr {
                            transform: translateX(-18px);
                        }
                        @include rtl {
                            transform: translateX(18px);
                        }
                    }

                    & + .slick-slide + .slick-slide .media-center-images-button {
                        @include ltr {
                            transform: translateX(-36px);
                        }
                        @include rtl {
                            transform: translateX(36px);
                        }
                    }
                }
            }
        }

        .slick-arrow {
            @include reset-button;
            color: transparent;
            width: 34px;
            height: 34px;
            background-image: url('../images/icons/arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            bottom: -63px;
            overflow: hidden;

            @include respond-to(tablet-max) {
                display: none !important;
            }

            &.slick-prev {
                @include left(calc(50% - 100px));
                @include ltr {
                    transform: rotate(180deg);
                }
            }
            &.slick-next {
                @include right(calc(50% - 100px));
                @include rtl {
                    transform: rotate(180deg);
                }
            }
        }
    
        .slick-dots {
            position: absolute;
            list-style: none;
            padding: 0;
            margin: 0;
            bottom: -53px;
            left: 50%;
            transform: translateX(-50%);
            height: 15px;

            @include respond-to(tablet-max) {
                display: none !important;
            }

            li {
                display: inline-block;
                height: 15px;
                @include margin-right(6px);

                &:last-child {
                    @include margin-right(0);
                }

                &.slick-active button {
                    pointer-events: none;
                    opacity: 1;
                }

                button {
                    @include reset-button;
                    color: transparent;
                    width: 15px;
                    height: 15px;
                    background-color: $color_red;
                    border-radius: 50%;
                    opacity: 0.44;
                    transition: opacity $transition_duration;
                    overflow: hidden;
                }
            }
        }
    }
}