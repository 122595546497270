.card-newsletter {
    font-weight: 500;
    background-color: $color_red;
    background-image: url('/ResourcePackages/Theme/assets/dist/images/card-newsletter-image.png');
    background-size: cover;
    background-position: center center;
    height: 100%;
    text-align: center;
    color: $color_white;
    padding-top: 74px;

    @include respond-to(tablet-max) {
        padding-top: 26px;
    }

    &-title {
        color: $color_white;
        font-size: pxToRem(30px);
        margin-bottom: 11px;

        @include respond-to(tablet-max) {
            margin-bottom: 5px;
        }
    }

    &-description {
        font-size: pxToRem(18px);
        line-height: pxToRem(30px);
        max-width: 473px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;

        @include respond-to(tablet-max) {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }

    &-email {
        @include reset-input;
        background-color: $color_white;
        border-radius: 9px;
        width: 100%;
        height: 40px;
        font-size: pxToRem(18px);
        color: $color_gray;

        @include ltr {
            padding: 0 30px 0 14px;
        }
        @include rtl {
            padding: 0 14px 0 30px;
        }

        &::placeholder {
            color: $color_gray2;
        }

        &:focus + .card-newsletter-email-corner {
            color: $color_red;
        }

        @include respond-to(tablet-max) {
            border-radius: 5px;
            height: 54px;
            font-size: 16px;
        }

        &-wrapper {
            display: inline-block;
            position: relative;
            width: 100%;
            max-width: 308px;

            @include respond-to(tablet-max) {
                max-width: none;
                padding: 0 20px;
            }

            &.error .error-inline {
                position: absolute;
                @include left(0);
                top: -25px;
                font-weight: 500;
                font-size: pxToRem(15px);

                @include respond-to(tablet-max) {
                    position: static;
                    font-size: 12px;
                    margin-top: 10px;
                }
            }
        }

        &-corner {
            position: absolute;
            top: 5px;
            @include right(5px);
            color: $color_gray2;
            transition: color $transition_duration;
            width: 21px;
            height: 20px;

            @include rtl {
                transform: rotate(-90deg);
            }

            @include respond-to(tablet-max) {
                width: 14px;
                height: 13px;
                top: 8px;
                @include right(28px);

                svg {
                    width: 14px;
                    height: 13px;
                }
            }
        }
    }

    &-subscribe {
        @include reset-button;
        font-size: pxToRem(16px);
        text-transform: uppercase;
        color: $color_white;
        border: solid 1px $color_white;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        padding: 0 20px;
        @include margin-left(17px);

        @include respond-to(tablet-max) {
            margin-top: 14px;
            margin-bottom: 30px;
            @include margin-left(0);
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
        }
    }
}
