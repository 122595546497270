.split-section {
    padding-bottom: pxToRem(40px);

    &-content {
        @include respond-to(laptop-min) {
            @include padding-right(pxToRem(60px));
        }
    }

    &-title {
        font-size: pxToRem(42px);
        font-weight: 500;
        color: $color_red;
        margin-bottom: pxToRem(45px);
        line-height: normal;
    }

    &-subtitle {
        margin-bottom: pxToRem(15px);

    	font-size: pxToRem(30px);
    	font-weight: 300;
    	line-height: pxToRem(45px);
    }

    @include respond-to(tablet-max) {
        &-subtitle + &-description {
            color: $color_body_copy;
        }
    }


    &-description {
        font-size: pxToRem(18px);
        line-height: pxToRem(30px);
        font-weight: 300;
        line-height: pxToRem(30px);
    }

    .split-section-image {
        height: pxToRem(421px);
        width: 100%;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        box-shadow: 0 20px 140px 10px rgba(255,255,255,0.18);

        @include respond-to(tablet-max) {
            height: pxToRem(337px);
            width: 100%;
            margin-bottom: pxToRem(50px);

            border-radius: 24px;
            box-shadow: 0 20px 140px 10px rgba(255,255,255,0.05);
        }
    }
    
    &-links {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            display: inline-block;
            color: $color_white;
            background-color: $color_red;
            min-width: 183px;
            text-align: center;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            @include margin-right(26px);
            margin-bottom: 10px;

            @include respond-to(tablet-max) {
                min-width: 127px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                @include margin-right(10px);
            }

            &:last-child {
                @include margin-right(0);
            }

            &-link {
                font-weight: 500;
                font-size: pxToRem(16px);
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
}
