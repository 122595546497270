.store-locator {
    height: calc(100vh - 116px);
    overflow: hidden;
    position: relative;

    @include respond-to(tablet-max) {
        height: 75vh;
    }

    &-container {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        @include left(0px);
        top: 0px;
    }

    &-zoom {
        position: absolute;
        top: 0;
        @include left(0);
        width: 100%;
        height: 100%;
        pointer-events: none;

        &-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;

            @include respond-to(tablet-max) {
                flex-direction: row;
                align-items: flex-end;
            }
        }

        &-in,
        &-out {
            @include reset-button;
            width: 46px;
            height: 46px;
            background-color: $color_black;
            border-radius: 8px;
            box-shadow: 0 3px 15px 0 rgba($color_black, .5);
            pointer-events: auto;

            @include respond-to(tablet-max) {
                width: 38px;
                height: 38px;
                box-shadow: 0 3px 13px 0 rgba($color_black, .5);
                border-radius: 7px;
                margin-bottom: 29px;
            }

            &-icon {
                color: $color_white;
                position: relative;
                top: 4px;

                @include respond-to(tablet-max) {
                    width: 19px;
                    height: 19px;
                }
            }
        }

        &-in {
            &-icon {
                left: 1px;

                @include respond-to(tablet-max) {
                    left: 0;
                }
            }
        }

        &-out {
            margin-bottom: 17px;

            @include respond-to(tablet-max) {
                margin-bottom: 29px;
                @include margin-right(19px);
            }
        }
    }

    &-filters {
        background-color: $color_white;
        box-shadow: 0 0 28px 0 rgba($color_black, .22);
        position: absolute;
        top: 0;
        @include right(0);
        width: 408px;
        height: 100%;

        @include ltr {
            transform: translateX(100%);
        }

        @include rtl {
            transform: translateX(-100%);
        }

        transition: transform $transition_duration;

        @include respond-to(phone-max) {
            box-shadow: 0 0 19px 0 rgba($color_black, .22);
            width: 66vw;
        }

        &.active {
            transform: translateX(0);

            .store-locator-filters-togglebutton-icon {
                color: $color_red;
            }
        }

        &-scrollable {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            height: 100%;
            padding: 0;
        }

        &-form {
            padding-top: 94px;

            @include respond-to(phone-max) {
                padding-top: 36px;
            }
        }

        &-togglebutton {
            @include reset-button;
            width: 73px;
            height: 71px;
            background-color: $color_white;
            box-shadow: 0 0 28px 0 rgba($color_black, .22);
            position: absolute;
            top: 83px;
            @include left(-73px);

            @include ltr {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            @include rtl {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            @include respond-to(phone-max) {
                width: 50px;
                height: 46px;
                box-shadow: 0 2px 18px 0 rgba($color_black, .26);
                top: 32px;
                @include left(-50px);

                @include ltr {
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                }

                @include rtl {
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                }
            }

            &::after {
                content: '\A';
                display: inline-block;
                background-color: $color_white;
                position: absolute;
                top: -20px;
                @include right(-20px);
                width: 20px;
                height: calc(100% + 40px);
                pointer-events: none;

                @include respond-to(phone-max) {
                    top: -15px;
                    @include right(-15px);
                    width: 15px;
                    height: calc(100% + 30px);
                }
            }

            &-icon {
                position: relative;
                top: 3px;
                color: $color_black;
                transition: color $transition_duration;

                @include respond-to(phone-max) {
                    width: 19px;
                    height: 18px;
                }
            }
        }

        &-filter {
            color: $color_black;
            font-weight: 300;
            font-size: pxToRem(18px);
            transition: box-shadow $transition_duration;
            padding: 0 28px;
            margin-bottom: 25px;

            @include respond-to(phone-max) {
                padding: 0 16px;
                margin-bottom: 16px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                box-shadow: 0 10px 40px 0 rgba($color_black, .1);

                @include respond-to(phone-max) {
                    box-shadow: 0 5px 20px 0 rgba($color_black, .1);
                }

                .store-locator-filters-filter-header {
                    background-color: $color_white;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .store-locator-filters-filter-container {
                    max-height: 500px;

                    @include respond-to(phone-max) {
                        max-height: 250px;
                    }
                }

                .store-locator-filters-filter-checkbox {
                    opacity: 1;
                }
            }

            &-header {
                @include reset-button;
                width: 100%;
                background-color: $color_lightgray2;
                border-top-left-radius: 9px;
                border-top-right-radius: 9px;
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
                height: 61px;
                padding: 0 33px;
                border: 1px solid $color_lightgray2;
                @include text-align(left);
                transition: background-color $transition_duration, border-bottom-left-radius $transition_duration, border-bottom-right-radius $transition_duration;

                @include respond-to(phone-max) {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    height: 46px;
                    padding: 0 14px;
                }

                & > span {
                    display: flex;
                    align-items: center;
                }
            }

            &-title {
                font-weight: 500;
                font-size: pxToRem(18px);
                margin: 0;
                flex-basis: 35%;
            }

            &-value {
                font-weight: 300;
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @include margin-right(10px);

                @include respond-to(phone-max) {
                    @include margin-right(5px);
                }
            }

            &-container {
                border-left: 1px solid $color_lightgray2;
                border-right: 1px solid $color_lightgray2;
                border-bottom: 1px solid $color_lightgray2;
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
                overflow: hidden;
                max-height: 0;
                transition: max-height $transition_duration;

                @include respond-to(phone-max) {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                &-gutter {
                    padding: 39px 34px;

                    @include respond-to(phone-max) {
                        padding: 20px 17px;
                    }
                }
            }

            &-checkbox {
                opacity: 0;
                transition: opacity $transition_duration;

                &-input {
                    display: none;

                    &:checked + .store-locator-filters-filter-checkbox-label {
                        font-weight: 500;

                        .store-locator-filters-filter-checkbox-label-icon {
                            color: $color_red;
                        }
                    }
                }

                &-label {
                    display: flex;
                    line-height: 1;
                    margin-bottom: 21px;
                    cursor: pointer;
                    font-weight: 300;

                    @include respond-to(phone-max) {
                        margin-bottom: 11px;
                    }

                    &-text {
                        width: 80%;
                    }

                    &-icon {
                        color: $color_gray2;
                        transition: color $transition_duration;
                        @include margin-right(14px);
                        position: relative;
                        top: 4px;
                        width: 25px;
                        height: 22px;

                        @include respond-to(phone-max) {
                            width: 15px;
                            height: 15px;
                            @include margin-right(10px);
                            top: 2px;
                        }
                    }
                }

                &:last-child .store-locator-filters-filter-checkbox-label {
                    margin-bottom: 0;
                }
            }

            &-caret {
                @include respond-to(phone-max) {
                    width: 15px;
                    height: 8px;
                }
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            background-color: $color_white;
            position: sticky;
            top: 0;
            z-index: 1;
            padding: 25px 28px;

            @include respond-to(phone-max) {
                padding: 16px;
            }
        }

        &-filterbutton {
            @include reset-button;
            display: inline-block;
            color: $color_white;
            background-color: $color_red;
            text-align: center;
            width: 162px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            font-weight: 500;
            font-size: pxToRem(13px);
            text-transform: uppercase;
        }

        &-resetbutton {
            @include reset-button;
            display: inline-block;
            color: $color_red;
            text-align: center;
            width: 162px;
            height: 30px;
            line-height: 30px;
            font-weight: 500;
            font-size: pxToRem(13px);
            text-transform: uppercase;
        }
    }

    &-infowindow {
        position: relative;

        &-container {
            background-color: $color_white;
            color: $color_black;
            border-radius: 30px;
            box-shadow: 0 4px 40px 0 rgba($color_black, .17);
            width: 100%;
            height: auto;
            padding-bottom: 27px;

            @include respond-to(phone-max) {
                border-radius: 15px;
                box-shadow: 0 3px 24px 0 rgba($color_black, .17);
                width: 221px;
                padding-bottom: 20px;
            }
        }

        &-image {
            @include reset-button;
            width: 100%;
            height: 187px;
            background-size: cover;
            background-position: center center;
            color: transparent;
            position: relative;

            &:hover {
                &::before {
                    opacity: 1;
                }
            }

            &::before {
                content: '\A';
                background-color: rgba($color_black, .7);
                background-image: url('../images/icons/google-street-view.svg');
                background-repeat: no-repeat;
                background-size: 100px 100px;
                background-position-x: 20%;
                background-position-y: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                opacity: 0;
                transition: opacity $transition_duration;
            }

            @media (max-height: 768px) {
                height: 120px;
            }

            @include respond-to(phone-max) {
                height: 101px;
            }

            &.disabled {
                pointer-events: none;

                & > span {
                    display: none;
                }
            }
        }

        &-close {
            @include reset-button;
            position: absolute;
            top: 16px;
            @include right(16px);
            width: 37px;
            height: 37px;
            background-color: rgba($color_black, .4);
            border-radius: 5px;

            @include respond-to(phone-max) {
                top: 10px;
                @include right(10px);
                width: 23px;
                height: 23px;
                border-radius: 3px;
            }

            &-icon {
                position: relative;
                top: 2px;

                @include respond-to(phone-max) {
                    width: 8px;
                    height: 8px;
                    top: 0;
                    left: 1px;
                }
            }
        }

        &-info {
            font-weight: 500;
            font-size: pxToRem(18px);
            @include padding-left(30px);
            position: relative;
            margin-bottom: 24px;

            @include respond-to(phone-max) {
                @include padding-left(18px);
                margin-bottom: 12px;
            }

            &-container {
                list-style: none;
                padding: 27px 27px 0 27px;
                margin: 0;

                @include respond-to(phone-max) {
                    padding: 20px 20px 0 20px;
                }
            }

            &-locationicon,
            &-phoneicon,
            &-hoursicon {
                position: absolute;
                top: 0;
                @include left(0);
            }

            &-locationicon {
                top: -2px;

                @include respond-to(phone-max) {
                    top: 0;
                    width: 12px;
                    height: 15px;
                }
            }

            &-phoneicon {
                @include respond-to(phone-max) {
                    width: 10px;
                    height: 10px;
                    top: 1px;
                }
            }

            &-hoursicon {
                @include respond-to(phone-max) {
                    width: 12px;
                    height: 12px;
                    top: 1px;
                }
            }
        }

        &-getdirection {
            display: inline-block;
            color: $color_white;
            background-color: $color_red;
            text-align: center;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            font-weight: 500;
            font-size: pxToRem(13px);
            text-transform: uppercase;
            padding: 0 15px;
            @include margin-left(27px);
            margin-top: 6px;
            text-decoration: none;

            @include respond-to(phone-max) {
                margin-top: 5px;
                @include margin-left(20px);
            }
        }
    }

    .gm-style-iw {
        padding: 0 !important;
        max-width: 369px;
        max-height: 420px;
        overflow: visible !important;

        &-c {
            border-radius: 30px;
        }

        div {
            overflow: visible !important;

            .store-locator-infowindow-container {
                overflow: hidden !important;
            }
        }

        & + div {
            display: none;
        }

        button.gm-ui-hover-effect { // original infowindow close button
            display: none !important;
        }
    }

    &-results {
        opacity: 0;
        transition: opacity $transition_duration;
        padding: 0 28px;

        @include respond-to(phone-max) {
            padding: 0 16px;
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &-title {
            color: $color_black;
            font-size: pxToRem(30px);
            margin-top: 28px;
            margin-bottom: 28px;

            @include respond-to(phone-max) {
                margin-top: 14px;
                margin-bottom: 20px;
            }
        }

        &.active {
            opacity: 1;
        }
    }

    &-result {
        &-button {
            @include reset-button;
            width: 100%;
            display: block;
            line-height: pxToRem(28px);
            padding: 28px 32px;
            border: 1px solid $color_lightgray2;
            box-shadow: 0 10px 40px 0 rgba($color_black, .1);
            border-radius: 9px;
            margin-bottom: 28px;
            @include text-align(left);

            @include respond-to(phone-max) {
                padding: 14px 16px;
                box-shadow: 0 5px 20px 0 rgba($color_black, .1);
                border-radius: 5px;
                margin-bottom: 20px;
            }
        }
    }

    &-streetview {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        opacity: 0;
        z-index: 11;

        @include respond-to(phone-max) {
            align-items: flex-start;
        }

        &.active {
            pointer-events: auto;
            opacity: 1;
        }

        &-overlay {
            @include reset-button;
            background-color: $color_black;
            opacity: .7;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: transparent;
        }

        &-frame {
            width: 100%;
            height: 100%;
            border-radius: 30px;

            @include respond-to(tablet-max) {
                border-radius: 15px;
            }

            &-wrapper {
                width: calc(100% - 30px);
                max-width: 800px;
                height: 70vh;
                max-height: 600px;
                position: relative;

                @include respond-to(tablet-max) {
                    margin-top: 15px;
                    height: 80vh;
                    max-height: 700px;
                }

                @include respond-to(phone-max) {
                    height: 70vh;
                }
            }
        }

        &-close {
            @include reset-button;
            position: absolute;
            top: -10vh;
            @include right(-87px);

            @include respond-to(tablet-max) {
                position: fixed;
                top: auto;
                @include right(auto);
                left: 50%;
                transform: translateX(-50%);
                bottom: 40px;
                width: 64px;
                height: 64px;
                border-radius: 50%;
                border: solid 1px $color_gray3;

                &-icon {
                    width: 16px;
                    height: 16px;
                }
            }

            @media (max-height: 450px) {
                position: absolute;
                bottom: -80px;
            }
        }
    }
}

html.is-touch.is-device .store-locator-infowindow-image:hover::before {
    opacity: 0;
}